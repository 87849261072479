import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
  COUNTERS,
  COUNTER_DURATION,
  DAILY_UPLOADED_SONGS,
} from '@fnd/constants'
import { getRandomUniqueNumbers } from '@fnd/core/libs/helpers'
import Badge from '@fnd/components/Badge'
import Card from '@fnd/components/Card'
import Counter from '@fnd/components/Counter'
import Video from '@fnd/components/Video'
import Icon from '@fnd/components/Icon'
import { motion, AnimatePresence } from 'framer-motion'
import parse from 'html-react-parser'
import classNames from 'classnames'

const Solution = ({ className }) => {
  const intl = useIntl()

  const Problem = () => {
    const IMAGES_NUMBER = 90
    const IMAGES_GRID = 40
    const imgs = getRandomUniqueNumbers(1, IMAGES_NUMBER, IMAGES_GRID)
    const activeIndex = Math.floor(Math.random() * imgs.length)

    const containerVariants = {
      hidden: {
        opacity: 0,
      },
      show: {
        opacity: 1,
        transition: {
          staggerChildren: 0.04,
        },
      },
    }

    const itemVariants = {
      hidden: {
        opacity: 0,
      },
      show: {
        opacity: 1,
      },
    }

    return (
      <Card>
        <div className="grid md:grid-cols-2 items-center md:gap-8">
          <div className="p-8">
            <h3 className="inline-flex gap-3 text-primary text-5xl md:text-8xl font-bold mb-3">
              <Counter interval={4000} start={0} end={DAILY_UPLOADED_SONGS} />
              <span>+</span>
            </h3>

            <h4>{intl.formatMessage({ id: 'home.problem.title' })}</h4>

            <AnimatePresence>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="show"
                className="solution-cover-grid-wrapper"
              >
                <div className="solution-cover-grid">
                  {imgs.map((idx) => (
                    <motion.div
                      key={idx}
                      className={classNames({
                        cell: true,
                        active: idx === activeIndex,
                      })}
                      variants={itemVariants}
                    >
                      <img
                        src={`/images/covers/sp_playlist_${idx}.jpeg`}
                        alt={`Playlist Cover ${idx}`}
                      />
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          <div className="p-8">
            <p className="text-xl">
              {parse(intl.formatMessage({ id: 'home.problem.body.1' }))}
            </p>

            <h4 className="my-6">
              {parse(intl.formatMessage({ id: 'home.problem.body.2' }))}
            </h4>

            <p className="text-xl">
              {parse(intl.formatMessage({ id: 'home.problem.body.3' }))}
            </p>
          </div>
        </div>
      </Card>
    )
  }

  const Solution = () => {
    const SolutionCard = ({
      icon,
      color,
      title,
      text,
      footer,
      video,
      className,
    }) => {
      const cardClasses = classNames({
        card: true,
        'solution-card': true,
        'solution-video': video,
        [`card-${color}`]: color,
        [className]: className,
      })

      return (
        <div className={cardClasses}>
          <div className="flex flex-col justify-between h-full">
            {icon && (
              <div className="card-icon">
                <Icon name={icon} />
              </div>
            )}

            <div className="flex flex-col gap-4 mt-auto">
              <div className="card-content">
                <div className="flex flex-col gap-1">
                  {title && <h3 className="m-0">{title}</h3>}
                  {text && <p>{parse(text)}</p>}
                </div>
              </div>

              {footer && <div className="card-footer">{footer}</div>}
            </div>
          </div>

          {video && (
            <Video
              muted
              autoPlay
              loop
              playsinline={true}
              controls={false}
              src={video}
            />
          )}
        </div>
      )
    }

    const SolutionGrid = () => {
      return (
        <div className="grid sm:grid-cols-2 md:grid-cols-6 gap-4">
          <SolutionCard
            icon="music"
            className="solution-card sm:col-span-2 md:col-span-6 card-primary border-b-1"
            title={intl.formatMessage({ id: 'home.solution.match.title' })}
            text={intl.formatMessage({ id: 'home.solution.match.body' })}
            video="/video/matchfy_match.mp4"
            footer={
              <div className="flex flex-wrap justify-start gap-2">
                <Badge
                  variant="primary-light"
                  icon="arrow-right"
                  className="capitalize"
                  label={intl.formatMessage({
                    id: 'home.solution.submissions.counter',
                  })}
                  value={
                    <Counter
                      start={0}
                      duration={COUNTER_DURATION}
                      end={COUNTERS.SUBMISSIONS}
                    />
                  }
                />

                <Badge
                  variant="primary-light"
                  icon="user-music"
                  className="capitalize"
                  label={intl.formatMessage({
                    id: 'home.solution.artists.counter',
                  })}
                  value={
                    <Counter
                      start={0}
                      duration={COUNTER_DURATION}
                      end={COUNTERS.ARTISTS}
                    />
                  }
                />

                <Badge
                  variant="primary-light"
                  icon="music"
                  className="capitalize"
                  label={intl.formatMessage({
                    id: 'home.solution.playlists.counter',
                  })}
                  value={
                    <Counter
                      start={0}
                      duration={COUNTER_DURATION}
                      end={COUNTERS.PLAYLISTS}
                    />
                  }
                />
              </div>
            }
          />

          <SolutionCard
            icon="file-chart-line"
            className="solution-card card-secondary md:col-span-3"
            title={intl.formatMessage({ id: 'home.solution.analysis.title' })}
            text={intl.formatMessage({ id: 'home.solution.analysis.body' })}
            footer={
              <div className="flex flex-wrap justify-start gap-2">
                <Badge
                  variant="secondary-light"
                  icon="chart-line"
                  className="capitalize"
                  label={intl.formatMessage({
                    id: 'home.solution.analysis.counter',
                  })}
                  value={
                    <Counter
                      start={0}
                      duration={COUNTER_DURATION}
                      end={COUNTERS.ANALYSIS}
                    />
                  }
                />
              </div>
            }
          />

          <SolutionCard
            icon="rocket"
            className="solution-card card-red md:col-span-3"
            title={intl.formatMessage({ id: 'home.solution.accelerate.title' })}
            text={intl.formatMessage({ id: 'home.solution.accelerate.body' })}
            footer={
              <div className="flex flex-wrap justify-start gap-2">
                <Badge
                  variant="red-light"
                  icon="rocket"
                  className="capitalize"
                  label={intl.formatMessage({
                    id: 'home.solution.accelerate.counter',
                  })}
                  value={
                    <Counter
                      start={0}
                      duration={COUNTER_DURATION}
                      end={COUNTERS.ACCELERATE}
                    />
                  }
                />
              </div>
            }
          />

          <SolutionCard
            icon="gem"
            className="solution-card card-gold md:col-span-2"
            title={intl.formatMessage({ id: 'home.solution.vip_plays.title' })}
            text={intl.formatMessage({ id: 'home.solution.vip_plays.body' })}
            footer={
              <div className="flex flex-wrap justify-start gap-2">
                <Badge
                  variant="gold-light"
                  icon="play"
                  className="capitalize"
                  label={intl.formatMessage({
                    id: 'home.solution.vip_plays.counter',
                  })}
                  value={
                    <Counter
                      start={0}
                      duration={COUNTER_DURATION}
                      end={COUNTERS.PLAYS}
                    />
                  }
                />
              </div>
            }
          />

          <SolutionCard
            icon="trophy"
            className="solution-card card-orange md:col-span-2"
            title={intl.formatMessage({ id: 'home.solution.rank.title' })}
            text={intl.formatMessage({ id: 'home.solution.rank.body' })}
            footer={
              <div className="flex flex-wrap justify-start gap-2">
                <Badge
                  variant="orange-light"
                  icon="award"
                  className="capitalize"
                  label={intl.formatMessage({
                    id: 'home.solution.rank.counter',
                  })}
                  value={
                    <Counter
                      start={0}
                      duration={COUNTER_DURATION}
                      end={COUNTERS.CURATORS}
                    />
                  }
                />
              </div>
            }
          />

          <SolutionCard
            icon="shield-check"
            className="solution-card card-green md:col-span-2"
            title={intl.formatMessage({ id: 'home.solution.check.title' })}
            text={intl.formatMessage({ id: 'home.solution.check.body' })}
            footer={
              <div className="flex flex-wrap justify-start gap-2">
                <Badge
                  variant="green-light"
                  icon="check"
                  className="capitalize"
                  label={intl.formatMessage({
                    id: 'home.solution.check.counter',
                  })}
                  value={
                    <Counter
                      start={0}
                      duration={COUNTER_DURATION}
                      end={COUNTERS.FAKE_PLAYLISTS}
                    />
                  }
                />
              </div>
            }
          />
        </div>
      )
    }

    return (
      <>
        <Card className="bg-transparent">
          <div className="flex flex-col">
            <div className="p-4 md:p-8">
              <div className="grid items-center md:grid-cols-3 gap-4 md:gap-8">
                <h3 className="inline-flex gap-3 text-gradient text-6xl md:text-7xl font-bold mb-3">
                  {intl.formatMessage({ id: 'home.solution.intro.title' })}
                </h3>

                <div className="flex flex-col gap-3 md:col-span-2">
                  <p className="text-xl">
                    {parse(
                      intl.formatMessage({ id: 'home.solution.intro.body.1' })
                    )}
                  </p>

                  <p className="text-xl">
                    {parse(
                      intl.formatMessage({ id: 'home.solution.intro.body.2' })
                    )}
                  </p>

                  <p className="text-xl">
                    {parse(
                      intl.formatMessage({ id: 'home.solution.intro.body.3' })
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </>
    )
  }

  const classes = classNames({
    section: true,
    container: true,
    [className]: className,
  })

  return (
    <div id="solution" className={classes}>
      <Problem />
    </div>
  )
}

export default Solution
