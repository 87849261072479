import React, { useState, useEffect } from 'react'
import Badge from '@fnd/components/Badge'
import Spinner from '@fnd/components/Spinner'
import { Checkbox } from '@fnd/components/Field/Checkbox'
import { formatNumber } from '@fnd/core/libs/currency'
import { TableColumnHeader } from '@fnd/components/Table/TableColumnHeader'
import { DataTableRowActions } from './SpotifyTableActions'
import { Placeholder } from '@fnd/components/Placeholder'
import { useInView } from 'react-intersection-observer'
import { useMatchStore, usePushToPlaylistStore } from '@fnd/store'
import { throttle } from 'lodash'
import { MATCH_CONFIG } from '@fnd/constants'
import {
  buildSpotifyPlaylistUrl,
  buildSpotifyUserUrl,
} from '@fnd/core/libs/platforms'
import CuratorEmoji from '@fnd/components/Card/CuratorEmoji'

const ScoreCell = ({ playlist }) => {
  const [matchScore, setMatchScore] = useState(playlist?.score || 0)
  const { getMatch } = useMatchStore()
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 1,
  })

  const handlePlaylistMatch = throttle(async () => {
    if (!playlist || !playlist.id) return

    const matchData = await getMatch(playlist.id)
    setMatchScore(matchData?.score ?? -1)
  }, MATCH_CONFIG.SCORE_FETCH_THROTTLE)

  useEffect(() => {
    if (!playlist?.score && inView && !matchScore) {
      handlePlaylistMatch()
    } else if (playlist?.score && !matchScore) {
      setMatchScore(playlist?.score)
    }
  }, [playlist?.score, inView, matchScore])

  return (
    <span ref={ref}>
      {matchScore > 0 && <span className="score">{matchScore}%</span>}

      {matchScore === -1 && (
        <span className="score">
          <span>-</span>
        </span>
      )}

      {matchScore === 0 && <Spinner variant="white" inline className="mb-1" />}
    </span>
  )
}

export const getColumns = (intl, actionProps = {}) => [
  {
    id: 'select',
    size: 10,
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_all' })}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_row' })}
      />
    ),
    show: false,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    size: 40,
    header: ({ column }) => <TableColumnHeader column={column} title="Name" />,
    cell: ({ row }) => {
      const item = row.original
      const playlist = item?.playlist

      const { pushedPlaylists } = usePushToPlaylistStore()
      const isPushed = pushedPlaylists?.find(
        (pushedId) => pushedId === playlist.id
      )

      return (
        <div className="flex flex-col items-start">
          <a
            href={buildSpotifyPlaylistUrl(playlist.id)}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center gap-3 font-medium"
          >
            {isPushed && (
              <Placeholder
                variant="success"
                icon="check"
                className="text-lg p-0 w-10 h-10 rounded flex-shrink-0"
              />
            )}

            {playlist?.image && !isPushed && (
              <img
                src={playlist.image}
                alt={playlist.name}
                className="w-10 h-10 rounded flex-shrink-0"
              />
            )}

            {!playlist?.image && !isPushed && (
              <Placeholder
                icon="music"
                className="text-lg p-0 w-10 h-10 rounded flex-shrink-0"
              />
            )}

            <span>{playlist.name}</span>
          </a>
        </div>
      )
    },
    enableSorting: true,
    enableHiding: false,
    filterFn: (row, id, value) => {
      const playlist = row.original.playlist
      return playlist.name.toLowerCase().includes(value.toLowerCase())
    },
  },
  {
    accessorKey: 'curator',
    size: 20,
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Curator" />
    ),
    cell: ({ row }) => {
      const item = row.original
      const curator = item?.curator

      return (
        <Badge
          variant="light"
          target="_blank"
          to={buildSpotifyUserUrl(curator.id)}
          emoji={<CuratorEmoji curator={curator} />}
          label={curator.display_name}
        />
      )
    },
  },
  {
    accessorKey: 'followers',
    size: 10,
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Followers" />
    ),
    cell: ({ row }) => {
      const item = row.original
      const playlist = item?.playlist

      return (
        <span>
          {playlist?.followers ? formatNumber(playlist.followers) : 0}
        </span>
      )
    },
    sortingFn: (rowA, rowB) => {
      const followersA = rowA.original.playlist?.followers || 0
      const followersB = rowB.original.playlist?.followers || 0

      if (followersA > followersB) {
        return 1
      } else if (followersA < followersB) {
        return -1
      } else {
        return 0
      }
    },
  },
  {
    accessorKey: 'tracks',
    size: 20,
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Tracks" />
    ),
    cell: ({ row }) => {
      const item = row.original
      const playlist = item?.playlist

      return <span>{playlist?.tracks ? formatNumber(playlist.tracks) : 0}</span>
    },
    sortingFn: (rowA, rowB) => {
      const tracksA = rowA.original.playlist?.tracks || 0
      const tracksB = rowB.original.playlist?.tracks || 0

      if (tracksA > tracksB) {
        return 1
      } else if (tracksA < tracksB) {
        return -1
      } else {
        return 0
      }
    },
  },
  {
    accessorKey: 'score',
    size: 10,
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Tracks" />
    ),
    cell: ({ row }) => {
      const playlist = row.original
      return <ScoreCell playlist={playlist} />
    },
  },
  {
    id: 'actions',
    size: 10,
    cell: ({ row }) => {
      return <DataTableRowActions row={row} {...actionProps} />
    },
  },
]

export default getColumns
