import React, { useEffect, useState } from 'react'
import { VirtualDataTable } from '@fnd/components/Table'
import PlaylistModal from '@fnd/components/PlaylistDetails'
import PlaylistGrid from './PlaylistGrid'
import ReactGA from 'react-ga4'
import { getColumns } from './SpotifyTableColumns'
import filters from './SpotifyTableFilters'
import Spinner from '@fnd/components/Spinner'
import SpotifyListHeader from './SpotifyListHeader'
import {
  MATCH_CONFIG,
  PLANS,
  QUERIES,
  QUERY_STALE_TIME,
  USER_TYPES,
} from '@fnd/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useIntl } from 'react-intl'
import { useIncrementalDisplay } from '@fnd/core/hooks/useIncrementalDisplay'
import {
  useMatchStore,
  usePushToPlaylistStore,
  useRecentMatchStore,
  useUserStore,
} from '@fnd/store'
import { uniqBy, orderBy } from 'lodash'
import {
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { VipCampaignAlert } from '@fnd/components/VipCampaign'
import Development from '@fnd/components/Development'
import { userProfileSelector, useUserContext } from '@fnd/modules/User'
import { toastFeedback } from '@fnd/core/libs/toast'

const INITIAL_PAGE_LIMIT = Math.ceil(
  MATCH_CONFIG.REFETCH_MAX_ITEMS / MATCH_CONFIG.PER_PAGE
)

function SpotifyList() {
  const [sortedItems, setSortedItems] = useState([])
  const [skipPushed, setSkipPushed] = useState(true)
  const [view, setView] = useState('grid')
  const [currentItem, setCurrentItem] = useState(null)
  const [fetchLimit, setFetchLimit] = useState(MATCH_CONFIG.REFETCH_MAX_ITEMS)
  const [fetchedPages, setFetchedPages] = useState(1)
  const [batchNumber, setBatchNumber] = useState(1)
  const [pageLimit, setPageLimit] = useState(INITIAL_PAGE_LIMIT)
  const [lastPage, setLastPage] = useState(0)
  const [total, setTotal] = useState(0)
  const intl = useIntl()

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const { pushedPlaylists, pushedCount, setPushedCount } =
    usePushToPlaylistStore()
  const { setStarUsers } = useUserStore()
  const { matchType, isMatching, genres, trackId, track } = useMatchStore()
  const { addMatch } = useRecentMatchStore()

  const queryClient = useQueryClient()

  const { data: pushCount, isSuccess: pushedSuccess } = useQuery({
    queryKey: [QUERIES.PLAYLIST.PUSHED, trackId],
    queryFn: () => spotimatchEndpoints.getPlaylistsPushed(trackId).toPromise(),
    enabled: !!trackId,
  })

  const { data: starUsers, isSuccess: userSuccess } = useQuery({
    queryKey: [QUERIES.USER.STARS],
    queryFn: () => spotimatchEndpoints.getUserType(USER_TYPES.STAR).toPromise(),
  })

  const {
    data,
    isFetching,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERIES.SPOTIFY.LIST, genres, trackId],
    queryFn: async (params) => {
      const res = await spotimatchEndpoints.getPlaylistsFeatured({
        ...params,
        limit: MATCH_CONFIG.PER_PAGE,
        genres,
        trackId,
        artistsId: track?.artists?.map((artist) => artist.id),
      })

      setTotal(res.total)
      setLastPage(Math.ceil(res.total / MATCH_CONFIG.PER_PAGE))
      return res
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next_page) return
      return lastPage.next_page
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: QUERY_STALE_TIME,
    enabled: isMatching && matchType == 'spotify',
  })

  useEffect(() => {
    if (isError && error?.response?.status === 403) {
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.artist_blocked' })
      )
    } else if (isError) {
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.generic' })
      )
    }
  }, [isError, isMatching, error])

  const playlists = data?.pages?.map((page) => page.data).flat() || []
  const { displayedItems, resetDisplayedItems, hasMore } =
    useIncrementalDisplay(
      playlists,
      MATCH_CONFIG.ITEMS_RANGE,
      MATCH_CONFIG.DELAY_RANGE
    )

  const handleFetchNextPage = () => {
    if (sortedItems.length === 0) return

    const fetchLimit = MATCH_CONFIG.REFETCH_MAX_ITEMS * batchNumber
    const pageLimit = Math.ceil(fetchLimit / MATCH_CONFIG.PER_PAGE)

    setFetchLimit(fetchLimit)
    setPageLimit(Math.min(pageLimit, lastPage))

    setFetchedPages((prev) => prev + 1)
    fetchNextPage()
  }

  const handlePlaylistSelect = (playlist) => {
    setCurrentItem(playlist)
  }

  useEffect(() => {
    if (pushedSuccess) {
      setPushedCount(pushCount)
    }

    if (userSuccess) {
      setStarUsers(starUsers)
    }
  }, [pushedSuccess, userSuccess])

  useEffect(() => {
    if (!isMatching) return

    if (
      !hasMore &&
      sortedItems?.length < fetchLimit &&
      fetchedPages < pageLimit
    ) {
      handleFetchNextPage()
    }
  }, [isMatching, hasMore])

  useEffect(() => {
    if (isMatching) {
      addMatch({
        track,
        genres,
        type: matchType,
      })

      ReactGA.event({
        category: 'Search',
        action: 'User searched for matches',
      })
    } else {
      queryClient.invalidateQueries([QUERIES.SPOTIFY.LIST, genres, trackId])
      resetDisplayedItems()
      setFetchedPages(1)
      setPageLimit(INITIAL_PAGE_LIMIT)
      setSortedItems([])
    }
  }, [isMatching])

  useEffect(() => {
    if (displayedItems?.length > 0 && isMatching) {
      const uniqueItems = uniqBy(displayedItems, 'id')

      const sortFunctions = [
        ...(profile?.plan.name === PLANS.FREE
          ? [(item) => item?.curator?.rank]
          : []),
        (item) => item.score,
        (item) => item.playlist?.followers ?? 0,
      ]

      const sortOrders = [
        ...(profile?.plan.name === PLANS.FREE ? ['asc'] : []),
        'desc',
        'desc',
      ]

      let sortedItems = orderBy(uniqueItems, sortFunctions, sortOrders)

      if (skipPushed) {
        sortedItems = sortedItems
          .filter((item) => !item?.isAlreadyPushed)
          .filter((item) => !pushedPlaylists?.includes(item.id))
      }

      setSortedItems(sortedItems)
    }
  }, [displayedItems, isMatching, skipPushed])

  useEffect(() => {
    if (fetchedPages === pageLimit) {
      setBatchNumber((prev) => prev + 1)
    }
  }, [fetchedPages, pageLimit])

  return (
    <div className="mb-8">
      <Development>
        <pre>
          Is Fetching: {isFetching ? 'y' : 'n'}
          <br />
          Is Loading: {isLoading ? 'y' : 'n'}
          <br />
          Has More: {hasMore ? 'y' : 'n'}
          <br />
          Fetched Pages: {fetchedPages} / {pageLimit}
          <br />
          Fetch limit: {fetchLimit}
          <br />
          Batch number: {batchNumber}
          <br />
          Total items: {total}
          <br />
          Last page: {lastPage}
          <br />
          Sent count: {pushedCount ?? 0}
          <br />
        </pre>
      </Development>

      {sortedItems?.length > 0 && (
        <SpotifyListHeader
          items={sortedItems}
          fetchNextPage={handleFetchNextPage}
          genres={genres}
          hasMore={hasMore}
          loadMore={
            fetchedPages === pageLimit && !hasMore && !isFetching && !isLoading
          }
          setSkipPushed={setSkipPushed}
          setView={setView}
          skipPushed={skipPushed}
          track={track}
          view={view}
        />
      )}

      {currentItem && (
        <PlaylistModal
          isOpen={!!currentItem}
          curator={currentItem?.curator}
          values={currentItem?.values}
          score={currentItem?.score}
          playlist={currentItem?.playlist}
          onClose={() => setCurrentItem(null)}
        />
      )}

      {sortedItems?.length > 0 && (
        <>
          <VipCampaignAlert
            inline
            variant="success"
            btnVariant="green"
            className="mb-5"
          />

          {view === 'grid' && (
            <PlaylistGrid data={sortedItems} onClick={handlePlaylistSelect} />
          )}

          {view === 'list' && (
            <VirtualDataTable
              rowIdKey="_id"
              pagination={{ disabled: true }}
              columns={getColumns(intl, { setCurrentItem })}
              data={sortedItems}
              filters={filters}
              isLoading={isLoading || isFetching}
              total={total}
            />
          )}
        </>
      )}

      {(isLoading || isFetching || sortedItems?.length < 0) &&
        view !== 'list' && <Spinner />}
    </div>
  )
}

export default SpotifyList
