import React from 'react'
import classNames from 'classnames'

const DeezerLogo = ({ className }) => (
  <svg
    className={classNames({ [className]: className })}
    viewBox="0 0 289 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.481 51.0882H137.487V19.4995H163.481V28.0325H149.746V31.6837H162.661V38.6578H149.746V42.5552H163.481V51.0882ZM192.426 51.0882H166.433V19.4995H192.426V28.0325H178.693V31.6837H191.607V38.6578H178.693V42.5552H192.426V51.0882ZM286.767 51.0882C284.997 46.258 282.52 41.104 279.187 35.327C283.085 34.1833 285.415 31.7247 285.415 27.9505C285.415 22.2072 280.126 19.4995 271.763 19.4995H254.541V51.0882H266.842V38.0215C269.574 42.6268 271.588 46.9725 272.911 51.0882H286.767ZM266.842 33.6118V28.0325H271.107C272.911 28.0325 273.935 29.0172 273.935 30.8222C273.935 32.6272 272.911 33.6118 271.107 33.6118H266.842ZM251.59 51.0882H225.597V19.4995H251.59V28.0325H237.855V31.6837H250.77V38.6578H237.855V42.5552H251.59V51.0882ZM195.38 28.0325H208.07C202.805 32.3433 198.471 37.226 195.133 42.5552V51.0882H223.055V42.5552H209.098C212.296 37.8997 216.765 33.264 223.055 28.0325V19.4995H195.38V28.0325ZM103.048 19.4998H118.874C128.673 19.4998 135.602 26.0227 135.602 35.294C135.602 44.5655 128.673 51.0884 118.874 51.0884H103.048V19.4998ZM115.348 42.5553H118.218C121.293 42.5553 122.933 40.5042 122.933 35.294C122.933 30.084 121.293 28.0328 118.218 28.0328H115.348V42.5553Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.4521 12.2052C69.1918 7.91523 70.2772 5.21757 71.4795 5.2124H71.4821C73.7242 5.22013 75.5416 14.5757 75.5416 26.1265C75.5416 37.6772 73.7215 47.0405 71.477 47.0405C70.5569 47.0405 69.7079 45.4507 69.0241 42.7865C67.9437 52.5388 65.7017 59.2432 63.1064 59.2432C61.0983 59.2432 59.296 55.216 58.086 48.8647C57.2599 60.9437 55.1806 69.5133 52.7504 69.5133C51.2253 69.5133 49.8348 66.1175 48.8054 60.5882C47.5674 72.0023 44.7078 80 41.3726 80C38.0375 80 35.1729 72.0048 33.94 60.5882C32.9181 66.1175 31.5277 69.5133 29.9948 69.5133C27.5647 69.5133 25.4905 60.9437 24.6593 48.8647C23.4493 55.216 21.6522 59.2432 19.6389 59.2432C17.0461 59.2432 14.8016 52.5415 13.7213 42.7865C13.0426 45.4585 12.1885 47.0405 11.2683 47.0405C9.02376 47.0405 7.20371 37.6772 7.20371 26.1265C7.20371 14.5757 9.02376 5.2124 11.2683 5.2124C12.4732 5.2124 13.551 7.9178 14.2983 12.2052C15.4956 4.80788 17.4402 0 19.6389 0C22.2495 0 24.5119 6.79958 25.5821 16.673C26.6293 9.48693 28.218 4.9058 29.9975 4.9058C32.491 4.9058 34.6111 13.9161 35.3966 26.4847C36.8734 20.0405 39.0111 15.9979 41.3777 15.9979C43.7443 15.9979 45.8821 20.0432 47.3564 26.4847C48.1444 13.9161 50.2618 4.9058 52.7555 4.9058C54.5323 4.9058 56.1185 9.48693 57.1709 16.673C58.2385 6.79958 60.5008 0 63.1114 0C65.3026 0 67.2548 4.81047 68.4521 12.2052ZM1.39566 24.0695C1.39566 18.906 2.4277 14.719 3.70121 14.719C4.97473 14.719 6.00677 18.906 6.00677 24.0695C6.00677 29.2328 4.97473 33.4198 3.70121 33.4198C2.4277 33.4198 1.39566 29.2328 1.39566 24.0695ZM76.7369 24.0695C76.7369 18.906 77.7688 14.719 79.0423 14.719C80.3159 14.719 81.348 18.906 81.348 24.0695C81.348 29.2328 80.3159 33.4198 79.0423 33.4198C77.7688 33.4198 76.7369 29.2328 76.7369 24.0695Z"
      fill="#A238FF"
    />
  </svg>
)

export default DeezerLogo
