import {
  Artists,
  Blog,
  Curators,
  Features,
  HomeHero,
  HowItWorks,
  Mentions,
  Reviews,
  PromoCompare,
  Intro,
  Solution,
} from '@fnd/components/Home'
import React from 'react'
import { Plans } from '@fnd/components/Plans'

const Home = () => {
  return (
    <div className="wrapper">
      <HomeHero />

      <Intro />

      <Solution />

      <PromoCompare className="mt-8" />

      <HowItWorks showTitle />

      <Features className="mt-8" />

      <Artists />

      <Plans showTitle titleTag="h3" className="container mb-8" />

      <Curators />

      <Blog />

      <Reviews />

      <Mentions className="mb-16 md:mb-32" />
    </div>
  )
}

export default Home
