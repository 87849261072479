import React, { useState, useEffect } from 'react'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { FormattedMessage, useIntl } from 'react-intl'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import Badge from '@fnd/components/Badge'
import Empty from '@fnd/components/Empty'
import Icon from '@fnd/components/Icon'
import { NotificationItem } from '@fnd/components/Notification'
import Spinner from '@fnd/components/Spinner'
import { NOTIFICATION_CLEAR_TIMEOUT } from '@fnd/constants'

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [unread, setUnread] = useState([])
  const [read, setRead] = useState([])
  const intl = useIntl()

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const notificationData = await spotimatchEndpoints.getNotifications()

      const readNotifications = notificationData.filter(
        (notification) => notification.status === 'read'
      )
      setRead(readNotifications)

      const unreadNotifications = notificationData.filter(
        (notification) => notification.status === 'unread'
      )
      setUnread(unreadNotifications)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const markAsRead = async () => {
    try {
      const unreadIds = unread.map((notification) => notification._id)
      await spotimatchEndpoints.markNotificationsAsRead(unreadIds)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (unread.length > 0) {
      setTimeout(() => {
        markAsRead()
      }, NOTIFICATION_CLEAR_TIMEOUT)
    }
  }, [unread])

  if (isLoading) return <Spinner />

  return (
    <Wrapper>
      <div className="notifications max-w-screen-md my-32 mx-auto">
        <div className="notification-section mb-10">
          <h2 className="flex items-center gap-2">
            <Icon name="bell" className="text-primary" />
            <FormattedMessage id="notifications.unread.title" />
            {unread.length > 0 && (
              <Badge
                className="rounded-full flex items-center justify-center w-8 h-8 ml-2"
                label={unread.length}
              />
            )}
          </h2>

          {unread.length > 0 ? (
            unread.map((notification) => {
              return (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                />
              )
            })
          ) : (
            <Empty
              small
              horizontal
              icon="bell-slash"
              message={intl.formatMessage({
                id: 'notifications.unread.empty_state',
              })}
            />
          )}
        </div>
        <div className="notification-section">
          <h2 className="inline-flex items-center gap-2">
            <Icon name="inbox" className="text-primary" />
            <FormattedMessage id="notifications.read.title" />
          </h2>

          {read.length > 0 ? (
            read.map((notification) => {
              return (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                />
              )
            })
          ) : (
            <Empty
              small
              horizontal
              icon="bell-slash"
              message={intl.formatMessage({
                id: 'notifications.read.empty_state',
              })}
            />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default Notifications
