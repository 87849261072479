import React, { useEffect, useState } from 'react'
import { DataTable } from '@fnd/components/Table'
import Modal from '@fnd/components/Modal/Modal'
import { ModalHeader } from '@fnd/components/Modal'
import Spinner from '@fnd/components/Spinner'
import MagazineCard from './MagazineCard'
import MagazineDetail from './MagazineDetail'
import { ViewSwitch } from '@fnd/components/View'
import { getColumns } from './MagazineTableColumns'
import filters from './MagazineTableFilters'
import { getMediaUrl } from '@fnd/core/libs/media'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useMatchStore, usePaginationStore } from '@fnd/store'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { MAGAZINE_PREVIEW_LIMIT, PLANS, QUERIES } from '@fnd/constants'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import { toastFeedback } from '@fnd/core/libs/toast'

export function MagazineList() {
  const [view, setView] = useState('grid')
  const [isAgency, setIsAgency] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const { genres, trackId, isMatching, matchType, track } = useMatchStore()
  const { total, setTotal } = usePaginationStore()
  const intl = useIntl()

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const { ref, inView } = useInView({
    threshold: 0,
  })

  const { data, isLoading, isFetching, isError, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: [QUERIES.MAGAZINE.LIST],
      queryFn: async (params) => {
        const res = await spotimatchEndpoints.getMagazines({
          ...params,
          genres,
          trackId,
          artistsId: track?.artists?.map((artist) => artist.id),
        })

        setTotal(res.total)
        return res
      },
      getNextPageParam: (lastPage) => {
        if (!lastPage?.next_page) return
        return lastPage.next_page
      },
      enabled: isMatching && matchType == 'magazine',
    })

  const handleCardClick = (magazine) => {
    setCurrentItem(magazine)
  }

  const handleModalClose = () => {
    setCurrentItem(null)
  }

  useEffect(() => {
    if (isError) {
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.artist_blocked' })
      )
    }
  }, [isError, isMatching])

  useEffect(() => {
    if (inView && hasNextPage && isAgency) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  useEffect(() => {
    if (profile?.plan?.name === PLANS.AGENCY) {
      setIsAgency(true)
    }
  }, [profile])

  const magazines = data?.pages?.map((page) => page.data).flat() || []

  return (
    <div className="mb-12">
      {magazines && magazines.length > 0 && (
        <>
          <ViewSwitch className="mb-4" view={view} setView={setView} />

          {view === 'list' && (
            <DataTable
              rowIdKey="_id"
              pagination={{ disabled: true }}
              columns={getColumns(intl, { setCurrentItem })}
              data={magazines}
              isLoading={isLoading || isFetching}
              filters={filters}
              total={total}
            />
          )}

          {view === 'grid' && (
            <div className="magazine-grid grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8">
              {magazines.map((magazine, index) => (
                <MagazineCard
                  key={magazine._id}
                  preview={!isAgency && index > MAGAZINE_PREVIEW_LIMIT}
                  tags={magazine.tags}
                  logo={getMediaUrl(magazine.logo)}
                  cover={getMediaUrl(magazine.cover)}
                  website={magazine.website}
                  name={magazine.name}
                  slug={magazine.slug}
                  contacts={magazine.contacts}
                  onClick={() => handleCardClick(magazine)}
                />
              ))}
            </div>
          )}
        </>
      )}

      {currentItem ? (
        <Modal
          key={currentItem.id}
          isOpen={true}
          onClose={() => handleModalClose()}
        >
          <ModalHeader
            title={currentItem.name}
            onClose={() => handleModalClose()}
          />
          <div className="p-4">
            <MagazineDetail
              key={currentItem._id}
              magazineId={currentItem._id}
              preview={!isAgency}
              description={currentItem.description}
              location={currentItem.location}
              facebook={currentItem.facebook}
              instagram={currentItem.instagram}
              twitter={currentItem.twitter}
              tags={currentItem.tags}
              logo={getMediaUrl(currentItem.logo)}
              cover={getMediaUrl(currentItem.cover)}
              website={currentItem.website}
              name={currentItem.name}
              slug={currentItem.slug}
              contacts={currentItem.contacts}
            />
          </div>
        </Modal>
      ) : null}

      {(isLoading || isFetching) && view !== 'list' && <Spinner />}

      <div ref={ref} />

      {!isAgency && magazines?.length > 0 && (
        <AccountUpgrade
          btnVariant="gradient"
          message={intl.formatMessage(
            { id: 'profile.upgrade.magazine' },
            {
              plan: 'agency',
            }
          )}
          plan={PLANS.AGENCY}
        />
      )}
    </div>
  )
}

export default MagazineList
