import React, { useEffect, useState } from 'react'
import { BannerNotice } from '@fnd/components/Banner'
import ConfirmEmail from '@fnd/components/ConfirmEmail'
import { isUserAuthorizedSelector, useUserContext } from '@fnd/modules/User'
import Navigation from './Navigation'
import NavigationBackdrop from './NavigationBackdrop'
import NavigationDrawer from './NavigationDrawer'
import NavigationTabs from './NavigationTabs'
import { EVENTS, SPOTIFY_SUPPORT_URL } from '@fnd/constants'
import { useBannerStore } from '@fnd/store'
import eventEmitter from '@fnd/core/libs/eventEmitter'
import { useIntl } from 'react-intl'

function Header() {
  const [drawerOpen, setDrawerState] = useState(false)
  const [showError429, setShowError429] = useState(false)
  const { banner } = useBannerStore()
  const intl = useIntl()
  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  useEffect(() => {
    eventEmitter.on(EVENTS.ERROR_429, () => {
      setShowError429(true)
    })

    return () => {
      eventEmitter.off(EVENTS.ERROR_429)
    }
  }, [])

  useEffect(() => {
    if (drawerOpen) {
      document.body.classList.add('lock-scroll')
    } else {
      document.body.classList.remove('lock-scroll')
    }
  }, [drawerOpen])

  return (
    <>
      <Navigation navigationToggleHandler={() => setDrawerState(!drawerOpen)} />

      <NavigationDrawer
        show={drawerOpen}
        closeDrawer={() => setDrawerState(false)}
      />

      {drawerOpen && (
        <NavigationBackdrop
          backdropClickHandler={() => setDrawerState(false)}
        />
      )}

      {isAuthorized ? <NavigationTabs /> : null}

      <ConfirmEmail className="mt-10" />

      {showError429 ? (
        <BannerNotice id="error-429" variant="error">
          <p>
            {intl.formatMessage({ id: 'error_login.subtitle' })}
            {'. '}
            <a
              target="_blank"
              rel="noreferrer"
              href={SPOTIFY_SUPPORT_URL}
              className="font-semibold underline"
            >
              {intl.formatMessage({ id: 'error_login.cta' })}
            </a>
          </p>
        </BannerNotice>
      ) : (
        <BannerNotice id="dynamic-banner" variant="gradient" banner={banner} />
      )}
    </>
  )
}

export default Header
