import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import Emoji from '@fnd/components/Emoji'
import { spotifyEndpoints } from '@fnd/core/spotify'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { usePlayStore } from '@fnd/store'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import Confetti from 'react-confetti'
import { useIntl } from 'react-intl'
import TrackCard from './TrackCard'

export function TrackPlayer({ track, showInfo }) {
  const intl = useIntl()

  const {
    CHECK_INTERVAL,
    checkPlaybackState,
    elapsedTime,
    error,
    isLoading,
    isPlaying,
    maxRetries,
    playbackState,
    playTrack,
    refresh,
    resetAll,
    setDuration,
    setPlaybackState,
    setTrack,
    setTrackId,
    stopTrack,
    success,
  } = usePlayStore()

  useEffect(() => {
    if (!track) return
    resetAll()
  }, [track])

  const { profile, updateReferral } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      updateReferral: (next) => updateUserProfile({ referral: { $set: next } }),
    })
  )

  const handleCreditsUpdate = async () => {
    updateReferral({
      ...profile.referral,
      score: profile.referral.score + 1,
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const playbackData = await spotifyEndpoints.getPlaybackState().toPromise()
      setDuration(playbackData?.item?.duration_ms)
      setPlaybackState(playbackData)
      setTrack(track)
      setTrackId(track.id)

      await checkPlaybackState()
    }
    const handleVisibilityChange = () => {
      const isPageVisible = !document.hidden
      checkPlaybackState(isPageVisible)

      if (!isPlaying && isPageVisible) {
        checkPlaybackState()
      }
    }

    fetchData()
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [track.id])

  useEffect(() => {
    if ((elapsedTime * 1000) % CHECK_INTERVAL !== 0) return

    const checkPlayer = async () => {
      await checkPlaybackState()
    }
    checkPlayer()
  }, [elapsedTime])

  useEffect(() => {
    if (refresh) {
      handleCreditsUpdate()
    }
  }, [refresh])

  const classes = classNames({
    player: true,
    'has-device': playbackState?.device,
    'no-device': !playbackState?.device,
    'play-success': success,
    'play-error': error,
  })

  return (
    <div className={classes}>
      <div className="player-card">
        <div className="player-card-content">
          <div className="track-card-main">
            <div className="track-cover">
              <img src={track.album.images[0].url} alt={track.name} />
            </div>

            <div className="track-info">
              <h1 className="mb-0">{track.name}</h1>
              <div className="track-artists">
                {track.artists.map((artist) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://open.spotify.com/artist/${artist.id}`}
                    key={artist.id}
                    className="mb-1"
                  >
                    {artist.name}
                  </a>
                ))}
              </div>
            </div>
          </div>

          <Button
            disabled={maxRetries}
            loading={isLoading}
            icon={isPlaying ? 'stop' : 'play'}
            className={classNames('button-play', { 'is-playing': isPlaying })}
            onClick={isPlaying ? stopTrack : playTrack}
          />
        </div>

        {showInfo && (
          <div className="player-online">
            {playbackState?.device && (
              <TrackCard
                titleTag="h5"
                nowPlaying={isPlaying}
                track={playbackState?.item}
              />
            )}

            <div className="player-device">
              {playbackState?.device && (
                <p>
                  {playbackState?.device?.type === 'Computer' && (
                    <Emoji symbol="💻" />
                  )}
                  {playbackState?.device?.type === 'Smartphone' && (
                    <Emoji symbol="📱" />
                  )}
                  {playbackState?.device?.type === 'Speaker' && (
                    <Emoji symbol="🔈" />
                  )}
                  <span>
                    {intl.formatMessage({ id: 'track_player.play_device' })}
                  </span>
                  {': '}
                  <span>{playbackState?.device?.name}</span>
                </p>
              )}

              {!playbackState?.device && (
                <p>
                  <Emoji symbol="🚫" name="Prohibited" />
                  <span>
                    {intl.formatMessage({ id: 'track_player.no_play_device' })}
                  </span>
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      {refresh && (
        <Button
          label={intl.formatMessage({ id: 'track_player.refresh' })}
          className="w-full mt-4"
          variant="primary-light"
          icon="repeat"
          onClick={() => resetAll()}
        />
      )}

      {isPlaying && !success && !error && (
        <Alert className="mt-4" variant="info">
          {intl.formatMessage({ id: 'track_player.do_not_close' })}
        </Alert>
      )}

      {!isLoading && success && (
        <Alert className="mt-4" variant="success">
          {intl.formatMessage({ id: success })}
        </Alert>
      )}

      {!isLoading && success && (
        <Confetti numberOfPieces={200} recycle={false} duration_ms={5000} />
      )}

      {isLoading && error && (
        <Alert className="mt-4" variant="danger">
          {intl.formatMessage({ id: error })}
        </Alert>
      )}
    </div>
  )
}

export default TrackPlayer
