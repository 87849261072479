import parse from 'html-react-parser'
import React from 'react'
import { useIntl } from 'react-intl'

export function TrackPlaysInstructions() {
  const intl = useIntl()

  return (
    <ol className="list-badges">
      <li>
        {parse(intl.formatMessage({ id: 'vip_plays.how_does_it_work.1' }))}
      </li>
      <li>
        {parse(intl.formatMessage({ id: 'vip_plays.how_does_it_work.2' }))}
      </li>
      <li>
        {parse(intl.formatMessage({ id: 'vip_plays.how_does_it_work.3' }))}
      </li>
    </ol>
  )
}

export default TrackPlaysInstructions
