import React from 'react'
import Button from '@fnd/components/Button'
import { StepperFooter } from '@fnd/components/Stepper'
import { CheckboxCard } from '@fnd/components/Field'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useVipCampaignStore } from '@fnd/store'
import {
  CAMPAIGN_AGE_OPTIONS as ageOptions,
  CAMPAIGN_GENDER_OPTIONS as genderOptions,
  CAMPAIGN_DURATION_OPTIONS as durationOptions,
  CAMPAIGN_COUNTRY_OPTIONS as countryOptions,
} from '../config'
import { Maps } from '@fnd/components/Maps'
import classNames from 'classnames'

const VipCampaignStep2 = ({ next, previous }) => {
  const { form, setForm } = useVipCampaignStore()
  const intl = useIntl()

  const CAMPAIGN_AGE_OPTIONS = ageOptions(intl)
  const CAMPAIGN_GENDER_OPTIONS = genderOptions(intl)
  const CAMPAIGN_DURATION_OPTIONS = durationOptions(intl)
  const CAMPAIGN_COUNTRY_OPTIONS = countryOptions(intl)

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...form,
    },
  })

  const handleBack = () => {
    const data = getValues()
    setForm({
      ...form,
      ...data,
      country: [],
      budget: {
        manager: data.budget.manager,
      },
    })
    previous()
  }

  const onSubmit = (data) => {
    setForm({
      ...form,
      ...data,
    })

    next()
  }

  const getCountryConfig = (country) => {
    if (country !== 'it' && form.typologies.includes('tiktokTalents')) {
      return {
        disabled: true,
        subtitle: 'Coming Soon',
      }
    }

    if (country === 'us' && form.typologies.includes('tiktok')) {
      return {
        disabled: true,
        subtitle: 'Coming Soon',
      }
    }

    return {}
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2 mt-2 mb-6">
        <h3 className="text-3xl m-0">
          {intl.formatMessage({ id: 'vip_campaign.step2.title' })}
        </h3>
        <p className="font-light text-lg opacity-80 m-0">
          {intl.formatMessage({ id: 'vip_campaign.step2.text' })}
        </p>
      </div>

      <div className="flex flex-col gap-8">
        {!form.typologies.includes('tiktokTalents') && (
          <>
            <div className="flex flex-col gap-3">
              <h4 className="text-xl m-0">
                {intl.formatMessage({ id: 'vip_campaign.field.age.label' })}
              </h4>
              <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2">
                {CAMPAIGN_AGE_OPTIONS.map((option, index) => (
                  <Controller
                    key={option.value}
                    name="age"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CheckboxCard
                        {...field}
                        className={classNames({
                          'whitespace-nowrap': true,
                          'col-span-2': index === 0,
                        })}
                        name="age"
                        errors={errors?.age}
                        setValue={setValue}
                        getValues={getValues}
                        multiple={true}
                        {...option}
                      />
                    )}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <h4 className="text-xl m-0">
                {intl.formatMessage({ id: 'vip_campaign.field.gender.label' })}
              </h4>
              <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2">
                {CAMPAIGN_GENDER_OPTIONS.map((option, index) => (
                  <Controller
                    key={option.value}
                    name="gender"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CheckboxCard
                        {...field}
                        className={classNames({
                          'whitespace-nowrap': true,
                          'col-span-2': index === 0,
                        })}
                        name="gender"
                        errors={errors?.gender}
                        setValue={setValue}
                        getValues={getValues}
                        multiple={true}
                        {...option}
                      />
                    )}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <h4 className="text-xl m-0">
                {intl.formatMessage({
                  id: 'vip_campaign.field.duration.label',
                })}
              </h4>
              <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2">
                {CAMPAIGN_DURATION_OPTIONS.map((option) => (
                  <Controller
                    key={option.value}
                    name="duration"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CheckboxCard
                        {...field}
                        className="whitespace-nowrap"
                        name="duration"
                        errors={errors?.duration}
                        setValue={setValue}
                        getValues={getValues}
                        {...option}
                      />
                    )}
                  />
                ))}
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col gap-3">
          <h4 className="text-xl m-0">
            {intl.formatMessage({ id: 'vip_campaign.field.country.label' })}
          </h4>
          <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-3">
            {CAMPAIGN_COUNTRY_OPTIONS.map((option) => (
              <Controller
                key={option.value}
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CheckboxCard
                    {...field}
                    className="map-card whitespace-nowrap"
                    name="country"
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors?.country}
                    {...option}
                    {...getCountryConfig(option.value)}
                  >
                    <Maps map={option.value} />
                  </CheckboxCard>
                )}
              />
            ))}
          </div>
        </div>
      </div>

      <StepperFooter className="mt-6">
        <Button
          variant="inverse"
          type="button"
          onClick={handleBack}
          icon="arrow-left"
        >
          {intl.formatMessage({ id: 'messages.previous' })}
        </Button>

        <Button type="submit" icon="arrow-right">
          {intl.formatMessage({ id: 'messages.next' })}
        </Button>
      </StepperFooter>
    </form>
  )
}

export default VipCampaignStep2
