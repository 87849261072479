import React from 'react'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import Button from '@fnd/components/Button'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { ROUTES } from '@fnd/screens/constants'
import { useIntl } from 'react-intl'

const NotFound = () => {
  const intl = useIntl()

  return (
    <Wrapper id="not-found">
      <section className="not-found-wrapper">
        <LogoIcon />

        <h1>{intl.formatMessage({ id: 'not_found.title' })}</h1>

        <p>{intl.formatMessage({ id: 'not_found.subtitle' })}</p>

        <Button
          large
          variant="gradient"
          to={ROUTES.HOME}
          label={intl.formatMessage({ id: 'not_found.cta' })}
          className="mt-8"
        />
      </section>
    </Wrapper>
  )
}

export default NotFound
