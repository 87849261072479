import React from 'react'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@fnd/components/Dropdown/DropdownMenu'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

export function TableColumnHeader({ column, title, className }) {
  const intl = useIntl()

  if (!column.getCanSort()) {
    return <div className={classNames(className)}>{title}</div>
  }

  return (
    <div className={classNames('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            small
            variant="ghost"
            className="-ml-3 data-[state=open]:bg-accent"
          >
            <span>
              {intl.formatMessage({ id: `field.${column.id}.label` }) || title}
            </span>
            {column.getIsSorted() === 'desc' ? (
              <Icon name="arrow-down" className="ml-2" />
            ) : column.getIsSorted() === 'asc' ? (
              <Icon name="arrow-up" className="ml-2" />
            ) : (
              <Icon name="sort" className="ml-2" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
            <Icon name="arrow-up" className="mr-2 text-muted-foreground/70" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
            <Icon name="arrow-down" className="mr-2 text-muted-foreground/70" />
            Desc
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
            <Icon name="eye-slash" className="mr-2 text-muted-foreground/70" />
            {intl.formatMessage({ id: 'messages.hide' })}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
