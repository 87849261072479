import React, { useEffect } from 'react'
import Button from '@fnd/components/Button'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useDiscountStore } from '@fnd/store'
import { getMediaUrl } from '@fnd/core/libs/media'
import { ROUTES } from '@fnd/screens/constants'

export function InvoiceCard({
  cover,
  accessToken,
  description,
  invoiceId,
  className,
}) {
  const intl = useIntl()
  const { setCanUseDiscount } = useDiscountStore()

  useEffect(() => {
    setCanUseDiscount(false)
  }, [])

  const classes = classNames({
    'invoice-ticket': true,
    [className]: className,
  })

  const invoiceDetailUrl = ROUTES.INVOICE.replace(':invoiceId', accessToken)

  return (
    <div className={classes}>
      <div className="p-3 invoice-ticket-body">
        {cover && (
          <div className="mb-4 invoice-ticket-cover">
            <img src={getMediaUrl(cover)} alt="Cover" className="rounded-lg" />
          </div>
        )}

        {description && (
          <h3 className="m-0 text-2xl font-bold">
            {description}
          </h3>
        )}
      </div>

      <div className="invoice-ticket-footer">
        <Button
          to={invoiceDetailUrl}
          className="w-full"
          label={intl.formatMessage({ id: 'promo.card_cta' })}
        />
      </div>
    </div>
  )
}

export default InvoiceCard
