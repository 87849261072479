import React from 'react'

const TeamMember = (props) => {
  const { image, name, description } = props
  return (
    <div className="team-member">
      <div className="team-member-image">
        <img src={image} alt={name} />
      </div>
      <div className="team-member-content">
        <h3>{name}</h3>
        <span>{description}</span>
      </div>
    </div>
  )
}

export default TeamMember
