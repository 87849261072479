import React from 'react'

const EntertainmentDesignLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 92.8">
    <polygon points="0 16 16 0 128 0 112 16 0 16"></polygon>
    <polygon points="0 54.4 16 38.4 128 38.4 112 54.4 0 54.4"></polygon>
    <polygon points="0 92.8 16 76.8 128 76.8 112 92.8 0 92.8"></polygon>
  </svg>
)

export default EntertainmentDesignLogo
