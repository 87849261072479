import React from 'react'
import { Checkbox } from '@fnd/components/Field/Checkbox'
import Badge from '@fnd/components/Badge'
import { TableColumnHeader } from '@fnd/components/Table/TableColumnHeader'
import { PlaylistFeaturedTableRowActions } from './PlaylistFeaturedTableActions'
import { formatNumber } from '@fnd/core/libs/currency'
import { truncate } from 'lodash-es'

export const getPlaylistFeaturedColumns = (intl, actionProps = {}) => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_all' })}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_row' })}
      />
    ),
    show: false,
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'name',
    accessorKey: 'name',
    header: ({ column }) => <TableColumnHeader column={column} title="Name" />,
    cell: ({ row }) => {
      const item = row.original

      return (
        <div className="flex flex-col items-start">
          <span className="font-medium">{item.playlist_name}</span>
          {item?.playlist_description && (
            <span className="text-sm opacity-80">
              {item.playlist_description.length > 80
                ? truncate(item.playlist_description, { length: 100 })
                : item.playlist_description}
            </span>
          )}
        </div>
      )
    },
    enableSorting: true,
    enableHiding: true,
  },
  {
    id: 'followers',
    accessorKey: 'followers',
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Followers" />
    ),
    cell: ({ row }) => {
      const item = row.original

      return <span>{formatNumber(item.followers)}</span>
    },
  },
  {
    id: 'genres',
    accessorKey: 'genres',
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Genres" />
    ),
    cell: ({ row }) => {
      const item = row.original
      if (!item.genres || item.genres.length === 0) return null

      return (
        <div className="flex flex-wrap gap-2">
          {item.genres.map((genre) => (
            <Badge key={genre} variant="primary-light" label={genre} />
          ))}
        </div>
      )
    },
    filterFn: (row, id, value) => {
      const rowValue = row.getValue(id)
      if (!rowValue) return false
      return value.some((genre) => rowValue.includes(genre))
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => (
      <PlaylistFeaturedTableRowActions row={row} {...actionProps} />
    ),
  },
]

export default getPlaylistFeaturedColumns
