import React, { useEffect } from 'react'
import Button from '@fnd/components/Button'
import Separator from '@fnd/components/Separator'
import { CURRENCY_SYMBOL } from '@fnd/constants'
import { StepperFooter } from '@fnd/components/Stepper'
import ArtistPopularity from '@fnd/components/Artist/ArtistPopularity'
import Field, { InputCard, QuantityInput } from '@fnd/components/Field'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useVipCampaignStore } from '@fnd/store'
import { CAMPAIGN_BUDGET_OPTIONS, TIKTOK_TALENTS_OPTIONS } from '../config'
import { VipBudgetSpotify, VipBudgetSpotifyAlgo } from '../Info'
import { VipBoostAddon, VipManagerSupport } from '../Forms'
import {
  VipBudgetAlert,
  VipImpressions,
  VipCampaignSummary,
} from '@fnd/components/VipCampaign'
import parse from 'html-react-parser'

const VipCampaignStep4 = ({ next, previous, plan }) => {
  const {
    form,
    setForm,
    impressions,
    calculatePaymentTotal,
    calculateImpressions,
    getPaymentTotal,
  } = useVipCampaignStore()

  const intl = useIntl()

  const { control, getValues, setValue, handleSubmit, watch } = useForm({
    defaultValues: {
      ...form,
    },
  })

  const updateImpressions = () => {
    CAMPAIGN_BUDGET_OPTIONS.forEach((option) => {
      calculateImpressions(option.id, formData, plan)
    })
  }

  const handleBack = () => {
    const data = getValues()
    setForm({
      ...form,
      ...data,
    })
    previous()
  }

  const onSubmit = (data) => {
    setForm({
      ...form,
      ...data,
    })

    next()
  }

  const formData = watch()
  const budget = watch('budget')

  useEffect(() => {
    calculatePaymentTotal(formData, plan)
    updateImpressions()
  }, [])

  useEffect(() => {
    calculatePaymentTotal(formData, plan)
    updateImpressions()
  }, [JSON.stringify(budget)])

  return (
    <>
      <div className="grid md:grid-cols-3 gap-8">
        <form onSubmit={handleSubmit(onSubmit)} className="md:col-span-2">
          <div className="flex flex-col gap-2 mt-2 mb-6">
            <h3 className="text-3xl m-0">
              {intl.formatMessage({ id: 'vip_campaign.step4.title' })}
            </h3>
            {!form.typologies.includes('tiktokTalents') ? (
              <p className="font-light text-lg opacity-80 m-0">
                {intl.formatMessage({ id: 'vip_campaign.step4.text' })}
              </p>
            ) : (
              <p className="font-light text-lg opacity-80 m-0">
                {intl.formatMessage({
                  id: 'vip_campaign.step4.tiktok_talents',
                })}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-8">
            <div className="flex flex-wrap gap-2">
              {CAMPAIGN_BUDGET_OPTIONS.map(
                (option) =>
                  form?.typologies.includes(option.id) &&
                  !option.hide && (
                    <Controller
                      key={option.id}
                      name={`budget.${option.id}`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <QuantityInput
                          {...field}
                          cardVariant={true}
                          name={`budget.${option.id}`}
                          className="input-card"
                          setValue={setValue}
                          getValues={getValues}
                          inputProps={{
                            prefix: CURRENCY_SYMBOL,
                          }}
                          {...option}
                        >
                          <div className="w-full flex flex-col gap-2 mt-2">
                            <VipImpressions
                              id={option.id}
                              formData={formData}
                              impressions={impressions}
                            />
                          </div>
                        </QuantityInput>
                      )}
                    />
                  )
              )}

              {form?.typologies.includes('tiktokTalents') && (
                <Controller
                  key="budget.tiktokTalents"
                  name={`budget.tiktokTalents`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputCard label="TikTok Talents" icon="user-music">
                      <Field
                        type="select"
                        name="budget.tiktokTalents"
                        className="w-full"
                        isClearable={false}
                        options={TIKTOK_TALENTS_OPTIONS.map((item) => ({
                          label: intl.formatMessage(
                            { id: 'messages.talent_count' },
                            { count: item.value }
                          ),
                          value: item.value,
                        }))}
                        defaultValue={
                          budget.tiktokTalents || TIKTOK_TALENTS_OPTIONS[0]
                        }
                        setValue={setValue}
                        getValues={getValues}
                        onChange={() => {
                          setValue('budget.tiktokTalents', field.value)
                        }}
                        {...field}
                      />

                      <div className="w-full flex flex-col gap-2 mt-2">
                        <VipImpressions
                          id="tiktokTalents"
                          impressions={impressions}
                        />
                      </div>
                    </InputCard>
                  )}
                />
              )}
            </div>

            <VipBudgetAlert
              budget={formData.budget}
              form={formData}
              formData={formData}
            />

            <VipManagerSupport form={form} control={control} />

            <VipBoostAddon budget={budget} form={form} control={control} />

            {form?.typologies.includes('spotify') && <VipBudgetSpotify />}

            {form?.typologies.includes('spotifyAlgo') && (
              <VipBudgetSpotifyAlgo />
            )}
          </div>
        </form>

        <div className="md:pt-20">
          <VipCampaignSummary
            className="relative md:sticky md:top-24"
            campaign={formData}
            impressions={impressions}
            payment={getPaymentTotal()}
          />
        </div>
      </div>

      {(form?.typologies.includes('spotify') ||
        form?.typologies.includes('tiktok') ||
        form?.typologies.includes('youtube')) && (
        <>
          <Separator className="my-6" />

          <p className="flex flex-wrap md:flex-nowrap gap-1 md:gap-8 font-light opacity-80 text-sm">
            {form?.typologies.includes('spotify') && (
              <span>
                {parse(
                  intl.formatMessage({ id: 'vip_campaign.disclaimer.spotify' })
                )}
              </span>
            )}

            {(form?.typologies.includes('tiktok') ||
              form?.typologies.includes('youtube')) && (
              <span>
                {intl.formatMessage({ id: 'vip_campaign.disclaimer.generic' })}
              </span>
            )}
          </p>
        </>
      )}

      <StepperFooter className="mt-6">
        <Button
          variant="inverse"
          type="button"
          onClick={handleBack}
          icon="arrow-left"
        >
          {intl.formatMessage({ id: 'messages.previous' })}
        </Button>

        <Button icon="arrow-right" onClick={handleSubmit(onSubmit)}>
          {intl.formatMessage({ id: 'messages.next' })}
        </Button>
      </StepperFooter>
    </>
  )
}

export default VipCampaignStep4
