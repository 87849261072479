import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '@fnd/components/Icon'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'
import classNames from 'classnames'

const SubNavItem = ({
  color = 'primary',
  icon,
  iconType,
  title,
  subtitle,
  to,
  className,
}) => {
  const classes = classNames({
    'sub-menu-item': true,
    [className]: className,
  })

  return (
    <li className={classes}>
      <NavLink to={to}>
        <span className={`icon icon-${color}`}>
          <Icon name={icon} type={iconType ?? DEFAULT_ICON_TYPE} />
        </span>
        <div className="flex flex-col items-start">
          <h5>{title}</h5>
          <p className="text-balance">{subtitle}</p>
        </div>
      </NavLink>
    </li>
  )
}

export default SubNavItem

SubNavItem.defaultProps = {
  iconType: 'brands',
}
