import React, { useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { MatchSwitch } from '@fnd/components/Match'
import { applyTheme, resetTheme } from '@fnd/core/libs/helpers'
import RadioList from './RadioList'

export function RadioMatch() {
  useEffect(() => {
    applyTheme('blue', 'secondary')

    return () => {
      resetTheme()
    }
  }, [])

  return (
    <Wrapper>
      <Auth login emptyState>
        <MatchSwitch initial="radio" className="mb-8" />

        <RadioList />
      </Auth>
    </Wrapper>
  )
}

export default RadioMatch
