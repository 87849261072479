import React from 'react'
import { Link } from 'react-router-dom'

export default function ConditionalLink({
  children,
  to,
  condition,
  external,
  ...props
}) {
  if (!!condition && !external && to) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  }

  if (!!condition && external && to) {
    return (
      <a href={to} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    )
  }

  return (
    <div {...props} href={null}>
      {children}
    </div>
  )
}
