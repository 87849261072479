import React from 'react'
import { ROUTES } from '@fnd/screens/constants'
import { TrustpilotMiniWidget } from '@fnd/components/Trustpilot'
import Icon from '../Icon'

const Social = () => {
  return (
    <div className="flex flex-col items-start justify-between">
      <ul className="footer-socials">
        <li>
          <a
            className="text-facebook"
            href={ROUTES.FACEBOOK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="facebook" type="brands" />
          </a>
        </li>

        <li>
          <a
            className="text-instagram"
            href={ROUTES.INSTAGRAM}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="instagram" type="brands" />
          </a>
        </li>
      </ul>

      <TrustpilotMiniWidget className="mt-6 md:mt-0 ml-[-1.5rem]" />
    </div>
  )
}

export default Social
