import React, { useContext } from 'react'
import AccelerateForm from '@fnd/components/Accelerate/AccelerateForm'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import Card from '@fnd/components/Card'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import FankeeLogo from '@fnd/components/Logo/FankeeLogo'
import SectionTitle from '@fnd/components/SectionTitle'
import { useIntl, FormattedMessage } from 'react-intl'
import { ThemeContext } from '@fnd/context/ThemeProvider'
import Wrapper from '@fnd/components/Wrapper'

const Contest = () => {
  const { currentTheme } = useContext(ThemeContext)
  const intl = useIntl()

  return (
    <Wrapper>
      <section className="max-w-3xl mx-auto pt-12">
        <p className="text-3xl text-center text-balance mb-10">
          <FormattedMessage
            id="contest.welcome"
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </p>

        <div className="grid grid-cols-[1fr_50px_1fr] gap-8 items-center max-w-xl mx-auto">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0,
              color: '#ffe600',
              fill: '#ffe600',
              backgroundColor:
                currentTheme === 'light' ? '#010101' : 'transparent',
              padding: '2rem',
              borderRadius: '.5rem',
              aspectRatio: '1/1',
            }}
          >
            <FankeeLogo className="w-full h-full" />
          </div>
          <span className="text-5xl font-bold">X</span>
          <div>
            <LogoIcon className="w-full h-full" />
          </div>
        </div>
      </section>

      <section className="max-w-3xl mx-auto">
        <SectionTitle
          tag="h3"
          title={intl.formatMessage({ id: 'contest.how_it_works_title' })}
          subtitle={intl.formatMessage({ id: 'contest.how_it_works_subtitle' })}
          center
        />

        <Card className="w-full text-lg font-medium p-4 mb-8">
          <Badge
            large
            label={intl.formatMessage({ id: 'contest.for_artists' })}
            icon="microphone"
            variant="primary-light"
            className="mb-4"
          />

          <h3 className="text-3xl text-balance">
            {intl.formatMessage({ id: 'contest.selection_description' })}
          </h3>

          <ul className="list">
            <li>
              <FormattedMessage
                id="contest.requirement_unpublished"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </li>

            <li>
              {intl.formatMessage({ id: 'contest.contract_description' })}
            </li>
            <li>
              <mark>
                <FormattedMessage
                  id="contest.winning_criteria"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </mark>
            </li>
          </ul>
        </Card>
      </section>

      <section className="max-w-3xl mx-auto">
        <SectionTitle
          tag="h3"
          title={intl.formatMessage({ id: 'contest.prizes_title' })}
          subtitle={intl.formatMessage({ id: 'contest.prizes_subtitle' })}
          badge={
            <Badge
              label={intl.formatMessage({ id: 'contest.prizes_badge' })}
              icon="trophy"
              variant="primary-light"
              className="mb-4"
            />
          }
          center
        />

        <img
          src="/images/home/matchfy_contest.jpg"
          alt={intl.formatMessage({ id: 'contest.image_alt' })}
          className="w-full rounded-xl mb-6"
        />

        <div className="grid grid-cols-1 gap-8">
          <Card className="w-full text-lg font-medium p-4">
            <p>{intl.formatMessage({ id: 'contest.promo_plan' })}</p>
          </Card>
        </div>
      </section>

      <section className="max-w-3xl mx-auto">
        <SectionTitle
          tag="h3"
          title={intl.formatMessage({ id: 'contest.how_to_participate_title' })}
          subtitle={intl.formatMessage({
            id: 'contest.how_to_participate_subtitle',
          })}
          center
        />

        <div className="flex flex-col items-start gap-4">
          <Card className="w-full text-lg font-medium p-4">
            {intl.formatMessage({ id: 'contest.requirement_account' })}
          </Card>
          <Card className="w-full text-lg font-medium p-4">
            <FormattedMessage
              id="contest.requirement_unpublished_song"
              values={{
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </Card>
          <Alert variant="warning" className="w-full text-lg font-medium p-4">
            {intl.formatMessage({ id: 'contest.genre_restriction' })}
          </Alert>
        </div>
      </section>

      <section className="max-w-3xl mx-auto mb-12">
        <SectionTitle
          tag="h3"
          title={intl.formatMessage({ id: 'contest.submit_title' })}
          subtitle={intl.formatMessage({ id: 'contest.submit_subtitle' })}
          center
        />

        <AccelerateForm
          bypassLimit={true}
          btnLabel={intl.formatMessage({ id: 'contest.submit_button' })}
        />
      </section>
    </Wrapper>
  )
}

export default Contest
