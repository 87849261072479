import React, { useState, useEffect } from 'react'
import Badge from '@fnd/components/Badge'
import Info from '@fnd/components/Info'
import { PLANS } from '@fnd/constants'
import { PlaylistFeaturedContacts } from '@fnd/modules/PlaylistFeatured'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'

export function PlaylistFeaturedDetail({
  contacts,
  description,
  name,
  spotifyUrl,
  tags,
  className,
}) {
  const [isAgency, setIsAgency] = useState(false)

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  useEffect(() => {
    if (profile?.plan?.name === PLANS.AGENCY) {
      setIsAgency(true)
    }
  }, [profile])

  const intl = useIntl()

  const classes = classNames({
    'radio-detail': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      {description && <p>{description}</p>}

      <Info
        label={<FormattedMessage id="field.playlist_name.label" />}
        value={name}
      />

      <Info
        label={<FormattedMessage id="field.spotify_url.label" />}
        value={
          <a
            href={spotifyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="truncate"
          >
            <Icon name="external-link" className="mr-2" />
            {intl.formatMessage({ id: 'messages.open_in_spotify' })}
          </a>
        }
      />

      {isAgency ? (
        <>
          <Info
            icon="tags"
            label="Tags"
            value={
              <div className="flex flex-wrap gap-2">
                {tags?.map((tag) => (
                  <Badge key={tag} variant="primary-light" label={tag} />
                ))}
              </div>
            }
          />

          <PlaylistFeaturedContacts contacts={contacts} />
        </>
      ) : (
        <AccountUpgrade
          small
          btnVariant="gradient"
          btnClasses="w-full"
          className="mt-4"
          message={intl.formatMessage(
            { id: 'profile.upgrade.radio' },
            {
              plan: 'agency',
            }
          )}
          plan={PLANS.AGENCY}
        />
      )}
    </div>
  )
}

export default PlaylistFeaturedDetail
