import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { COMPANY_NAME, WHISTLEBLOWING_URL, VAT_NUMBER } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import EntertainmentDesignLogo from '@fnd/components/Logo/EntertainmentDesignLogo'

const Copyright = () => {
  return (
    <div className="copyright">
      <div>
        <span>
          {COMPANY_NAME} ® {new Date().getFullYear()} All Rights Reserved
        </span>

        <span>
          <FormattedMessage id="messages.vat" /> {VAT_NUMBER}
        </span>

        <Link to={ROUTES.TERMS}>
          <FormattedMessage id="navigation.terms" />
        </Link>

        <Link to={ROUTES.PRIVACY}>
          <FormattedMessage id="navigation.privacy" />
        </Link>

        <Link to={ROUTES.SLA}>SLA</Link>

        <Link to={ROUTES.CONTRACT_OF_SERVICE}>
          <FormattedMessage id="payment.contract_of_service" />
        </Link>

        <a href={WHISTLEBLOWING_URL} target="_blank" rel="noreferrer">
          Whistleblowing
        </a>
      </div>

      <div className="credits">
        <a
          className="flex items-center"
          href="//ed3sign.com?rel=matchfy"
          rel="noreferrer"
          target="_blank"
        >
          <EntertainmentDesignLogo />
          <span className="ml-3">{' Entertainment Designed'}</span>
        </a>
      </div>
    </div>
  )
}

export default Copyright
