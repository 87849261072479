import React from 'react'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import { formatCurrency } from '@fnd/core/libs/currency'
import { getSpotifyMinBudget } from '@fnd/core/libs/helpers'
import { useIntl } from 'react-intl'

const VipBudgetAlert = ({ budget, form, formData }) => {
  const intl = useIntl()
  const { locale } = intl

  const minBudget = getSpotifyMinBudget(
    formData?.info?.spotify?.artist?.popularity ?? 0
  )
  const typologies = form?.typologies

  const hideAlert = typologies.every((_) => {
    const _budget = budget[_]
    return minBudget <= _budget
  })

  if (hideAlert || !typologies.includes('spotify')) return null

  return (
    <Alert variant="info" contentClasses="items-start">
      <Badge variant="blue" label="AI" icon="sparkles" className="mb-2" />

      <p>
        {intl.formatMessage(
          { id: `vip_campaign.budget_warning` },
          { budget: formatCurrency(minBudget, locale) }
        )}
      </p>
    </Alert>
  )
}

export default VipBudgetAlert
