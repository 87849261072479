import React, { useState, useEffect } from 'react'
import Button from '@fnd/components/Button'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useAppStore } from '@fnd/store'
import { DEVELOPMENT } from '@fnd/constants'
import { useUserContext, isUserAuthorizedSelector } from '@fnd/modules/User'

export function BannerReload({ className }) {
  const intl = useIntl()
  const [showBanner, setShowBanner] = useState(false)
  const { version } = useAppStore()

  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  useEffect(() => {
    const appVersion = window.localStorage.getItem('version')
    if ((appVersion && appVersion !== version) || !appVersion) {
      setShowBanner(true)
    }
  }, [])

  const classes = classNames({
    banner: true,
    'banner-fixed': true,
    'banner-card': true,
    'banner-bottom-right': true,
    [className]: className,
  })

  const handleReload = () => {
    window.localStorage.setItem('version', version)
    window.location.reload()
  }

  if (!showBanner || !isAuthorized || DEVELOPMENT) return null

  return (
    <div className={classes}>
      <div className="banner-content flex-col h-auto items-start gap-1">
        <p className="font-bold">
          {intl.formatMessage({ id: 'banner.reload.title' })}
        </p>
        <p>{intl.formatMessage({ id: 'banner.reload.text' })}</p>
      </div>

      <Button wide small onClick={() => handleReload()} variant="primary">
        {intl.formatMessage({ id: 'banner.reload.cta' })}
      </Button>
    </div>
  )
}

export default BannerReload
