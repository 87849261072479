import React, { useState } from 'react'
import Alert from '../Alert'

function Catcher(props) {
  const [error, setError] = useState(null)
  const [info, setInfo] = useState(null)

  function handleCatch(error, info) {
    setError(error)
    setInfo(info.componentStack.split('\n').map((line) => line.trim()))
  }

  if (error || info) {
    return (
      <Alert variant="danger">
        <h2 className="alert-heading">Oh snap! Error just occurred</h2>
        <h3>{error?.name}</h3>
        <p className="mb-1">
          <strong>{"Don't worry. Try to reload the page and continue."}</strong>
        </p>
        <p>
          <strong>
            For the time being, we have received an error and will work to
            ensure that this does not happen again.
          </strong>
        </p>

        {info.map((line) => (
          <p key={line} className="m-0">
            {line}
          </p>
        ))}
      </Alert>
    )
  }

  return props.children
}

export default Catcher
