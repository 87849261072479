import React from 'react'
import Badge from '@fnd/components/Badge'
import { Controller } from 'react-hook-form'
import { formatCurrency } from '@fnd/core/libs/currency'
import { useIntl } from 'react-intl'
import { CAMPAIGN_MANAGER_PRICE } from '../config'

const VipManagerSupport = ({ form, control }) => {
  const intl = useIntl()
  const { locale } = intl

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="inline-flex flex-col items-start md:flex-row md:items-center gap-4">
        <h3 className="text-2xl m-0">
          {intl.formatMessage({ id: 'vip_campaign.manager.title' })}
        </h3>

        <Badge
          medium
          className="capitalize"
          variant="primary-light"
          icon="star"
          label={intl.formatMessage({ id: 'messages.recommended' })}
        />
      </div>

      <p className="font-light opacity-90 mt-0 mb-4">
        {intl.formatMessage({ id: 'vip_campaign.manager.text' })}
      </p>

      <Controller
        key="manager"
        name="budget.manager"
        control={control}
        render={({ field }) => (
          <label>
            <input
              type="checkbox"
              defaultChecked={form?.budget?.manager}
              {...field}
            />
            {intl.formatMessage({
              id: 'vip_campaign.field.manager.label',
            })}
            <span className="ml-2 text-base font-medium">
              + {formatCurrency(CAMPAIGN_MANAGER_PRICE, locale)}
            </span>
          </label>
        )}
      />
    </div>
  )
}

export default VipManagerSupport
