import React, { useState, useEffect, memo } from 'react'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import PushProgress from './PushProgress'
import { Modal } from '@fnd/components/Modal'
import { TrackPopularityBadge, TrackPopularityBar } from '@fnd/components/Track'
import {
  calculatePushRange,
  getPopularityColor,
} from '@fnd/core/libs/popularity'
import { useIntl } from 'react-intl'
import { formatNumber } from '@fnd/core/libs/currency'
import Separator from '@fnd/components/Separator'

export const PushCounter = React.memo(function PushCounter({
  pushCount,
  popularity,
}) {
  const [showFeedback, setShowFeedback] = useState(false)
  const [pushRange, setPushRange] = useState({ low: 0, high: 0 })
  const intl = useIntl()

  const getPopularityRange = () => {
    if (popularity < 30) {
      return 'low'
    } else if (popularity >= 30 && popularity <= 70) {
      return 'medium'
    }

    return 'high'
  }

  const getFeedback = () => {
    const popularityRange = getPopularityRange()
    const feedbackTitle = intl.formatMessage({
      id: `track_popularity.${popularityRange}.title`,
    })
    const feedbackText = intl.formatMessage({
      id: `track_popularity.${popularityRange}.text`,
    })

    return {
      title: feedbackTitle,
      text: feedbackText,
    }
  }

  useEffect(() => {
    setPushRange(calculatePushRange(popularity))
  }, [popularity])

  return (
    <div className="flex items-center gap-3">
      {popularity && (
        <Badge
          variant="primary-light"
          label={intl.formatMessage({ id: 'messages.push_count' })}
          value={pushCount > 0 ? pushCount : `0`}
          icon="arrow-right"
          iconRight="info-circle"
          onClick={() => setShowFeedback(true)}
        />
      )}

      {popularity && <TrackPopularityBadge popularity={popularity} />}

      <Modal
        className="modal-xs modal-feedback"
        title={getFeedback().title}
        isOpen={showFeedback}
        onClose={() => setShowFeedback(false)}
      >
        <div className="flex flex-col gap-4 p-5">
          <TrackPopularityBar popularity={popularity} />

          <p>{getFeedback().text}</p>

          <PushProgress
            pushCount={pushCount}
            pushRange={pushRange}
            popularity={popularity}
          />

          <Alert color={getPopularityColor(popularity)}>
            <p className="text-xl">
              {intl.formatMessage({ id: 'track_popularity.range' })}:{' '}
              {formatNumber(pushRange.low)} - {formatNumber(pushRange.high)}
            </p>
          </Alert>

          <Separator />

          <p className="text-sm opacity-70">
            {intl.formatMessage({ id: 'track_popularity.notes' })}
          </p>
        </div>
      </Modal>
    </div>
  )
})

export default PushCounter
