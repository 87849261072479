import React, { useCallback } from 'react'
import { MatchForm } from '@fnd/components/Match'
import classNames from 'classnames'
import { useMatchStore } from '@fnd/store'

function AnalysisWidget({ className, playlist, onSubmit }) {
  const { getMatch } = useMatchStore()

  const classes = classNames({
    'analysis-widget': true,
    [className]: className,
  })

  const handlePlaylistMatch = useCallback(async () => {
    if (!playlist || !playlist.id) return

    const matchData = await getMatch(playlist.id)

    if (matchData && onSubmit) {
      onSubmit(matchData)
    }
  }, [playlist])

  return (
    <div className={classes}>
      <MatchForm
        btnLabel={`Match with ${playlist?.name}`}
        btnVariant="gradient"
        matchTypeEnabled="spotify"
        onSubmit={handlePlaylistMatch}
      />
    </div>
  )
}

export default AnalysisWidget
