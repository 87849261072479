import React from 'react'
import { useIntl } from 'react-intl'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import Stepper from '@fnd/components/Stepper'
import {
  VipCampaignStep1,
  VipCampaignStep2,
  VipCampaignStep3,
  VipCampaignStep4,
  VipCampaignStep5,
} from './Steps'
import { useStepper } from '@fnd/core/hooks/useStepper'
import { useConsent } from '@fnd/context/ConsentProvider'
import {
  useUserContext,
  userPlanSelector,
  userProfileSelector,
} from '@fnd/modules/User'
import { useVipCampaignStore } from '@fnd/store'
import classNames from 'classnames'

const VipCampaign = ({ className }) => {
  const { profile, plan } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    plan: userPlanSelector(user),
  }))

  const intl = useIntl()

  const steps = [
    {
      key: 'vip-step-1',
      label: intl.formatMessage({ id: 'vip_campaign.step1.label' }),
      icon: 'megaphone',
      content: <VipCampaignStep1 />,
    },
    {
      key: 'vip-step-2',
      label: intl.formatMessage({ id: 'vip_campaign.step2.label' }),
      icon: 'bullseye-pointer',
      content: <VipCampaignStep2 />,
    },
    {
      key: 'vip-step-3',
      label: intl.formatMessage({ id: 'vip_campaign.step3.label' }),
      icon: 'user-music',
      content: <VipCampaignStep3 />,
    },
    {
      key: 'vip-step-4',
      label: intl.formatMessage({ id: 'vip_campaign.step4.label' }),
      icon: 'money-bill-wave',
      content: <VipCampaignStep4 />,
    },
    {
      key: 'vip-step-5',
      label: intl.formatMessage({ id: 'vip_campaign.step5.label' }),
      icon: 'check',
      content: <VipCampaignStep5 />,
    },
  ]

  const handleStepChange = () => {
    const stepper = document.querySelector('.stepper')
    if (stepper) {
      stepper.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const [currentStep, { set, previous, next }] = useStepper(
    steps[0].key,
    steps,
    handleStepChange
  )
  const stepProps = { currentStep, set, previous, next, plan, profile }
  const { handleIntercomToggle } = useConsent()
  const {
    error,
    loading,
    success,
    resetFeedback,
    // resetForm,
  } = useVipCampaignStore()

  const handleRestart = () => {
    // resetForm()
    resetFeedback()
    set(steps[0].key)
  }

  const classes = classNames({
    'vip-campaign': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      {loading && <Spinner />}

      {error && (
        <Empty message={intl.formatMessage({ id: error })} variant="error">
          <Button
            className="mt-5"
            icon="redo-alt"
            label={intl.formatMessage({ id: 'messages.retry' })}
            variant="red"
            onClick={handleRestart}
          />
        </Empty>
      )}

      {success && (
        <Empty
          variant="success"
          icon="check-circle"
          message={intl.formatMessage({ id: success })}
        >
          <Button
            className="mt-5"
            icon="redo-alt"
            label={intl.formatMessage({ id: 'messages.restart' })}
            variant="green"
            onClick={handleRestart}
          />
        </Empty>
      )}

      {!error && !success && !loading && (
        <>
          <Stepper
            steps={steps}
            currentStep={currentStep}
            set={set}
            enableNavigation={false}
            previous={previous}
            next={next}
          />

          <div className="card border-outline p-5 md:p-6 mt-5">
            <div className="vip-campaign-help">
              <p>{intl.formatMessage({ id: 'vip_campaign.help' })}</p>
              <Button
                small
                variant="primary-light"
                icon="question-circle"
                className="toggle-intercom"
                onClick={handleIntercomToggle}
                label={intl.formatMessage({ id: 'messages.support' })}
              />
            </div>

            {React.cloneElement(currentStep.content, { ...stepProps })}
          </div>
        </>
      )}
    </div>
  )
}

export default VipCampaign
