import React from 'react'
import classNames from 'classnames'

const YouTubeLogoIcon = ({ className }) => (
  <svg
    className={classNames({ [className]: className })}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M146.873 39.3931C145.156 32.948 140.069 27.867 133.607 26.1272C121.913 23 75 23 75 23C75 23 28.1098 23 16.3931 26.1272C9.94798 27.8439 4.86705 32.9306 3.12717 39.3931C0 51.0867 0 75.4971 0 75.4971C0 75.4971 0 99.9075 3.12717 111.601C4.84393 118.046 9.93064 123.127 16.3931 124.867C28.1098 127.994 75 127.994 75 127.994C75 127.994 121.913 127.994 133.607 124.867C140.052 123.15 145.133 118.064 146.873 111.601C150 99.9075 150 75.4971 150 75.4971C150 75.4971 150 51.0867 146.873 39.3931Z"
      fill="#FF0000"
    />
    <path
      d="M60.0115 52.9943V98L98.9826 75.4972L60.0115 52.9943Z"
      fill="white"
    />
  </svg>
)

export default YouTubeLogoIcon
