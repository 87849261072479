import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { LogoFull, LogoIcon } from '@fnd/components/Logo/Logo'
import LanguageDropdown from '@fnd/components/LanguageDropdown/LanguageDropdown'
import { EVENTS, DEFAULT_ICON_TYPE } from '@fnd/constants'
import {
  ROUTES,
  NAV,
  MATCH_DROPDOWN,
  RANK_DROPDOWN,
} from '@fnd/screens/constants'
import { CAMPAIGN_TYPOLOGY_OPTIONS } from '@fnd/components/VipCampaign/config'
import Icon from '@fnd/components/Icon'
import NavigationToggle from './NavigationToggle'
import NavigationAlternate from './NavigationAlternate'
import NavigationDropdown from './NavigationDropdown'

const Navigation = ({ navigationToggleHandler }) => {
  const intl = useIntl()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const navbar = document.getElementById('navbar')
    if (navbar) {
      if (window.scrollY > 25) {
        navbar.classList.add('nav-fixed')
      } else {
        navbar.classList.remove('nav-fixed')
      }
    }
  }

  return (
    <header id="navbar" className="nav">
      <nav>
        <div className="nav-left">
          <div className="nav-logo">
            <div className="nav-toggle">
              <NavigationToggle click={navigationToggleHandler} />
            </div>
            <Link to={ROUTES.HOME}>
              <span className="hidden lg:block">
                <LogoFull />
              </span>
              <span className="block lg:hidden">
                <LogoIcon />
              </span>
            </Link>
          </div>
          <ul>
            <NavigationDropdown
              to={ROUTES.MATCH}
              icon="music"
              label={intl.formatMessage({ id: 'navigation.match' })}
              items={MATCH_DROPDOWN(intl).filter((item) => item.show)}
            />

            {NAV.map(
              (item) =>
                item.show && (
                  <li key={item.link}>
                    <NavLink
                      to={item.link}
                      className={({ isActive }) => (isActive ? ' active' : '')}
                    >
                      {item.icon && <Icon name={item.icon} />}
                      <FormattedMessage id={item.title} />
                    </NavLink>
                  </li>
                )
            )}

            <NavigationDropdown
              to={ROUTES.VIP}
              icon="gem"
              label="VIP Promo"
              items={CAMPAIGN_TYPOLOGY_OPTIONS(intl)
                .filter((item) => item.enabled)
                .map((item) => ({
                  icon: item.icon,
                  iconType: item?.iconType ?? DEFAULT_ICON_TYPE,
                  title: item.label,
                  subtitle: item.subtitle,
                  to: `${ROUTES.VIP}?type=${item.value}`,
                }))}
            />

            <NavigationDropdown
              to={ROUTES.RANK}
              icon="trophy"
              label={intl.formatMessage({ id: 'navigation.rank' })}
              items={RANK_DROPDOWN(intl)}
            />
          </ul>
        </div>

        <div className="nav-right">
          <ul>
            <li className="nav-item flex items-center">
              <NavigationAlternate />
            </li>
          </ul>
          <LanguageDropdown />
        </div>
      </nav>
    </header>
  )
}

export default Navigation
