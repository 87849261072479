import React, { useState, useEffect } from 'react'
import {
  TableArrayFilter,
  TableResetButton,
  TableViewOptions,
} from '@fnd/components/Table'
import Field from '@fnd/components/Field'

export default function ({ data, table }) {
  const [genreOptions, setGenreOptions] = useState([])

  useEffect(() => {
    if (data && data.length > 0) {
      const uniqueGenres = new Set()
      data.forEach((row) => {
        if (row.genres) {
          row.genres.forEach((genre) => uniqueGenres.add(genre))
        }
      })

      const sortedGenreOptions = [...uniqueGenres]
        .sort((a, b) => a.localeCompare(b))
        .map((genre) => ({ label: genre, value: genre }))

      setGenreOptions(sortedGenreOptions)
    }
  }, [data])

  return (
    <div className="table-filters">
      <div className="flex flex-col md:flex-row flex-wrap items-center gap-2 w-full">
        <Field
          className="w-full flex-1"
          placeholder="Filter by name"
          value={table.getColumn('name')?.getFilterValue() ?? ''}
          onChange={(event) =>
            table.getColumn('name')?.setFilterValue(event.target.value)
          }
        />

        {table.getColumn('genres') && (
          <TableArrayFilter
            icon="tags"
            className="w-full flex-1"
            column={table.getColumn('genres')}
            title="Filter by genres"
            options={genreOptions}
          />
        )}
      </div>

      <div className="flex items-start md:flex-row flex-1 w-full md:items-center gap-2">
        <TableResetButton table={table} />

        <TableViewOptions table={table} />
      </div>
    </div>
  )
}
