import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import { CuratorScore } from '@fnd/components/Card'
import { MatchDetails } from '@fnd/components/PlaylistDetails'
import Spinner from '@fnd/components/Spinner'
import { isMobile } from 'react-device-detect'
import { Placeholder } from '@fnd/components/Placeholder'
import { useMatchStore, usePushToPlaylistStore, useUserStore } from '@fnd/store'
import { throttle } from 'lodash'
import { MATCH_CONFIG } from '@fnd/constants'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

export const PlaylistMatchCard = (props) => {
  const { onClick, item } = props

  const { score, values, playlist, curator } = item

  const [matchScore, setMatchScore] = useState(score)
  const [matchValues, setMatchValues] = useState(values)
  const { getMatch } = useMatchStore()
  const { checkPushedPlaylist } = usePushToPlaylistStore()
  const isPushed = checkPushedPlaylist(item)

  const { starUsers } = useUserStore()
  const isStar = starUsers?.includes(curator?.id)

  const { ref, inView } = useInView({
    threshold: 0,
  })

  const handlePlaylistMatch = throttle(async () => {
    if (!playlist || !playlist.id) return

    const matchData = await getMatch(playlist.id)
    setMatchScore(matchData?.score ?? -1)
    setMatchValues(matchData?.values)
  }, MATCH_CONFIG.SCORE_FETCH_THROTTLE)

  useEffect(() => {
    if (inView && (!matchScore || !matchValues)) {
      handlePlaylistMatch()
    }
  }, [score, values, inView])

  const handlePlaylistClick = () => {
    if (onClick) {
      onClick(item)
    }
  }

  const classes = classNames({
    'card-playlist': true,
    pushed: isPushed,
    star: isStar,
  })

  return (
    <Button
      ref={ref}
      variant="empty"
      className="button-playlist"
      onClick={handlePlaylistClick}
    >
      <div className={classes}>
        <div className="card-header">
          {isStar && <Badge variant="gold" icon="star" label="Star" />}
        </div>

        {isPushed && (
          <div className="pushed-overlay">
            <Icon className="text-3xl mr-1" name="check" />
            <FormattedMessage id="match.card.pushed" />
          </div>
        )}

        <div className="card-image">
          <div className="overlay" />

          <div className="card-content">
            <div className="card-title">
              <h3>{playlist?.name}</h3>
              <CuratorScore compact curator={curator} truncateLength={12} />
            </div>

            <div className="card-match-score">
              {matchScore > 0 && <span className="score">{matchScore}%</span>}

              {matchScore === -1 && (
                <span className="score">
                  <span>-</span>
                </span>
              )}

              {matchScore === 0 && (
                <Spinner variant="white" inline className="mb-1" />
              )}
              <span className="font-light tracking-widest uppercase text-xs opacity-80">
                Match
              </span>
            </div>

            <div className="card-match-progress">
              <div className="progress">
                <div className="progress-bar" style={{ width: `${score}%` }} />
              </div>
            </div>
          </div>

          {playlist?.image && (
            <img src={playlist?.image} alt={playlist?.name} />
          )}

          {!playlist?.image && <Placeholder variant="large" icon="music" />}
        </div>

        {!isMobile && !isPushed && (
          <div className="card-data">
            <MatchDetails values={matchValues} />
          </div>
        )}
      </div>
    </Button>
  )
}

export default PlaylistMatchCard
