import React from 'react'
import Button from '@fnd/components/Button'
import { useIntl } from 'react-intl'

export function PlaylistFeaturedTableRowActions({ row, setCurrentItem }) {
  const item = row.original
  const intl = useIntl()

  const handleRowClick = () => {
    setCurrentItem(item)
  }

  return (
    <>
      <Button
        small
        icon="eye"
        variant="theme-primary-light"
        className="whitespace-nowrap"
        label={intl.formatMessage({ id: 'messages.view' })}
        onClick={handleRowClick}
      />
    </>
  )
}

export default PlaylistFeaturedTableRowActions
