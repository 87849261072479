import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Bootstrap from './Bootstrap'

const container = document.querySelector('#root')
const root = createRoot(container)

const Entry = () => {
  return (
    <BrowserRouter>
      <Bootstrap />
    </BrowserRouter>
  )
}

root.render(<Entry />)
