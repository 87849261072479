import React, { useState, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'
import { NavLink } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { TAB_NAV as routes } from '@fnd/screens/constants'

export function NavigationTabs() {
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [fixed, setFixed] = useState(false)
  const [hidden, setHidden] = useState(false)

  const scrollOffset = 50
  const intl = useIntl()

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset
    setFixed(currentScrollPos > scrollOffset)
    setHidden(
      currentScrollPos > scrollOffset && prevScrollPos < currentScrollPos
    )
    setPrevScrollPos(currentScrollPos)
  }, [prevScrollPos])

  const renderTabs = (routes) => {
    return routes.map(
      (link) =>
        link.show && (
          <li className="nav-item" key={link.link}>
            <NavLink
              className={({ isActive }) => (isActive ? ' active' : '')}
              to={link.link}
            >
              {link.icon && <Icon name={link.icon} />}
              <span className="nav-link-label">
                {intl.formatMessage({ id: link.title })}
              </span>
              <span className="nav-link-indicator"></span>
            </NavLink>
          </li>
        )
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPos, fixed, handleScroll])

  const classes = classNames({
    'tab-nav': true,
    'tab-nav-fixed': fixed,
    'tab-nav-hidden': hidden,
  })

  return (
    <nav className={classes}>
      <ul>{renderTabs(routes)}</ul>
    </nav>
  )
}

export default NavigationTabs
