import React, { useState } from 'react'
import { DEVELOPMENT } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
} from '@fnd/modules/User'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { BannerNotice } from '@fnd/components/Banner'
import Button from '@fnd/components/Button'
import Emoji from '@fnd/components/Emoji'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

function ConfirmEmail({ className }) {
  const [isClicked, setIsClicked] = useState(false)
  const intl = useIntl()

  const { profile, isAuthorized } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  if (!isAuthorized || profile.verified || isClicked || DEVELOPMENT) {
    return null
  }

  const classes = classNames({
    'py-4': true,
    [className]: className,
  })

  return (
    <BannerNotice id="confirm-email" variant="light" className={classes}>
      <div className="flex flex-col gap-2">
        <p className="m-0">
          <Emoji symbol="👋" label="Waving Hand" className="mr-2" />
          <strong>
            {intl.formatMessage({ id: 'messages.hi' })}{' '}
            {profile.spotify_username}
            {'! '}
          </strong>
          {intl.formatMessage({ id: 'confirm_email.text' })}
        </p>

        <div className="flex items-center gap-2">
          <Button
            className="flex-1"
            variant="outline"
            onClick={() => setIsClicked(true)}
          >
            {intl.formatMessage({ id: 'confirm_email.later' })}
          </Button>

          <Button
            className="flex-1"
            variant="inverse"
            to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`}
            onClick={() => setIsClicked(true)}
          >
            {intl.formatMessage({ id: 'confirm_email.cta' })}
          </Button>
        </div>
      </div>
    </BannerNotice>
  )
}

export default ConfirmEmail
