import React from 'react'
import Info from '@fnd/components/Info'
import { getUsername, getPlatformUrl } from '@fnd/core/libs/platforms'
import { FormattedMessage } from 'react-intl'

export function PlaylistFeaturedContacts({ contacts }) {
  return (
    <>
      {contacts && contacts?.email && (
        <Info
          icon="envelope"
          label="Email"
          valueClasses="flex flex-col"
          value={contacts?.email.map((email, index) => (
            <a
              key={index}
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </a>
          ))}
        />
      )}

      {contacts && contacts?.website && (
        <Info
          icon="browser"
          label={<FormattedMessage id="field.website.label" />}
          value={
            <a
              href={getPlatformUrl('website', contacts?.website)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contacts?.website}
            </a>
          }
        />
      )}

      {contacts && contacts?.facebook && (
        <Info
          icon="facebook"
          iconType="brands"
          label="Facebook"
          value={
            <a
              href={getPlatformUrl('facebook', contacts?.facebook)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contacts?.facebook}
            </a>
          }
        />
      )}

      {contacts && contacts?.instagram && (
        <Info
          icon="instagram"
          iconType="brands"
          label="Instagram"
          value={
            <a
              href={getPlatformUrl('instagram', contacts?.instagram)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getUsername(contacts?.instagram)}
            </a>
          }
        />
      )}

      {contacts && contacts?.twitter && (
        <Info
          icon="twitter"
          iconType="brands"
          label="Twitter"
          value={
            <a
              href={getPlatformUrl('twitter', contacts?.twitter)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getUsername(contacts?.twitter)}
            </a>
          }
        />
      )}
    </>
  )
}

export default PlaylistFeaturedContacts
