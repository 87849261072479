import React from 'react'

function AccelerateRocket() {
  return (
    <div className="mb-10">
      <div className="rocket">
        <svg
          id="rocket-launch"
          className="launched"
          viewBox="0 0 754 751"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <mask
              id="a"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="755"
              height="751"
            >
              <path
                d="M754 377c.084 90.413-32.359 177.84-91.401 246.312-59.043 68.473-140.747 113.422-230.189 126.638H330.3c-.16.14-5.82.41-5.82.41-.95-.13-1.91-.27-2.86-.41-7.27-1.07-14.473-2.347-21.61-3.83-27.945-5.785-55.133-14.76-81.03-26.75-.27-.12-.53-.25-.8-.37-14.431-6.698-28.417-14.317-41.87-22.81-.34-.21-.67-.42-1-.63-.57-.36-1.13-.71-1.69-1.08-.18-.11-.36-.22-.54-.34-12.027-7.747-23.603-16.174-34.67-25.24-.54-.43-1.08-.88-1.62-1.32-2.17-1.8-4.32-3.62-6.45-5.46-.02-.01-.03-.03-.05-.04A380.81006 380.81006 0 0199.32 632c-.03-.04-.07-.07-.1-.11-42.8124-46.569-73.1921-103.18-88.33-164.6-.12-.44-.22-.87-.33-1.31A378.1398 378.1398 0 01.00001 377C.00001 168.79 168.79 0 377 0c208.21 0 377 168.79 377 377z"
                fill="#3F3D56"
              />
            </mask>
            <g mask="url(#a)">
              <path
                opacity=".8"
                d="M411.853 432.616l32.971-38.598 32.971-38.599 18.612-21.789c1.03-1.206-.559-3.087-1.589-1.881l-32.971 38.598-32.97 38.599-18.613 21.789c-1.03 1.206.56 3.087 1.589 1.881z"
                fill="url(#paint0_linear)"
                className="comet one"
              />
              <path
                d="M649.38 245.25v392.4c-24.327 25.418-52.077 47.322-82.45 65.08V245.25h82.45z"
                fill="#2F2E41"
              />
              <path
                d="M688.521 295.224h-54.134v54.134h54.134v-54.134zM614.399 114.5h-12.493v141.581h12.493V114.5z"
                fill="#2F2E41"
              />
              <g opacity=".4" fill="#E6E6E6">
                <path
                  opacity=".4"
                  d="M573.93 245.25h-7v457.48c2.352-1.374 4.685-2.776 7-4.205V245.25zM608.906 114.5h-7v130.75h7V114.5z"
                />
              </g>
              <path
                id="signal"
                d="M628.14 309.798h-44.972v24.985h44.972v-24.985zM606.5 125.5c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
                fill="#6C63FF"
              />
              <path
                d="M146.933 311.039c36.557 0 66.193-29.635 66.193-66.192 0-36.557-29.636-66.193-66.193-66.193s-66.1923 29.636-66.1923 66.193 29.6353 66.192 66.1923 66.192z"
                fill="#E6E6E6"
              />
              <path
                d="M140.423 206.867c4.195 0 7.595-3.4 7.595-7.596 0-4.195-3.4-7.595-7.595-7.595-4.196 0-7.596 3.4-7.596 7.595 0 4.196 3.4 7.596 7.596 7.596zM153.444 282.826c4.195 0 7.596-3.401 7.596-7.596s-3.401-7.596-7.596-7.596-7.596 3.401-7.596 7.596 3.401 7.596 7.596 7.596zM182.742 238.336c2.997 0 5.426-2.429 5.426-5.426 0-2.996-2.429-5.425-5.426-5.425-2.996 0-5.425 2.429-5.425 5.425 0 2.997 2.429 5.426 5.425 5.426zM120.89 267.634c9.589 0 17.362-7.773 17.362-17.362 0-9.588-7.773-17.362-17.362-17.362-9.588 0-17.362 7.774-17.362 17.362 0 9.589 7.774 17.362 17.362 17.362z"
                fill="#CBCBCB"
              />
              <path
                className="stars"
                d="M283.012 401.093l-1.785 1.773-1.773-1.785-1.19 1.182 1.773 1.785-1.785 1.773 1.182 1.19 1.785-1.773 1.773 1.785 1.19-1.182-1.773-1.785 1.785-1.773-1.182-1.19zM297.318 297.375l-1.785 1.773-1.773-1.785-1.19 1.182 1.773 1.785-1.785 1.773 1.182 1.19 1.785-1.773 1.773 1.785 1.19-1.182-1.773-1.785 1.785-1.773-1.182-1.19zM118.557 366.728l-2.457-.539.538-2.458-1.638-.359-.539 2.458-2.457-.538-.359 1.638 2.457.539-.538 2.457 1.638.359.539-2.458 2.457.539.359-1.638zM494.557 71.728l-2.457-.539.538-2.458-1.638-.359-.539 2.458-2.457-.538-.359 1.638 2.457.539-.538 2.457 1.638.359.539-2.458 2.457.539.359-1.638zM686.557 230.728l-2.457-.539.538-2.458-1.638-.359-.539 2.458-2.457-.538-.359 1.638 2.457.539-.538 2.457 1.638.359.539-2.458 2.457.539.359-1.638zM222.46 110.506l-2.457-.539.538-2.457-1.638-.359-.539 2.457-2.458-.538-.359 1.638 2.458.539-.538 2.458 1.638.358.539-2.457 2.457.538.359-1.638zM428.557 242.728l-2.457-.539.538-2.458-1.638-.359-.539 2.458-2.457-.538-.359 1.638 2.457.539-.538 2.457 1.638.359.539-2.458 2.457.539.359-1.638zM523.46 198.506l-2.457-.539.538-2.457-1.638-.359-.539 2.457-2.458-.538-.359 1.638 2.458.539-.538 2.458 1.638.358.539-2.457 2.457.538.359-1.638z"
                fill="#E6E6E6"
              />
              <path
                className="dot-1"
                d="M252.015 352.789c1.975 0 3.576-1.601 3.576-3.576s-1.601-3.577-3.576-3.577-3.577 1.602-3.577 3.577c0 1.975 1.602 3.576 3.577 3.576z"
                fill="#6C63FF"
              />
              <path
                className="dot-2"
                d="M342.279 100.556c-3.862 2.573-7.815-3.5514-3.878-6.0104 3.861-2.573 7.814 3.5514 3.878 6.0104z"
                fill="#E6E6E6"
              />
              <path
                className="dot-3"
                d="M455.34 192.127c1.975 0 3.576-1.601 3.576-3.576s-1.601-3.577-3.576-3.577c-1.976 0-3.577 1.602-3.577 3.577 0 1.975 1.601 3.576 3.577 3.576z"
                fill="#6C63FF"
              />
              <path
                opacity=".8"
                d="M357.853 218.616l32.971-38.598 32.971-38.599 18.612-21.789c1.03-1.206-.559-3.087-1.589-1.881l-32.971 38.598-32.97 38.599-18.613 21.789c-1.03 1.206.56 3.087 1.589 1.881z"
                fill="url(#paint1_linear)"
                className="comet two"
              />
              <g id="rocket">
                <path
                  d="M420.91 326.41h-34.1v413.54h34.1V326.41z"
                  fill="#2F2E41"
                />
                <path
                  d="M409.95 478.23l-.07 12.38-.83 150.26-.1 17.91-.22 40.36-.22 40.81h-2.2l.22-40.81.21-37.7.11-20.94.83-149.86.07-12.42 2.2.01z"
                  fill="#6C63FF"
                />
                <path
                  d="M427.031 331.91h-46.204c-.671-7.238.998-14.528 5.294-21.876l1.306-8.927h31.903l1.505 8.814c4.47 5.956 6.202 13.476 6.196 21.989z"
                  fill="#2F2E41"
                />
                <path
                  d="M420.318 302.207h-32.922c-.332 0-.661-.075-.96-.22-.299-.145-.562-.357-.768-.618-.205-.261-.35-.565-.421-.89-.071-.325-.068-.662.01-.985l5.544-23.102c.116-.481.389-.909.778-1.215.388-.306.867-.472 1.362-.472h21.833c.494 0 .974.166 1.362.472.388.306.662.734.777 1.215l5.545 23.102c.077.323.08.66.009.985-.071.325-.215.629-.421.89s-.468.473-.768.618c-.299.145-.627.22-.96.22z"
                  fill="#2F2E41"
                />
                <path
                  d="M403.86 263.15c-3.064.004-6.001 1.223-8.167 3.39-2.165 2.168-3.382 5.106-3.383 8.17v11.55h23.1v-11.55c-.001-3.064-1.218-6.002-3.383-8.17-2.166-2.167-5.103-3.386-8.167-3.39zM391.051 612.109l-78.286 101.605 10.827 2.499 60.796-78.286h6.663v-25.818zM419.831 612.109l78.286 101.605-10.827 2.499-60.796-78.286h-6.663v-25.818z"
                  fill="#2F2E41"
                />
                <path
                  d="M391.494 636.969h-6v102.023h6V636.969zM405.54 262.6c-2.451.663-4.616 2.114-6.161 4.129-1.545 2.015-2.384 4.482-2.389 7.021v.1c-.367.11-.699.313-.964.59-.265.277-.453.618-.546.99l-5.54 23.11c-.075.312-.08.636-.015.95.065.314.198.609.39.866s.437.468.72.619c.283.151.595.238.915.255l-.04.27-.15 1-2.26 7c-4.29 7.34-4.66 14.21-3.99 21.45h5.98v280.51l-78.04 101.3 5.74 1.32-.91 1.18-10.83-2.5 78.04-101.3V330.95l-4.99-.45c-.67-7.24.71-13.66 5-21l.26-7 .15-1 .04-.27c-.32-.017-.632-.104-.915-.255-.283-.151-.528-.362-.72-.619-.192-.257-.325-.552-.39-.866-.065-.314-.06-.638.015-.95l5.54-23.11c.093-.372.281-.713.546-.99s.597-.48.964-.59v-.1c.001-3.064 1.218-6.002 3.383-8.17 2.166-2.167 5.103-3.386 8.167-3.39 1.014.003 2.023.141 3 .41zM431.177 636.969h-5.583v.537l60.38 77.749 5.088-1.174-59.885-77.112z"
                  fill="#E6E6E6"
                />
                <path
                  d="M385.49 330.95h-5.98c-.67-7.24 1-14.53 5.29-21.87l.96-6.58.15-1h6l-.15 1-.96 6.58c-4.29 7.34-5.96 14.63-5.29 21.87h5.98M391.49 417.5h-6v73h6v-73z"
                  fill="#6C63FF"
                />
                <g className="flame">
                  <path
                    d="M404 785s-15-12.073-15-25.244c0-9.542 7.662-17.127 12-19.756h6s12 6.585 12 19.756S404 785 404 785z"
                    fill="#EB5757"
                  />
                  <path
                    d="M404 783s-13-10.463-13-21.878c0-11.415 12-20.622 13-20.622 0 0 13 9.207 13 20.622S404 783 404 783z"
                    fill="#F2994A"
                  />
                  <path
                    d="M404 775.692s-10-8.048-10-16.829c0-8.78 9.231-15.863 10-15.863 0 0 10 7.083 10 15.863 0 8.781-10 16.829-10 16.829z"
                    fill="#F2C94C"
                  />
                </g>
              </g>
              <g id="smoke">
                <path
                  d="M463 695c0 13.255-13.879 24-31 24-17.121 0-41-4-35.5-20s18.379-28 35.5-28 31 10.745 31 24z"
                  fill="#F4F4F4"
                  fillOpacity=".9"
                />
                <path
                  d="M435.5 674c-9.5 0-23.5 3-20.5 5 0 0 22-3 32-.5s-5.334-4.5-11.5-4.5z"
                  fill="#EE7823"
                  fillOpacity=".27"
                />
                <path
                  d="M404.514 696.697c-1.596 3.657-.675 10.475 2.011 10.273 0 0 .424-9.898 4.832-12.556 4.408-2.658-5.807-.09-6.843 2.283z"
                  fill="#5850DA"
                  fillOpacity=".22"
                />
                <path
                  d="M490.5 686.5c1.366 9.275 23.5 23.607-7.792 28.216C470.978 716.444 461 707 455.5 709s-.73-24.772 11-26.5c13-5 22.634-5.275 24 4z"
                  fill="#F4F4F4"
                  fillOpacity=".9"
                />
                <path
                  d="M485.5 689c-1.5-5-4.805-9.729-7.5-5 0 0 5 2 5 7s3.608 1.695 2.5-2z"
                  fill="#5850DA"
                  fillOpacity=".22"
                />
                <path
                  d="M465.874 696.335c1.248 8.469-5.714 16.508-15.549 17.957-9.835 1.449-27.825-4.292-20.066-12.712 7.758-8.419 5.714-16.508 15.549-17.957 9.835-1.449 18.819 4.243 20.066 12.712z"
                  fill="#F4F4F4"
                  fillOpacity=".9"
                />
                <path
                  d="M462.479 693.586c-1.453-1.629-6.142-1.757-7.382.273 0 0 5.913 1.499 5.319 5.108-.594 3.609 3.006-4.324 2.063-5.381z"
                  fill="#934E4E"
                  fillOpacity=".22"
                />
                <path
                  d="M376.606 688.763c1.366 9.276 23.5 23.608-7.792 28.217-11.73 1.728-21.708-7.717-27.208-5.717s-.73-24.772 11-26.5c13-5 22.634-5.275 24 4z"
                  fill="#F4F4F4"
                  fillOpacity=".9"
                />
                <path
                  d="M371.606 691.263c-1.5-5-4.806-9.729-7.5-5 0 0 5 2 5 7s3.608 1.695 2.5-2z"
                  fill="#5850DA"
                  fillOpacity=".22"
                />
                <path
                  d="M400 684.853c1.247 8.469 1.835 25.551-8 27-9.835 1.448-39.759-1.081-32-9.5 7.759-8.42 3.398-13.552 13.233-15 9.835-1.449 25.52-10.97 26.767-2.5z"
                  fill="#F4F4F4"
                  fillOpacity=".9"
                />
                <path
                  d="M392.748 703.407c1.943-3.556 1.993-10.331-.494-10.232 0 0-1.687 9.77-6.074 12.233-4.387 2.463 5.308.307 6.568-2.001z"
                  fill="#934E4E"
                  fillOpacity=".22"
                />
              </g>
              <path
                d="M514.88 709.14v18.85c-26.563 10.439-54.233 17.806-82.47 21.96h-123c-3.15-.57-6.29-1.18-9.4-1.83v-38.98h214.87z"
                fill="#2F2E41"
              />
              <path
                d="M409.05 650.87c-.7-.12-1.44-.24-2.2-.37-4.47-.72-9.86-1.54-16.04-2.39-.42-.06-.85-.12-1.29-.18-5.1-.69-10.71-1.39-16.76-2.08-33.17-3.73-79.5-6.72-126.78-2.61 1.17-4.7 2.25-9.4 3.27-14.08l-45.28 7.01 48.86-24.85c4.603-25.529 7.768-51.296 9.48-77.18l-24.46 3.78 25.01-12.72c1.79-32.03.95-53.35.95-53.35S182 514.38 116.58 579.38c-2.4-8.46-5.03-16.81-7.83-25.01l-35.14 29.4 28.97-46.52a689.22605 689.22605 0 00-31.78-70.97l-18.98 15.88 14.84-23.82c-15.02-28.35-26.76-46.17-26.76-46.17s-13.39 21.94-29.34 55.81C25.6024 529.947 56.1448 587.074 99.32 634c9.76 10.62 20.117 20.677 31.02 30.12 2.13 1.84 4.28 3.66 6.45 5.46a377.3409 377.3409 0 0036.29 26.56c.74.48 1.48.95 2.23 1.42 38.179 24.232 80.426 41.361 124.7 50.56 3.11.65 6.25 1.26 9.4 1.83.77.14 1.54.28 2.31.41h12.76s5.66-.27 5.82-.41c15.85-13.87 30.22-27.89 42.61-40.81 2.82-2.93 5.53-5.81 8.14-8.61l-21.38-12.47 27.44 5.88c1.26-1.39 2.5-2.76 3.7-4.1 6.16-6.88 11.5-13.11 15.93-18.4.76-.92 1.5-1.8 2.21-2.66 8.07-9.74 12.51-15.68 12.51-15.68s-4.46-.91-12.41-2.23z"
                fill="#2F2E41"
              />
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="409.931"
              y1="382.182"
              x2="496.741"
              y2="382.182"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#fff" />
              <stop offset="1" stopColor="#fff" stopOpacity=".3" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="50612.9"
              y1="24816.9"
              x2="58148.8"
              y2="24816.9"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#fff" />
              <stop offset="1" stopColor="#fff" stopOpacity=".3" />
            </linearGradient>
            <clipPath id="clip0">
              <path fill="#fff" d="M0 0h754v750.36H0z" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  )
}

export default AccelerateRocket
