import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { spotifyEndpoints } from '@fnd/core/spotify'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Auth from '@fnd/components/Auth'
import Button from '@fnd/components/Button'
import Spinner from '@fnd/components/Spinner'
import { PlaylistCard } from '@fnd/components/Playlist'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import {
  PlaylistAnalysis,
  PlaylistAnalysisRequest,
} from '@fnd/modules/PlaylistCheck'
import { QUERIES } from '@fnd/constants'
import { useQuery } from '@tanstack/react-query'
import { usePlaylistStore } from '@fnd/store'

export default function ReportForm() {
  const intl = useIntl()

  const { resetFeedback } = usePlaylistStore()

  const [isLoading, setIsLoading] = useState(false)
  const [playlistId, setPlaylistId] = useState(null)
  const [playlistData, setPlaylistData] = useState(null)
  const [hasAnalysis, setHasAnalysis] = useState(false)
  const [watching, setWatching] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const { data: watchedPlaylists } = useQuery({
    queryKey: [QUERIES.PLAYLIST_CHECK.MONITORED],
    queryFn: () => spotimatchEndpoints.getMonitoring().toPromise(),
  })

  const checkIfWatched = () => {
    if (!watchedPlaylists || watchedPlaylists?.length === 0) return

    const watched = watchedPlaylists?.find(
      (playlist) => playlist?.PlaylistId === playlistId
    )
    setWatching(!!watched)
    return watched
  }

  const fetchAnalysis = async () => {
    setIsLoading(true)
    resetFeedback()

    try {
      const analysisData = await spotimatchEndpoints
        .getPlaylistAnalysis(playlistId)
        .toPromise()
      setHasAnalysis(!!analysisData)
    } catch (error) {
      setHasAnalysis(false)
      console.error(error)
    } finally {
      setIsLoading(false)
      setSubmitted(true)
    }
  }

  const fetchPlaylistData = async () => {
    setIsLoading(true)

    if (playlistId) {
      try {
        const playlistData = await spotifyEndpoints.getPlaylistById(playlistId)
        setPlaylistData(playlistData)
      } catch (error) {
        console.error(error)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchPlaylistData()
    checkIfWatched()
  }, [playlistId])

  const btnDisabled = isLoading || !playlistData

  const clearForm = () => {
    setPlaylistId(null)
    setPlaylistData(null)
    setHasAnalysis(false)
    setSubmitted(false)
    resetFeedback()
  }

  return (
    <Auth login emptyState>
      <div className="w-full flex flex-col gap-4">
        <SearchSelect
          id="playlist-field"
          className="flex-1"
          name="name"
          label={intl.formatMessage({ id: 'report.input.label' })}
          entity="playlist"
          onChange={(value) => setPlaylistId(value)}
          onClear={() => clearForm()}
        />

        {playlistData && (
          <PlaylistCard className="my-4" playlist={playlistData} />
        )}

        {isLoading && <Spinner />}

        {submitted && (
          <PlaylistAnalysisRequest
            hasAnalysis={hasAnalysis}
            playlistId={playlistId}
            watching={watching}
          />
        )}

        {hasAnalysis && (
          <PlaylistAnalysis hideOverview={true} playlistId={playlistId} />
        )}

        {!submitted && !hasAnalysis && (
          <Button
            icon="check"
            onClick={fetchAnalysis}
            disabled={btnDisabled}
            className="w-full"
            label={<FormattedMessage id="report.cta.check" />}
            loading={isLoading}
          />
        )}
      </div>
    </Auth>
  )
}
