import React, { useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import MagazineList from './MagazineList'
import { applyTheme, resetTheme } from '@fnd/core/libs/helpers'
import { MatchForm, MatchSwitch } from '@fnd/components/Match'

export function MagazineMatch() {
  useEffect(() => {
    applyTheme('orange', 'yellow')

    return () => {
      resetTheme()
    }
  }, [])

  return (
    <Wrapper>
      <Auth login emptyState>
        <MatchSwitch initial="magazine" className="mb-8" />

        <MatchForm
          btnLabel="Match 3000+ websites"
          btnVariant="gradient"
          className="mb-8"
          matchTypeEnabled="magazine"
        />

        <MagazineList />
      </Auth>
    </Wrapper>
  )
}

export default MagazineMatch
