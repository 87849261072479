import React from 'react'
import Badge from '@fnd/components/Badge'
import { getCampaignOption } from '../utils'
import { useIntl } from 'react-intl'

const VipBudgetSpotifyAlgo = () => {
  const intl = useIntl()

  const campaignOption = getCampaignOption('spotifyAlgo', intl)

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="inline-flex flex-col items-start md:flex-row md:items-center gap-4">
        <h3 className="text-3xl m-0">{campaignOption.label}</h3>

        <Badge
          medium
          className="capitalize"
          variant="blue-light"
          icon="chart-line"
          label={intl.formatMessage({
            id: 'vip.algo.badge',
          })}
        />
      </div>

      <p className="font-light opacity-90 text-balance mt-0 mb-4">
        {intl.formatMessage({ id: 'vip_campaign.budget.spotify_algo.text' })}
      </p>

      <p className="font-semibold text-lg opacity-90 text-balance mt-0 mb-4">
        {intl.formatMessage({ id: 'vip_campaign.budget.spotify_algo.text_2' })}
      </p>
    </div>
  )
}

export default VipBudgetSpotifyAlgo
