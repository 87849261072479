import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import { ROUTES } from '@fnd/screens/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import CuratorCard from '@fnd/components/Card/CuratorCard'
import Spinner from '@fnd/components/Spinner'
import classNames from 'classnames'

export const Curators = ({ className }) => {
  const [starCurators, setStarCurators] = useState([])
  const [featuredCurators, setFeaturedCurators] = useState([])
  const [isLoadingStar, setIsLoadingStar] = useState(true)
  const [isLoadingFeatured, setIsLoadingFeatured] = useState(true)
  const intl = useIntl()

  const fetchStarCurators = async () => {
    try {
      const starCurators = await spotimatchEndpoints
        .getStarCurators()
        .toPromise()

      setStarCurators(starCurators.slice(0, 4))
      setIsLoadingStar(false)
    } catch (err) {
      setIsLoadingStar(false)
      console.log(err)
    }
  }

  const fetchFeaturedCurators = async () => {
    try {
      const featuredCurators = await spotimatchEndpoints
        .getFeaturedCurators()
        .toPromise()

      setFeaturedCurators(featuredCurators.slice(0, 4))
      setIsLoadingFeatured(false)
    } catch (err) {
      setIsLoadingFeatured(false)
      console.log(err)
    }
  }

  useEffect(() => {
    fetchStarCurators()
    fetchFeaturedCurators()
  }, [])

  const renderCurators = ({ curators, ...props }) => {
    return curators.map((curator) => {
      if (!curator) return null

      return <CuratorCard key={curator?.id} curator={curator} {...props} />
    })
  }

  const classes = classNames({
    curators: true,
    section: true,
    'container mx-auto': true,
    [className]: className,
  })

  return (
    <div id="curators" className={classes}>
      {(isLoadingStar ||
        (!isLoadingStar && starCurators && starCurators.length > 0)) && (
        <SectionTitle
          tag="h3"
          center
          title={intl.formatMessage({ id: 'curator_star.title' })}
          subtitle={intl.formatMessage({ id: 'curator_star.subtitle' })}
          badge={
            <Badge
              variant="gold-light"
              icon="stars"
              label={intl.formatMessage({ id: 'navigation.curators' })}
            />
          }
        />
      )}

      {isLoadingStar && <Spinner />}

      {!isLoadingStar && starCurators && starCurators.length > 0 && (
        <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 md:gap-8">
          {renderCurators({
            curators: starCurators,
            star: true,
          })}
        </div>
      )}

      {(isLoadingFeatured ||
        (!isLoadingFeatured &&
          featuredCurators &&
          featuredCurators.length > 0)) && (
        <SectionTitle
          tag="h3"
          center
          title={intl.formatMessage({ id: 'curator_featured.title' })}
          subtitle={intl.formatMessage({ id: 'curator_featured.subtitle' })}
          badge={
            <Badge
              variant="primary-light"
              icon="user-music"
              label={intl.formatMessage({ id: 'navigation.curators' })}
            />
          }
        />
      )}

      {isLoadingFeatured && <Spinner />}

      {!isLoadingFeatured &&
        featuredCurators &&
        featuredCurators.length > 0 && (
          <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 md:gap-8">
            {renderCurators({
              curators: featuredCurators,
              featured: true,
            })}
          </div>
        )}

      <div className="w-full text-center mt-8">
        <Button className="button-cta" to={ROUTES.CURATORS} variant="outline">
          <FormattedMessage id="messages.learn_more" />
        </Button>
      </div>
    </div>
  )
}

export default Curators
