import React from 'react'
import Accelerate from '@fnd/components/Accelerate'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { isUserLoadingSelector } from '@fnd/modules/User'
import Spinner from '@fnd/components/Spinner'

export default function AcceleratePage() {
  const { isUserLoading } = useUserContext(({ user }) => ({
    isUserLoading: isUserLoadingSelector(user),
  }))

  if (isUserLoading) {
    return <Spinner />
  }

  return <Accelerate />
}
