import React, { useState, useEffect } from 'react'
import axios from 'axios'
import parse from 'html-react-parser'
import { IUBENDA_TERMS } from '@fnd/constants'

const Terms = () => {
  const [content, setContent] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(IUBENDA_TERMS)
        setContent(res.data.content)
      } catch (error) {
        console.error('Error fetching terms:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className="container max-w-3xl mx-auto">
      <div className="flex flex-wrap mb-4 items-center">
        <div className="terms">{content && parse(content)}</div>
      </div>
    </div>
  )
}

export default Terms
