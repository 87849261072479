import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'
import Icon from '@fnd/components/Icon'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import { ROUTES, DRAWER_NAV } from '@fnd/screens/constants'

const NavigationDrawer = (props) => {
  const { show, closeDrawer } = props

  const drawerClasses = show ? 'side-drawer open' : 'side-drawer'

  return (
    <div className={drawerClasses}>
      <a
        to={ROUTES.HOME}
        onClick={closeDrawer}
        className="side-drawer-logo block ml-5 my-5"
      >
        <LogoIcon />
      </a>

      <div className="side-drawer-menu">
        {DRAWER_NAV.map((group) => (
          <div key={group.title} className="w-full">
            <h4 className="font-light text-sm uppercase tracking-wider opacity-70 ml-5 mb-2">
              <FormattedMessage id={group.title} />
            </h4>
            <ul>
              {group.items.map(
                (item) =>
                  item.show && (
                    <li key={item.link}>
                      <NavLink
                        onClick={closeDrawer}
                        to={item.link}
                        className={({ isActive }) =>
                          isActive ? ' active' : ''
                        }
                      >
                        {item.icon && (
                          <Icon
                            name={item.icon}
                            type={
                              item?.iconType
                                ? item?.iconType
                                : DEFAULT_ICON_TYPE
                            }
                          />
                        )}
                        <FormattedMessage id={item.title} />
                      </NavLink>
                    </li>
                  )
              )}
            </ul>
          </div>
        ))}
      </div>

      <div className="side-drawer-overlay" />
    </div>
  )
}

export default NavigationDrawer
