import React, { useEffect } from 'react'
import { Swiper } from 'swiper'
import Mockup from '@fnd/components/Mockup/Mockup'
import classNames from 'classnames'
import { Pagination } from 'swiper/modules'

const Slider = ({ className }) => {
  useEffect(() => {
    new Swiper('.slider-mockup', {
      modules: [Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1,
      spaceBetween: 30,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    })
  }, [])

  const mockupSlides = [
    {
      image: '/images/analysis/mockup_track.jpg',
      caption: 'Track audio features',
    },
    {
      image: '/images/analysis/mockup_profile.jpg',
      caption: 'Personal Profile Stats',
    },
    {
      image: '/images/analysis/mockup_artist.jpg',
      caption: 'Top Artists Stats',
    },
    {
      image: '/images/analysis/mockup_search.jpg',
      caption: 'Search artists & playlists',
    },
    {
      image: '/images/analysis/mockup_analysis.jpg',
      caption: 'Analyze artists and get tips',
    },
  ]

  const classes = classNames({
    [className]: className,
  })

  return (
    <Mockup
      address="https://matchfy.io"
      showAddressBar
      enableLink
      className={classes}
    >
      <div className="slider slider-mockup">
        <div className="swiper-wrapper">
          {mockupSlides.map((slide, index) => (
            <div key={index} className="swiper-slide">
              <div className="overlay overlay-bottom opacity-80"></div>
              <img src={slide.image} alt={slide.caption} />
              <span className="caption">{slide.caption}</span>
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </Mockup>
  )
}

export default Slider
