import React from 'react'
import Modal from 'react-modal'
import Button from '@fnd/components/Button'
import { useNavigate } from 'react-router-dom'
import { ModalHeader } from '@fnd/components/Modal'
import { FormattedMessage } from 'react-intl'
import { withUserAuthentication } from '@fnd/modules/User'
import { LOGOUT_REDIRECT } from '@fnd/screens/constants'

export function EmailVerifyPrompt({
  userName,
  isOpen,
  onClose,
  onSubmit,
  result,
  unauthorize,
}) {
  if (!isOpen) {
    return null
  }

  const navigate = useNavigate()

  const getContent = () => {
    if (result === 'success')
      return (
        <p>
          <FormattedMessage id="email_verify.success" />
        </p>
      )
    if (result === 'error')
      return (
        <p>
          <FormattedMessage id="email_verify.error" />
        </p>
      )
    return (
      <p>
        <FormattedMessage
          id="email_verify.text"
          values={{
            entity: userName,
          }}
        />
      </p>
    )
  }

  const onButtonClick = () => {
    if (result === 'success') {
      onClose()
      unauthorize()
      navigate(LOGOUT_REDIRECT)
    } else {
      onSubmit()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="modal show"
      className="modal-dialog modal-center modal-center modal-xs modal-confirm"
    >
      <div className="modal-content">
        <ModalHeader
          title={<FormattedMessage id="email_verify.title" />}
          onClose={onClose}
        />
        <div className="modal-body p-5">{getContent()}</div>
        <div className="modal-footer">
          <Button onClick={onButtonClick}>
            <FormattedMessage id="messages.continue" />
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withUserAuthentication(EmailVerifyPrompt)
