import React from 'react'
import classNames from 'classnames'
import {
  AppleMusicLogo,
  DeezerLogo,
  NapsterLogo,
  ShazamLogo,
  SoundcloudLogo,
  TidalLogo,
  SpotifyLogo,
  YouTubeLogo,
} from '@fnd/components/Logo'

const DSPsLogosMockup = ({ className }) => {
  const dsps = [
    {
      key: 'apple',
      component: AppleMusicLogo,
    },
    {
      key: 'deezer',
      component: DeezerLogo,
    },
    {
      key: 'napster',
      component: NapsterLogo,
    },
    {
      key: 'shazam',
      component: ShazamLogo,
    },
    {
      key: 'soundcloud',
      component: SoundcloudLogo,
    },
    {
      key: 'spotify',
      component: SpotifyLogo,
    },
    {
      key: 'tidal',
      component: TidalLogo,
    },
    {
      key: 'youtube',
      component: YouTubeLogo,
    },
  ]

  const classes = classNames({
    'dsps-logos-mockup': true,
    'flex flex-row flex-wrap items-start gap-2 w-full': true,
    [className]: className,
  })

  const cardClasses = classNames({
    'flex flex-col items-start gap-2': true,
    'bg-primary p-4 rounded-lg border-outline': true,
    'transform transition-all duration-300': true,
    'scale-100 hover:scale-95': true,
    'shadow-md hover:shadow-lg': true,
  })

  return (
    <div className={classes}>
      {dsps.map((dsp) => (
        <div key={dsp.key} className={cardClasses}>
          <dsp.component className="h-6 w-auto" />
        </div>
      ))}
    </div>
  )
}

export default DSPsLogosMockup
