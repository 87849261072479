import React from 'react'
import { PlaylistMatchCard } from './PlaylistMatchCard'

export const PlaylistGrid = ({ data, onClick }) => {
  if (!data || !data.length) return null

  return (
    <div className="playlist-cards">
      {data.map((playlistItem) => (
        <PlaylistMatchCard
          key={playlistItem.playlist.id}
          className="playlist-card-item"
          item={playlistItem}
          onClick={onClick}
        />
      ))}
    </div>
  )
}

export default PlaylistGrid
