import React from 'react'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import Select from 'react-select'
import { TABLE_PER_PAGE_OPTIONS } from '@fnd/constants'
import { useIntl } from 'react-intl'

export function TablePagination({ pagination, table }) {
  const intl = useIntl()
  const selectCol = table.getColumn('select')?.columnDef?.show ?? true
  const selectedRows = table.getFilteredSelectedRowModel().rows.length

  if (pagination && pagination?.disabled) {
    return null
  }

  return (
    <div className="flex flex-col items-start md:flex-row md:items-center justify-between gap-2">
      {selectCol && (
        <div className="flex-1 text-sm text-muted-foreground">
          <strong>
            {selectedRows} {intl.formatMessage({ id: 'messages.of' })}{' '}
            {table.getFilteredRowModel().rows.length}{' '}
          </strong>
          {selectedRows > 1 || selectedRows === 0
            ? intl.formatMessage({ id: 'messages.rows_selected' })
            : intl.formatMessage({ id: 'messages.row_selected' })}
        </div>
      )}

      <div className="flex flex-col md:flex-row items-start md:items-center md:ml-auto gap-2">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">
            {intl.formatMessage({ id: 'messages.rows_per_page' })}
          </p>

          <Select
            className="select w-[100px]"
            classNamePrefix="select"
            onChange={(option) => {
              table.setPageSize(Number(option.value))
            }}
            options={TABLE_PER_PAGE_OPTIONS.map((pageSize) => ({
              value: `${pageSize}`,
              label: `${pageSize}`,
            }))}
            value={{
              value: table.getState().pagination.pageSize,
              label: table.getState().pagination.pageSize,
            }}
          />
        </div>

        <div className="flex w-[120px] items-center justify-start md:justify-center text-sm font-medium">
          {intl.formatMessage({ id: 'messages.page' })}{' '}
          {table.getState().pagination.pageIndex + 1}{' '}
          {intl.formatMessage({ id: 'messages.of' })} {table.getPageCount()}
        </div>

        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            className="hidden h-10 w-10 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">
              {intl.formatMessage({ id: 'messages.go_to_first_page' })}
            </span>
            <Icon name="chevron-double-left" className="h-4 w-4" />
          </Button>

          <Button
            variant="ghost"
            className="h-10 w-10 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">
              {intl.formatMessage({ id: 'messages.go_to_previous_page' })}
            </span>
            <Icon name="chevron-left" className="h-4 w-4" />
          </Button>

          <Button
            variant="ghost"
            className="h-10 w-10 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">
              {intl.formatMessage({ id: 'messages.go_to_next_page' })}
            </span>
            <Icon name="chevron-right" className="h-4 w-4" />
          </Button>

          <Button
            variant="ghost"
            className="hidden h-10 w-10 p-0 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">
              {intl.formatMessage({ id: 'messages.go_to_last_page' })}
            </span>
            <Icon name="chevron-double-right" className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}
