import React from 'react'
import classNames from 'classnames'

const Hero = ({
  title,
  subtitle,
  image,
  overlay,
  overlayOpacity,
  contentClasses,
  className,
}) => {
  const classes = classNames({
    hero: true,
    [className]: className,
  })

  const contentCn = classNames({
    'hero-content': true,
    [contentClasses]: contentClasses,
  })

  return (
    <div className={classes}>
      {!!overlay && (
        <div className={`hero-${overlay} opacity-${overlayOpacity}`} />
      )}
      <div className="hero-bg" style={{ backgroundImage: `url(${image})` }} />
      <div className={contentCn}>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </div>
  )
}

export default Hero
