import React from 'react'
import { ConversationNavItem } from '@fnd/components/Chat'
import { SignButton } from '@fnd/components/Sign'
import { CreditsModal } from '@fnd/components/Credits'
import {
  isUserAuthorizedSelector,
  useUserContext,
  withUserAuthentication,
} from '@fnd/modules/User'
import { NotificationDropdown } from '@fnd/components/Notification'
import NavigationProfile from './NavigationProfile'

function NavigationAlternate() {
  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  if (!isAuthorized) {
    return <SignButton className="text-sm whitespace-nowrap" />
  }

  return (
    <div className="flex items-center">
      <NavigationProfile />

      <CreditsModal className="ml-2" />

      {isAuthorized && (
        <>
          <ConversationNavItem />
          <NotificationDropdown />
        </>
      )}
    </div>
  )
}

export default withUserAuthentication(NavigationAlternate)
