import React from 'react'
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import Button from '@fnd/components/Button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@fnd/components/Dropdown/DropdownMenu'
import { useIntl } from 'react-intl'

export function TableViewOptions({ table }) {
  const intl = useIntl()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="light"
          icon="sliders-v"
          label={intl.formatMessage({ id: 'messages.view' })}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[150px]">
        <DropdownMenuLabel>
          {intl.formatMessage({ id: 'messages.toggle_columns' })}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {table
          .getAllColumns()
          .filter(
            (column) =>
              typeof column.accessorFn !== 'undefined' && column.getCanHide()
          )
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {intl.formatMessage({ id: `field.${column.id}.label` })}
              </DropdownMenuCheckboxItem>
            )
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
