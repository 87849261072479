import React from 'react'
import Button from '@fnd/components/Button'

const NavigationToggle = ({ click }) => (
  <Button
    icon="bars"
    variant="ghost"
    className="toggle-button"
    onClick={click}
  />
)

export default NavigationToggle
