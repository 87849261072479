import React, { useState } from 'react'
import Icon from '@fnd/components/Icon'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import Field from '@fnd/components/Field'
import { CAMPAIGN_BUDGET_OPTIONS } from '../config'
import { Controller } from 'react-hook-form'
import { formatCurrency } from '@fnd/core/libs/currency'
import { useIntl } from 'react-intl'

const VipFormPodcast = ({ control, errors, setValue }) => {
  const [showId, setShowId] = useState(null)
  const budgetConfig = CAMPAIGN_BUDGET_OPTIONS.find(
    (item) => item.id === 'podcast'
  )
  const intl = useIntl()

  const handleShowChange = (data) => {
    setShowId(data?.id)
    setValue('info.podcast.show', {
      id: data?.id,
      name: data?.name,
      image: data?.images[0]?.url,
      link: data?.external_urls?.spotify,
    })
  }

  return (
    <div className="flex flex-col gap-3">
      <h4 className="text-xl m-0">
        <Icon name="microphone" className="mr-2" />
        Podcast
      </h4>

      <div className="grid md:grid-cols-2 gap-4">
        <Controller
          name="info.podcast.showId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SearchSelect
              defaultValue={showId}
              {...field}
              entity="show"
              onDataChange={handleShowChange}
              errors={errors?.info?.podcast?.show}
            />
          )}
        />

        <Controller
          name="info.podcast.name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              iconBefore={<Icon name="user-circle" />}
              label={intl.formatMessage({
                id: 'field.name.label',
              })}
              errors={errors?.info?.podcast?.name}
              {...field}
            />
          )}
        />

        <Controller
          name="info.podcast.phone"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="phone"
              label={intl.formatMessage({
                id: 'field.phone.label',
              })}
              errors={errors?.info?.podcast?.name}
              {...field}
            />
          )}
        />

        <Controller
          name="info.podcast.email"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="email"
              iconBefore={<Icon name="envelope" />}
              label="Email"
              errors={errors?.info?.podcast?.name}
              {...field}
            />
          )}
        />

        <Controller
          name="budget.podcast"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="slider"
              label="Budget"
              className="md:col-span-2"
              min={budgetConfig?.min}
              max={budgetConfig?.max}
              step={budgetConfig?.step}
              showLabels={true}
              defaultValue={[parseFloat(budgetConfig?.max / 2)]}
              formatLabelFn={formatCurrency}
              {...field}
            />
          )}
        />
      </div>
    </div>
  )
}

export default VipFormPodcast
