import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames'

export const TextFadeUp = ({ value, className }) => {
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const classes = classNames({
    [className]: className,
  })

  return (
    <AnimatePresence mode="popLayout">
      <motion.div
        key={currentValue}
        className={classes}
        initial={{ y: -30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 30, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {currentValue}
      </motion.div>
    </AnimatePresence>
  )
}

export default TextFadeUp
