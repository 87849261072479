import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import { Accordion, AccordionItem } from '@fnd/components/Accordion'
import Badge from '@fnd/components/Badge'
import parse from 'html-react-parser'

const FAQ_DATA = [
  {
    question: 'distribution.faq.how_it_works.question',
    answer: 'distribution.faq.how_it_works.answer',
  },
  {
    question: 'distribution.faq.self_management.question',
    answer: 'distribution.faq.self_management.answer',
  },
  {
    question: 'distribution.faq.release_time.question',
    answer: 'distribution.faq.release_time.answer',
  },
  {
    question: 'distribution.faq.royalties_percentage.question',
    answer: 'distribution.faq.royalties_percentage.answer',
  },
  {
    question: 'distribution.faq.covers_and_remixes.question',
    answer: 'distribution.faq.covers_and_remixes.answer',
  },
  {
    question: 'distribution.faq.artist_vs_agency.question',
    answer: 'distribution.faq.artist_vs_agency.answer',
  },
  {
    question: 'distribution.faq.change_plan.question',
    answer: 'distribution.faq.change_plan.answer',
  },
  {
    question: 'distribution.faq.accelerate_program.question',
    answer: 'distribution.faq.accelerate_program.answer',
  },
  {
    question: 'distribution.faq.distribution_cost.question',
    answer: 'distribution.faq.distribution_cost.answer',
  },
  {
    question: 'distribution.faq.monitor_earnings.question',
    answer: 'distribution.faq.monitor_earnings.answer',
  },
  {
    question: 'distribution.faq.release_types.question',
    answer: 'distribution.faq.release_types.answer',
  },
  {
    question: 'distribution.faq.release_date.question',
    answer: 'distribution.faq.release_date.answer',
  },
  {
    question: 'distribution.faq.music_removal.question',
    answer: 'distribution.faq.music_removal.answer',
  },
  {
    question: 'distribution.faq.withdraw_music.question',
    answer: 'distribution.faq.withdraw_music.answer',
  },
  {
    question: 'distribution.faq.customer_support.question',
    answer: 'distribution.faq.customer_support.answer',
  },
]

export const Faqs = () => {
  const intl = useIntl()

  return (
    <div className="max-w-screen-md mx-auto">
      <Accordion>
        {FAQ_DATA.map((faq, index) => {
          return (
            <AccordionItem
              key={index}
              label={intl.formatMessage({ id: faq.question })}
            >
              <p>{parse(intl.formatMessage({ id: faq.answer }))}</p>
            </AccordionItem>
          )
        })}
      </Accordion>
    </div>
  )
}

export const DistributionFaqs = ({
  className,
  showTitle = false,
  titleTag = 'h3',
}) => {
  const classes = classNames({
    faq: true,
    section: true,
    container: true,
    [className]: className,
  })

  const intl = useIntl()

  return (
    <div id="faq" className={classes}>
      {showTitle && (
        <SectionTitle
          tag={titleTag}
          title={intl.formatMessage({ id: 'faq.title' })}
          center
          badge={
            <Badge
              variant="primary-light"
              icon="question-circle"
              label={intl.formatMessage({ id: 'navigation.faq' })}
            />
          }
        />
      )}

      <Faqs />
    </div>
  )
}

export default DistributionFaqs
