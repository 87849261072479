import React from 'react'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Emoji from '@fnd/components/Emoji'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'

const Intro = () => {
  const intl = useIntl()

  return (
    <div id="intro" className="section container">
      <SectionTitle
        tag="h3"
        center
        title={intl.formatMessage({ id: 'home.intro.title' })}
        subtitle={intl.formatMessage({ id: 'home.intro.subtitle' })}
        badge={<Badge variant="blue-light" label="AI" icon="sparkles" />}
      />
    </div>
  )
}

export default Intro
