import React, { useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import Button from '@fnd/components/Button'
import Badge from '@fnd/components/Badge'
import SectionTitle from '@fnd/components/SectionTitle'
import Spinner from '@fnd/components/Spinner'
import Video from '@fnd/components/Video'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { spotifyEndpoints } from '@fnd/core/spotify'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { ROUTES } from '@fnd/screens/constants'
import { usePlayStore } from '@fnd/store'
import { useInfiniteQuery } from '@tanstack/react-query'
import parse from 'html-react-parser'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import TrackCard from './TrackCard'
import TrackPlaysInstructions from './TrackPlaysInstructions'
import { unionBy } from 'lodash-es'
import { QUERIES } from '@fnd/constants'

export function TrackPlays() {
  const { tracks, setTracks, setTrack, setTrackId } = usePlayStore()

  const intl = useIntl()

  const { isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [QUERIES.TRACK_PLAYS.LIST],
    queryFn: async (params) => {
      const res = await spotimatchEndpoints.getTracks(params)
      const trackIds = res?.tracks

      if (trackIds.length === 0) return

      const tracksData = await Promise.all(
        trackIds.map(async (trackId) => {
          const trackData = await spotifyEndpoints.getTrack(trackId).toPromise()
          return trackData
        })
      )

      const mergedTracks = unionBy(tracks, tracksData, 'id')

      setTracks(mergedTracks)
      return res
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next_page) return
      return lastPage.next_page
    },
  })

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  const handleTrackClick = (track) => {
    setTrack(track)
    setTrackId(track.id)
  }

  return (
    <Wrapper>
      <div className="mb-12">
        <SectionTitle
          title={intl.formatMessage({ id: 'vip_plays.page.title' })}
          subtitle={intl.formatMessage({ id: 'vip_plays.page.subtitle' })}
          badge={
            <Badge variant="gold-light" label="Plays" icon="play-circle" />
          }
          center
        />

        <div className="w-full md:w-8/12 mt-0 mb-10 mx-auto">
          <h4 className="mt-8">
            {parse(
              intl.formatMessage({ id: 'vip_plays.how_does_it_work.title' })
            )}
          </h4>

          <Video className="mb-8" src="/video/matchfy_vip_plays_tutorial.mp4" />

          <TrackPlaysInstructions />

          <p className="text-lg mt-8">
            {parse(
              intl.formatMessage({ id: 'vip_plays.how_does_it_work.text' })
            )}
          </p>

          {tracks && tracks.length > 0 && (
            <div className="max-w-lg text-center mt-8 mx-auto">
              <Button
                variant="gradient"
                className="w-full"
                to={ROUTES.TRACK_DETAIL.replace(':trackId', tracks[0].id)}
                label={intl.formatMessage({ id: 'vip_plays.cta.start' })}
              />
            </div>
          )}
        </div>

        <Auth login emptyState>
          {tracks && tracks.length > 0 && (
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4">
              {tracks.map((track) => (
                <TrackCard
                  key={track.id}
                  track={track}
                  vertical
                  onClick={() => handleTrackClick(track)}
                  link={ROUTES.TRACK_DETAIL.replace(':trackId', track.id)}
                />
              ))}
            </div>
          )}

          <div className="max-w-lg text-center mt-8 mx-auto">
            <Button
              to={ROUTES.VIP}
              className="w-full"
              variant="gold"
              label={intl.formatMessage({ id: 'vip_plays.cta.vip' })}
            />
          </div>

          {isLoading && <Spinner />}

          <div ref={ref} />
        </Auth>
      </div>
    </Wrapper>
  )
}

export default TrackPlays
