export const imageFields = `
  url,
  metadata {
    lqip,
    dimensions {
      aspectRatio,
      height,
      width
    }
  },
`

export const mentionField = `
  _id,
  title,
  "slug": slug.current,
  "image": image.asset->{
    ${imageFields}
  },
  channel,
  invertLogo,
  url,
`

export const partnerField = `
  _id,
  title,
  "slug": slug.current,
  "image": image.asset->{
    ${imageFields}
  },
  featured,
  label,
  url,
`

export const releaseFields = `
  _id,
  title,
  artist,
  label,
  content,
  show,
  url,
  "coverImage": coverImage.asset->{
    ${imageFields}
  },
  "slug": slug.current,
`

export const reviewFields = `
  _id,
  title,
  reviewer,
  show,
  url,
  date,
  text,
  rating
`

export const teamField = `
  _id,
  title,
  role,
  "image": image.asset->{
    ${imageFields}
  },
  show,
`

export const mentionQuery = `
  *[_type == "mention" && show && (channel == 'matchfy' || channel == "all")] | order(title asc) {
    ${mentionField}
  }
`

export const partnerQuery = `
  *[_type == "partner"] | order(title asc) {
    ${partnerField}
  }
`

export const releaseQuery = `
  *[_type == "release"] | order(title asc) {
    ${releaseFields}
  }
`

export const reviewQuery = `
  *[_type == "review" && enable] | order(date desc) {
    ${reviewFields}
  }
`

export const teamQuery = `
  *[_type == "team" && show] | order(date desc) {
    ${teamField}
  }
`
