import React, { useEffect, useState, forwardRef } from 'react'
import ReactPlayer from 'react-player'
import classNames from 'classnames'

export const Video = forwardRef(
  ({ src, autoPlay, className, ...props }, ref) => {
    const [isPlaying, setIsPlaying] = useState(false)

    useEffect(() => {
      setIsPlaying(autoPlay)
    }, [autoPlay])

    const classes = classNames({
      video: true,
      [className]: className,
    })

    return (
      <div className={classes}>
        <ReactPlayer
          ref={ref}
          url={src}
          playing={isPlaying}
          width="100%"
          height="100%"
          controls
          {...props}
        />
      </div>
    )
  }
)

export default Video
