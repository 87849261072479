import React from 'react'
import classNames from 'classnames'

const TidalLogoIcon = ({ className }) => (
  <svg
    className={classNames({ [className]: className })}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.004 125.003L100.002 99.9993L75.0055 74.9976L99.9992 49.9985L125.002 75.0013L150 49.998L125.002 25L100.001 49.9954L75.004 25.0032L50.0007 49.9958L75.0024 74.9976L50.0007 99.9993L75.004 125.003ZM24.998 75.0013L50.0013 49.998L24.998 25L0 49.998L24.998 75.0013Z"
      fill="currentColor"
    />
  </svg>
)

export default TidalLogoIcon
