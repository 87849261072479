import React from 'react'
import { PlanCard } from '@fnd/components/Plans'
import { FormattedMessage, useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import { isUserAuthorizedSelector, useUserContext } from '@fnd/modules/User'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import {
  AnalysisFeatures,
  AnalysisProcess,
  AnalysisSlider,
} from '@fnd/components/Analysis'
import { useQuery } from '@tanstack/react-query'

const Analysis = () => {
  const intl = useIntl()

  const { data } = useQuery({
    queryKey: ['products'],
    queryFn: () => spotimatchEndpoints.getProducts(intl.locale),
  })

  const analysisPlan = data?.find((plan) => plan.slug === 'analysis')

  const { isUserAuthorized } = useUserContext(
    ({ user, updateUserProfile }) => ({
      isUserAuthorized: isUserAuthorizedSelector(user),
      updateUser: (next) => updateUserProfile(next),
    })
  )

  return (
    <Wrapper id="analysis">
      <SectionTitle
        title={intl.formatMessage({ id: 'analysis.title' })}
        subtitle={intl.formatMessage({ id: 'analysis.subtitle' })}
        center
        badge={
          <Badge
            icon="file-chart-line"
            variant="primary-light"
            label={intl.formatMessage({ id: 'navigation.analysis' })}
          />
        }
      />
      <div className="flex flex-wrap justify-around pb-8">
        {analysisPlan && (
          <PlanCard
            title={analysisPlan.name}
            icon={analysisPlan.icon}
            color={analysisPlan.colorClass}
            caption={analysisPlan.caption}
            features={analysisPlan.features}
            captionIcon={analysisPlan.captionIcon}
            price={analysisPlan.price}
            priceMonthly={analysisPlan.price}
            displayPrice
            layout="analysis"
            slug={analysisPlan.slug}
            isActive={false}
            disabled={!isUserAuthorized}
          />
        )}
      </div>
      <div className="w-full md:w-3/4 mx-auto">
        <AnalysisProcess className="mb-32" />
        <AnalysisFeatures className="mb-16" />
        <AnalysisSlider className="mb-32" />
      </div>
    </Wrapper>
  )
}

export default Analysis
