import React, { useEffect, useState } from 'react'
import Modal from '@fnd/components/Modal/Modal'
import { ModalHeader } from '@fnd/components/Modal'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useQuery } from '@tanstack/react-query'
import { usePaginationStore } from '@fnd/store'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { QUERIES, PLANS, PLAYLIST_FEATURED_PREVIEW_LIMIT } from '@fnd/constants'
import { DataTable } from '@fnd/components/Table'
import { getPlaylistFeaturedColumns } from './PlaylistFeaturedTableColumns'
import filters from './PlaylistFeaturedTableFilters'
import PlaylistFeaturedDetail from './PlaylistFeaturedDetail'
import { useIntl } from 'react-intl'

export function PlaylistFeaturedList() {
  const {
    total,
    pagination,
    setPagination,
    setTotal,
    enablePagination,
    disablePagination,
  } = usePaginationStore()

  const [currentItem, setCurrentItem] = useState(null)
  const [limit, setLimit] = useState(false)
  const [isAgency, setIsAgency] = useState(false)
  const intl = useIntl()

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const {
    data: featuredPlaylists,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      QUERIES.PLAYLIST_FEATURED.LIST,
      pagination.pageIndex,
      pagination.pageSize,
    ],
    queryFn: async () => {
      const res = await spotimatchEndpoints.getPlaylistFeatured({
        pageParam: pagination.pageIndex,
        limit: pagination.pageSize,
      })

      setTotal(res.total)
      return res
    },
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60 * 24,
    keepPreviousData: true,
    enabled: !limit,
  })

  const handleModalClose = () => {
    setCurrentItem(null)
  }

  useEffect(() => {
    if (profile?.plan?.name === PLANS.AGENCY) {
      setIsAgency(true)
    }
  }, [profile])

  useEffect(() => {
    if (
      !isAgency &&
      featuredPlaylists?.data?.length >= PLAYLIST_FEATURED_PREVIEW_LIMIT
    ) {
      setLimit(true)
      disablePagination()
    } else {
      setLimit(false)
      enablePagination()
    }
  }, [featuredPlaylists, isAgency])

  return (
    <div className="mb-12">
      <DataTable
        rowIdKey="_id"
        columns={getPlaylistFeaturedColumns(intl, { setCurrentItem })}
        data={featuredPlaylists?.data || []}
        filters={filters}
        isLoading={isLoading || isFetching}
        pagination={pagination}
        setPagination={setPagination}
        total={total}
      />

      {!isAgency && (
        <AccountUpgrade
          btnVariant="gradient"
          message={intl.formatMessage(
            { id: 'profile.upgrade.magazine' },
            {
              plan: 'agency',
            }
          )}
          plan={PLANS.AGENCY}
        />
      )}

      {currentItem ? (
        <Modal key={currentItem.id} isOpen={true} onClose={handleModalClose}>
          <ModalHeader
            icon="music"
            title={currentItem.playlist_name}
            onClose={handleModalClose}
          />
          <div className="p-4">
            <PlaylistFeaturedDetail
              contacts={currentItem.contacts}
              description={currentItem.playlist_description}
              name={currentItem.playlist_name}
              spotifyUrl={currentItem.spotify_url}
              tags={currentItem.genres}
              website={currentItem.contacts?.website}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

export default PlaylistFeaturedList
