import React, { useState, useEffect } from 'react'
import axios from 'axios'
import parse from 'html-react-parser'
import { IUBENDA_PRIVACY } from '@fnd/constants'

const Privacy = () => {
  const [content, setContent] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(IUBENDA_PRIVACY)
        setContent(res.data.content)
      } catch (error) {
        console.error('Error fetching privacy content:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className="container max-w-3xl mx-auto">
      <div className="flex flex-wrap mb-4 items-center">
        <div className="terms">{content && parse(content)}</div>
      </div>
    </div>
  )
}

export default Privacy
