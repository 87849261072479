import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import parse from 'html-react-parser'

const Features = ({ className }) => {
  const classes = classNames({
    'flex flex-col md:flex-row': true,
    'gap-12': true,
    [className]: className,
  })

  const intl = useIntl()

  return (
    <div className={classes}>
      <div className="w-full md:w-1/2">
        <h3 className="text-primary">
          {parse(intl.formatMessage({ id: 'analysis.features.col1.title' }))}
        </h3>
        <p>{parse(intl.formatMessage({ id: 'analysis.features.col1.p1' }))}</p>
        <p>{parse(intl.formatMessage({ id: 'analysis.features.col1.p2' }))}</p>
      </div>
      <div className="w-full md:w-1/2">
        <h3 className="text-secondary">
          {parse(intl.formatMessage({ id: 'analysis.features.col2.title' }))}
        </h3>
        <p>{parse(intl.formatMessage({ id: 'analysis.features.col2.p1' }))}</p>
        <ul className="list">
          <li>
            {parse(intl.formatMessage({ id: 'analysis.features.col2.li1' }))}
          </li>
          <li>
            {parse(intl.formatMessage({ id: 'analysis.features.col2.li2' }))}
          </li>
        </ul>
        <p>{parse(intl.formatMessage({ id: 'analysis.features.col2.p2' }))}</p>
      </div>
    </div>
  )
}

export default Features
