import React from 'react'
import { Controller } from 'react-hook-form'
import { formatCurrency } from '@fnd/core/libs/currency'
import { useIntl } from 'react-intl'
import { CAMPAIGN_BOOST_PRICE } from '../config'

const VipBoostAddon = ({ form, control }) => {
  const intl = useIntl()
  const { locale } = intl

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="inline-flex flex-col items-start md:flex-row md:items-center gap-4">
        <h3 className="text-2xl m-0">
          {intl.formatMessage({ id: 'vip_campaign.boost.title' })}
        </h3>
      </div>

      <p className="font-light opacity-80 mt-0 mb-4">
        {intl.formatMessage({ id: 'vip_campaign.boost.text' })}
      </p>

      <Controller
        key="boost"
        name="budget.boost"
        control={control}
        render={({ field }) => (
          <label>
            <input
              type="checkbox"
              defaultChecked={form?.budget?.boost}
              {...field}
            />
            {intl.formatMessage({
              id: 'vip_campaign.field.boost.label',
            })}
            <span className="ml-2 text-base font-medium">
              + {formatCurrency(CAMPAIGN_BOOST_PRICE, locale)}
            </span>
          </label>
        )}
      />
    </div>
  )
}

export default VipBoostAddon
