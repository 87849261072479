import React from 'react'
import classNames from 'classnames'

const TidalLogo = ({ className }) => (
  <svg
    className={classNames({ [className]: className })}
    viewBox="0 0 605 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.0016 80L79.9994 59.9979L60.0028 39.997L79.9973 19.9983L99.999 40L119.997 19.9979L99.999 0L79.999 19.9958L60.0016 0.00255778L39.9995 19.9961L60.0004 39.997L39.9995 59.9979L60.0016 80ZM19.9979 40L40 19.9979L19.9979 0L0 19.9979L19.9979 40ZM520.241 78.3688H503.085L498.095 64.8875H469.284L464.248 78.3688H447.557L475.979 10.0404H492.09L520.241 78.3688ZM483.759 26.0252L473.467 52.7467H493.955L483.759 26.0252ZM204.411 78.3688H219.926L219.926 23.3397H239.991V10.0403H184.345V23.3397H204.411V78.3688ZM286.415 78.368H302.023V10.0396H286.415V78.368ZM604.423 64.8849H577.431V10.042H561.827V78.3703H604.423V64.8849ZM353.982 10.027H379.266C397.919 10.027 416.48 19.9055 416.48 44.0054C416.48 66.7225 398.287 78.356 380.188 78.356H353.982V10.027ZM369.125 64.9634H378.805C391.826 64.9634 400.503 56.8398 400.503 43.9124C400.503 31.6319 391.733 23.3267 379.173 23.3267H369.125V64.9634Z"
      fill="currentColor"
    />
  </svg>
)

export default TidalLogo
