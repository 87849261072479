import React, { useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import { MatchForm, MatchSwitch } from '@fnd/components/Match'
import SpotifyList from './Spotify/SpotifyList'
import { applyTheme, resetTheme } from '@fnd/core/libs/helpers'
import Wrapper from '@fnd/components/Wrapper/Wrapper'

function Analysis() {
  useEffect(() => {
    applyTheme('green', 'dark-green')

    return () => {
      resetTheme()
    }
  }, [])

  return (
    <Wrapper>
      <Auth login emptyState>
        <MatchSwitch initial="spotify" className="mb-8" />

        <MatchForm
          btnLabel="Match 500+ playlists"
          btnVariant="gradient"
          className="mb-8"
          matchTypeEnabled="spotify"
        />

        <SpotifyList />
      </Auth>
    </Wrapper>
  )
}

export default Analysis
