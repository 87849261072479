import React from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'
import { DEFAULT_LANGUAGE } from '@fnd/screens/constants'
import { useIntl } from 'react-intl'
import { useBannerStore } from '@fnd/store'

export function BannerNotice({
  id,
  variant = 'primary',
  banner,
  children,
  className,
}) {
  const { dismissed, dismissBanner } = useBannerStore()
  const intl = useIntl()
  const { locale } = intl

  const handleDismiss = () => {
    if (id) dismissBanner(id)
  }

  const classes = classNames({
    'banner-notice': true,
    'text-center': true,
    'pr-8': banner?.dismissable,
    dismissed: dismissed.includes(id),
    [`banner-${variant}`]: variant,
    [className]: className,
  })

  if (!banner && !children) return null

  const getBannerText = () => {
    if (banner?.text?.[locale]) return banner.text[locale]
    return banner.text[DEFAULT_LANGUAGE]
  }

  const bannerCtaLabel = () => {
    if (banner?.cta?.label?.[locale]) return banner.cta.label[locale]
    return banner.cta.label[DEFAULT_LANGUAGE]
  }

  return (
    <div className={classes}>
      {banner?.text && (
        <span className="inline-flex items-center gap-2">
          {banner?.icon && <Icon name={banner.icon} />}
          <p className="mr-2">{getBannerText()}</p>
        </span>
      )}

      {banner?.cta && (
        <a
          href={banner?.cta?.url}
          className="font-semibold underline"
          target={banner?.cta?.external ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {bannerCtaLabel()}
        </a>
      )}

      {!banner && children ? children : null}

      {banner?.dismissable && (
        <button
          onClick={handleDismiss}
          className="absolute right-2 top-[50%] -translate-y-1/2"
        >
          <Icon name="times" />
        </button>
      )}
    </div>
  )
}

export default BannerNotice
