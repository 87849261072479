import React, { useState, useEffect, useRef } from 'react'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import { ROUTES } from '@fnd/screens/constants'
import classNames from 'classnames'
import useDimensions from '@fnd/core/hooks/useDimensions'
import { useIntl } from 'react-intl'
import {
  DEVELOPMENT,
  PROFILE_COMPLETE_BONUS,
  PLANS,
  PLAN_PRICES,
} from '@fnd/constants'

export function Banner({ className }) {
  const intl = useIntl()

  const [showBanner, setShowBanner] = useState(false)
  const [dismissed, setDismissed] = useState(false)
  const [hasBilling, setHasBilling] = useState(true)
  const [isFree, setIsFree] = useState(false)

  const [ref, { height }] = useDimensions({})

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  useEffect(() => {
    const hasDismissed = window.localStorage.getItem('banner-dismissed')

    if (hasDismissed) {
      setDismissed(true)
      return
    }
  }, [])

  useEffect(() => {
    if (profile && profile.plan && profile.plan.name === PLANS.FREE) {
      setIsFree(true)
    }

    if (profile && profile?.id && !profile?.billingInfo) {
      setHasBilling(false)
    }
  }, [profile])

  useEffect(() => {
    const body = document.querySelector('body')

    if (!profile || !profile?.plan || dismissed || (!isFree && hasBilling)) {
      setShowBanner(false)
      body.classList.remove('has-banner')
      return
    }

    setShowBanner(true)
    body.classList.add('has-banner')
  }, [profile, dismissed, isFree, hasBilling])

  useEffect(() => {
    if (height) {
      document.body.style.setProperty('--banner-height', `${height}px`)
    }
  }, [height])

  const handleDismiss = () => {
    setDismissed(true)
    window.localStorage.setItem('banner-dismissed', true)
  }

  const upgradeDiscount = (
    ((PLAN_PRICES[PLANS.ARTIST].price - PLAN_PRICES[PLANS.ARTIST].salePrice) /
      PLAN_PRICES[PLANS.ARTIST].price) *
    100
  ).toFixed(0)

  const classes = classNames({
    banner: true,
    'banner-fixed': true,
    'banner-bottom': true,
    'banner-free': isFree,
    'banner-billing': !isFree && !hasBilling,
    [className]: className,
  })

  if (DEVELOPMENT) return null

  if (showBanner) {
    return (
      <div className={classes} ref={ref}>
        {isFree && (
          <>
            <div className="banner-content">
              <p className="font-medium">
                {intl.formatMessage(
                  { id: 'banner.free.text' },
                  { plan: 'Artist' }
                )}
              </p>
              <Badge variant="green-light" className="font-bold">
                {intl.formatMessage(
                  { id: 'banner.free.badge' },
                  { discount: upgradeDiscount }
                )}
              </Badge>
            </div>

            <div className="flex items-center gap-2">
              <Button
                small
                to={`${ROUTES.PRICING}?plan=${PLANS.ARTIST}`}
                variant="green"
              >
                {intl.formatMessage({ id: 'banner.free.cta_upgrade' })}
              </Button>
              <Button
                icon="times"
                variant="ghost"
                onClick={() => handleDismiss()}
              />
            </div>
          </>
        )}

        {!isFree && !hasBilling && (
          <>
            <div className="banner-content">
              <p className="font-medium">
                {intl.formatMessage(
                  { id: 'banner.billing.text' },
                  { credits: PROFILE_COMPLETE_BONUS }
                )}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <Button
                small
                to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.SETTINGS}/?tab=${ROUTES.MY_PROFILE.BILLING_INFO}`}
                variant="orange"
              >
                {intl.formatMessage({ id: 'banner.billing.cta' })}
              </Button>
              <Button
                icon="times"
                variant="ghost"
                onClick={() => handleDismiss()}
              />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Banner
