import React, { useEffect, useState } from 'react'
import Button from '@fnd/components/Button'
import Info from '@fnd/components/Info'
import Spinner from '@fnd/components/Spinner'
import { FormattedMessage, useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useMatchStore } from '@fnd/store'
import { toastFeedback } from '@fnd/core/libs/toast'
import MatchMessage from '@fnd/components/Match/MatchMessage'
import TikTokContacts from './TikTokContacts'
import { TIKTOK_PROFILE_TYPES } from '@fnd/constants'

export function TikTokPush({
  agencyContacts,
  contacts,
  profileType,
  tags,
  tikTokId,
  tikTokHandle,
}) {
  const { track, trackId } = useMatchStore()
  const [push, setPush] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()

  const handlePush = async () => {
    setIsLoading(true)

    try {
      const res = await spotimatchEndpoints.pushTikTokQueue(tikTokId, trackId)
      if (res?.user) {
        setPush(true)
        toastFeedback(
          'success',
          intl.formatMessage({ id: 'feedback.success.tiktok_push' })
        )
      }
    } catch (e) {
      const error = e?.response?.data?.message || 'feedback.error.default'
      toastFeedback('error', intl.formatMessage({ id: error }))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const checkQueue = async () => {
      setIsLoading(true)

      try {
        const res = await spotimatchEndpoints.checkTikTokQueue(
          tikTokId,
          trackId
        )
        if (res?.user) {
          setPush(true)
        }
      } catch (e) {
        console.log(e)
      }

      setIsLoading(false)
    }

    checkQueue()
  }, [])

  const genres = tags.filter((tag) => tag !== 'all')

  const TikTokPushed = () => {
    return (
      <>
        {genres && genres?.length > 0 && (
          <Info
            icon="tag"
            className="capitalize"
            label={<FormattedMessage id="field.tags.label" />}
            value={genres.join(', ')}
          />
        )}

        {profileType !== TIKTOK_PROFILE_TYPES.AGENCY && (
          <TikTokContacts contacts={contacts} tikTokHandle={tikTokHandle} />
        )}

        {profileType === TIKTOK_PROFILE_TYPES.AGENCY && (
          <TikTokContacts contacts={agencyContacts} />
        )}

        <MatchMessage className="mt-4" typology="tiktok" />
      </>
    )
  }

  if (isLoading) return <Spinner />

  return (
    <>
      {push && <TikTokPushed />}

      {!push && (
        <Button
          variant="gradient"
          className="w-full mt-3"
          loading={isLoading}
          label={<FormattedMessage id="tiktok.push.cta" />}
          onClick={handlePush}
        />
      )}
    </>
  )
}

export default TikTokPush
