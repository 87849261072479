import React from 'react'
import Badge from '@fnd/components/Badge'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { FormattedMessage } from 'react-intl'
import { Placeholder } from '@fnd/components/Placeholder'
import { ROUTES } from '@fnd/screens/constants'

export function PlaylistCard({ playlist, className }) {
  const PlaylistCover = () => {
    if (playlist?.images && playlist?.images[0]?.url) {
      return (
        <div
          className="playlist-cover"
          style={{ backgroundImage: `url("${playlist?.images[0]?.url}")` }}
        />
      )
    } else {
      return <Placeholder className="playlist-cover" icon="music" />
    }
  }

  const classes = classNames({
    'playlist-card': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="playlist-info-wrapper">
        <PlaylistCover />

        <div className="playlist-info">
          <div className="playlist-meta">
            <Badge
              variant="light"
              icon="user-circle"
              label={<FormattedMessage id="match.table.owner" />}
              to={`${ROUTES.CURATORS}/${playlist?.owner?.id}`}
              value={playlist?.owner?.display_name}
            />
            <Badge
              variant="light"
              icon="users"
              label={<FormattedMessage id="match.table.followers" />}
              value={playlist?.followers?.total}
            />
          </div>

          <h2>{playlist.name}</h2>

          <p className="playlist-description">{parse(playlist.description)}</p>

          <div className="flex">
            {playlist?.external_urls?.spotify && (
              <a
                href={playlist?.external_urls?.spotify}
                className="button button-primary mt-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="mr-2" type="brands" name="spotify" />
                Spotify Link
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlaylistCard
