import React, { useState, useEffect } from 'react'
import Badge from '@fnd/components/Badge'
import Info from '@fnd/components/Info'
import { PLANS } from '@fnd/constants'
import { Accordion, AccordionItem } from '@fnd/components/Accordion'
import { RadioContacts } from '@fnd/modules/Radio'
import { getPlatformUrl } from '@fnd/core/libs/platforms'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'

export function RadioDetail({
  contacts,
  description,
  location,
  name,
  references,
  stationType,
  tags,
  website,
  className,
}) {
  const [isAgency, setIsAgency] = useState(false)

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  useEffect(() => {
    if (profile?.plan?.name === PLANS.AGENCY) {
      setIsAgency(true)
    }
  }, [profile])

  const intl = useIntl()

  const classes = classNames({
    'radio-detail': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      {description && <p>{description}</p>}

      <Info icon="signal-stream" label="Radio" value={name} />

      <Info icon="map-pin" label="Location" value={location} />

      <Info
        icon="broadcast-tower"
        label={<FormattedMessage id="field.station_type.label" />}
        value={
          <div className="flex flex-wrap gap-2">
            {stationType?.map((tag) => (
              <Badge key={tag} variant="blue-light" label={tag} />
            ))}
          </div>
        }
      />

      {isAgency ? (
        <>
          <Info
            icon="tags"
            label="Tags"
            value={
              <div className="flex flex-wrap gap-2">
                {tags?.map((tag) => (
                  <Badge key={tag} variant="blue-light" label={tag} />
                ))}
              </div>
            }
          />

          <Info
            icon="browser"
            label="Website"
            value={
              <a
                href={getPlatformUrl('website', website)}
                target="_blank"
                rel="noopener noreferrer"
                className="truncate"
              >
                {website}
              </a>
            }
          />

          <RadioContacts contacts={contacts} />

          {references && references?.length > 0 && (
            <Accordion className="mt-5">
              {references.map((reference) => (
                <AccordionItem
                  key={reference?.name}
                  icon="user-circle"
                  label={
                    reference?.name === ''
                      ? intl.formatMessage({ id: 'reference.main_contact' })
                      : reference?.name
                  }
                >
                  <RadioContacts contacts={reference?.contacts} />
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </>
      ) : (
        <AccountUpgrade
          small
          btnVariant="gradient"
          btnClasses="w-full"
          className="mt-4"
          message={intl.formatMessage(
            { id: 'profile.upgrade.radio' },
            {
              plan: 'agency',
            }
          )}
          plan={PLANS.AGENCY}
        />
      )}
    </div>
  )
}

export default RadioDetail
