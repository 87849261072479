import React from 'react'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import { BackgroundSquare } from '@fnd/components/Background'
import {
  AppleMusicLogoIcon,
  DeezerLogoIcon,
  NapsterLogoIcon,
  ShazamLogoIcon,
  SoundcloudLogoIcon,
  SpotifyLogoIcon,
  TidalLogoIcon,
  YouTubeLogoIcon,
} from '@fnd/components/Logo'
import classNames from 'classnames'

const DistributionMockup = () => {
  const orbitingLogos = [
    {
      key: 'apple-music',
      component: <AppleMusicLogoIcon />,
      style: {
        top: '10%',
        left: '5%',
      },
    },
    {
      key: 'deezer',
      component: <DeezerLogoIcon />,
      style: {
        top: '30%',
        left: '20%',
      },
    },
    {
      key: 'napster',
      component: <NapsterLogoIcon />,
      style: {
        left: '0%',
        bottom: '35%',
      },
    },
    {
      key: 'shazam',
      component: <ShazamLogoIcon />,
      style: {
        left: '20%',
        bottom: '15%',
      },
    },
    {
      key: 'soundcloud',
      component: <SoundcloudLogoIcon />,
      style: {
        top: '5%',
        right: '0%',
      },
    },
    {
      key: 'spotify',
      component: <SpotifyLogoIcon />,
      style: {
        top: '20%',
        right: '20%',
      },
    },
    {
      key: 'tidal',
      component: <TidalLogoIcon />,
      style: {
        right: '5%',
        bottom: '30%',
      },
    },
    {
      key: 'youtube',
      component: <YouTubeLogoIcon />,
      style: {
        right: '20%',
        bottom: '10%',
      },
    },
  ]

  const logoClasses = classNames({
    'logo-card': true,
    'scale-100 hover:scale-110': true,
    'shadow-md hover:shadow-lg': true,
    'transition-all duration-500 ease-in-out': true,
  })

  return (
    <div className="distro-mockup">
      <div className="distro-mockup-bg">
        <BackgroundSquare className="opacity-10 scale-120" />
      </div>

      <div className="distro-mockup-content">
        <div className={`${logoClasses} center-logo z-10`}>
          <LogoIcon />
        </div>

        <div className="orbiting-logos">
          {orbitingLogos.map((logo) => (
            <div
              key={logo.key}
              className={`${logoClasses} orbiting-logo`}
              style={logo.style}
            >
              {logo.component}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DistributionMockup
