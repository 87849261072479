import React from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import { USER_ROYALTIES_PERCENTAGE } from '@fnd/constants'
import { useIntl } from 'react-intl'

const RoyaltiesMockup = ({ className }) => {
  const intl = useIntl()

  const classes = classNames({
    'royalties-mockup': true,
    'flex flex-col items-start gap-2 w-full': true,
    [className]: className,
  })

  const cardClasses = classNames({
    'flex flex-col items-start gap-2': true,
    'bg-primary p-4 rounded-lg border-outline': true,
    'transform transition-all duration-300': true,
    'scale-100 hover:scale-95': true,
    'shadow-md hover:shadow-lg': true,
  })

  return (
    <div className={classes}>
      <div className={`${cardClasses} w-full`}>
        <h3 className="inline-flex items-center gap-4 m-0 text-green">
          <Icon name="user-music" className="text-3xl" />
          <span className="text-4xl">
            {(USER_ROYALTIES_PERCENTAGE * 100).toFixed(0)}%
          </span>
        </h3>

        <p className="font-light opacity-80 text-balance m-0">
          {intl.formatMessage({ id: 'royalties.user.caption' })}
        </p>
      </div>

      <div className={`${cardClasses} w-fit`}>
        <h3 className="inline-flex items-center gap-4 m-0 text-primary">
          <LogoIcon className="w-10 h-10" />
          <span className="text-4xl">
            {((1 - USER_ROYALTIES_PERCENTAGE) * 100).toFixed(0)}%
          </span>
        </h3>

        <p className="font-light opacity-80 text-balance m-0">
          {intl.formatMessage({ id: 'royalties.matchfy.caption' })}
        </p>
      </div>
    </div>
  )
}

export default RoyaltiesMockup
