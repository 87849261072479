import React from 'react'
import Auth from '@fnd/components/Auth'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { MatchSwitch } from '@fnd/components/Match'
import PlaylistFeaturedList from './PlaylistFeaturedList'

export function PlaylistFeaturedMatch() {
  return (
    <Wrapper>
      <Auth login emptyState>
        <MatchSwitch initial="playlist_featured" className="mb-8" />

        <PlaylistFeaturedList />
      </Auth>
    </Wrapper>
  )
}

export default PlaylistFeaturedMatch
