import React, { useEffect } from 'react'
import Button from '@fnd/components/Button'
import { StepperFooter } from '@fnd/components/Stepper'
import Field from '@fnd/components/Field'
import {
  VipFormInstagram,
  VipFormPodcast,
  VipFormSpotify,
  VipFormTikTok,
  VipFormTikTokTalents,
  VipFormYouTube,
} from '../Forms'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { CAMPAIGN_FORM_TYPOLOGIES } from '../config'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useVipCampaignStore } from '@fnd/store'

const VipCampaignStep3 = ({ set, next, previous, profile, plan }) => {
  const {
    form,
    setForm,
    calculatePaymentTotal,
    getFormData,
    setLoading,
    setSuccess,
    setError,
  } = useVipCampaignStore()
  const intl = useIntl()

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      ...form,
    },
  })

  const formData = watch()
  const minuteStart = watch('info.tiktokTalents.minuteStart')
  const minuteEnd = watch('info.tiktokTalents.minuteEnd')

  const isFormTypology = CAMPAIGN_FORM_TYPOLOGIES.some((typology) =>
    formData?.typologies.includes(typology)
  )

  const handleBack = () => {
    const data = getValues()
    setForm({
      ...form,
      ...data,
    })

    if (
      form.typologies.includes('tiktok') ||
      form.typologies.includes('youtube')
    ) {
      previous()
    } else {
      set('vip-step-1')
    }
  }

  const onSubmit = async (data) => {
    setForm({
      ...form,
      ...data,
    })

    if (isFormTypology) {
      setLoading(true)

      try {
        const data = getFormData()
        await spotimatchEndpoints.requestVipCampaign(data)
        setSuccess('vip_campaign.request.success')
      } catch (error) {
        setError('vip_campaign.request.error')
      }

      setLoading(false)
      return
    }

    next()
  }

  useEffect(() => {
    calculatePaymentTotal(formData, plan)
  }, [])

  useEffect(() => {
    if (minuteStart && minuteEnd) {
      setValue('info.tiktokTalents.minute', `${minuteStart} - ${minuteEnd}`)
    }
  }, [minuteStart, minuteEnd])

  const formProps = {
    control,
    errors,
    setValue,
    profile,
    plan,
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2 mt-2 mb-6">
        <h3 className="text-3xl m-0">
          {intl.formatMessage({ id: 'vip_campaign.step3.title' })}
        </h3>
        <p className="font-light text-lg opacity-80 m-0">
          {intl.formatMessage({ id: 'vip_campaign.step3.text' })}
        </p>
      </div>

      <div className="flex flex-col gap-8">
        {form?.typologies.includes('instagram') && (
          <VipFormInstagram {...formProps} />
        )}

        {form?.typologies.includes('podcast') && (
          <VipFormPodcast {...formProps} />
        )}

        {(form?.typologies.includes('spotify') ||
          form?.typologies.includes('spotifyAlgo')) && (
          <VipFormSpotify {...formProps} />
        )}

        {form?.typologies.includes('tiktok') && (
          <VipFormTikTok {...formProps} />
        )}

        {form?.typologies.includes('tiktokTalents') && (
          <VipFormTikTokTalents {...formProps} />
        )}

        {form?.typologies.includes('youtube') && (
          <VipFormYouTube {...formProps} />
        )}

        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <Field
              type="textarea"
              label={intl.formatMessage({
                id: 'vip_campaign.field.notes.label',
              })}
              errors={errors?.notes}
              {...field}
            />
          )}
        />
      </div>

      <StepperFooter className="mt-6">
        <Button
          variant="inverse"
          type="button"
          onClick={handleBack}
          icon="arrow-left"
        >
          {intl.formatMessage({ id: 'messages.previous' })}
        </Button>

        <Button
          type="submit"
          icon={isFormTypology ? 'paper-plane' : 'arrow-right'}
          label={intl.formatMessage({
            id: isFormTypology ? 'messages.send_request' : 'messages.next',
          })}
        />
      </StepperFooter>
    </form>
  )
}

export default VipCampaignStep3
