import React from 'react'
import { useIntl } from 'react-intl'
import { AccelerateContent } from '@fnd/components/Accelerate'
import Badge from '@fnd/components/Badge'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Wrapper from '@fnd/components/Wrapper/Wrapper'

function Accelerate() {
  const intl = useIntl()

  return (
    <Wrapper>
      <SectionTitle
        title={intl.formatMessage({ id: 'accelerate.title' })}
        subtitle={intl.formatMessage({ id: 'accelerate.subtitle' })}
        headingClasses="text-gradient-primary-fade"
        badge={
          <Badge
            variant="primary-light"
            label={intl.formatMessage({ id: 'navigation.accelerate' })}
            icon="rocket"
          />
        }
        center
      />

      <AccelerateContent />
    </Wrapper>
  )
}

export default Accelerate
