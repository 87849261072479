import React from 'react'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import Button from '@fnd/components/Button'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { useIntl } from 'react-intl'

const ErrorLogin = () => {
  const intl = useIntl()

  return (
    <Wrapper id="error-login">
      <section className="error-login-wrapper">
        <LogoIcon />

        <h1>{intl.formatMessage({ id: 'error_login.title' })}</h1>

        <p>{intl.formatMessage({ id: 'error_login.subtitle' })}</p>

        <Button
          large
          variant="gradient"
          to={'https://support.spotify.com/us/contact-spotify-support/'}
          label={intl.formatMessage({ id: 'error_login.cta' })}
          className="mt-8"
        />
      </section>
    </Wrapper>
  )
}

export default ErrorLogin
