import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import sanityClient from '@fnd/core/sanity/client'
import ConditionalLink from '@fnd/components/ConditionalLink/ConditionalLink'
import Badge from '@fnd/components/Badge'
import Date from '@fnd/components/Date'
import Marquee from 'react-fast-marquee'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import StarRating from '@fnd/components/Rating'
import { reviewQuery } from '@fnd/core/sanity/queries'
import { nanoid } from 'nanoid'
import { useIntl } from 'react-intl'

export function Reviews({ className }) {
  const [reviews, setReviews] = useState([])

  const classes = classNames({
    reviews: true,
    section: true,
    'container mx-auto': true,
    [className]: className,
  })

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await sanityClient.fetch(reviewQuery)
        setReviews(reviewsData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchReviews()
  }, [])

  const renderReviews = reviews.map((item) => {
    return (
      <ConditionalLink
        key={nanoid()}
        className="review-card"
        to={`${item.url}?ref=matchfy`}
        condition={item.url && item.url.length > 0}
        external
      >
        <div className="review-card-header">
          <StarRating rating={item.rating} />
          <Date dateString={item.date} />
        </div>
        <div className="review-card-body">
          <span>{item.reviewer}</span>
          <h5>{item.title}</h5>
          {item.text && <p>{item.text}</p>}
        </div>
      </ConditionalLink>
    )
  })

  const intl = useIntl()

  return (
    <div className={classes}>
      <SectionTitle
        tag="h3"
        title={intl.formatMessage({ id: 'reviews.title' })}
        subtitle={intl.formatMessage({ id: 'reviews.subtitle' })}
        center
        badge={
          <Badge
            variant="gold-light"
            label={intl.formatMessage({ id: 'messages.reviews' })}
            icon="stars"
          />
        }
      />

      <Marquee
        className="review-marquee"
        gradientWidth={50}
        pauseOnHover
        speed={12}
      >
        {reviews && reviews.length > 0 ? renderReviews : null}
      </Marquee>
    </div>
  )
}

export default Reviews
