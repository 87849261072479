import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as Scroll } from 'react-scroll'
import { AccelerateRocket } from '@fnd/components/Accelerate'
import Icon from '@fnd/components/Icon'

const AccelerateIntro = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 items-center">
        <AccelerateRocket />

        <div>
          <h5>
            <FormattedMessage
              id="accelerate.apply_to"
              values={{
                span: (chunk) => (
                  <span className="text-primary font-weight-bold">{chunk}</span>
                ),
                b: (chunk) => <b>{chunk}</b>,
              }}
            />
          </h5>

          <p>
            <FormattedMessage
              id="accelerate.feel_free"
              values={{ b: (chunk) => <b>{chunk}</b> }}
            />
          </p>

          <p>
            <FormattedMessage id="accelerate.by_the_end" />
          </p>

          <p className="font-bold">
            <FormattedMessage id="accelerate.confirmation_email" />
          </p>

          <Scroll
            className="button button-gradient px-4 py-2 mt-5"
            to="accelerate-apply"
            spy
            smooth
            offset={-200}
            duration={500}
          >
            <Icon name="arrow-right" />
            <FormattedMessage id="accelerate.apply_now_button" />
          </Scroll>
        </div>
      </div>
    </>
  )
}

export default AccelerateIntro
