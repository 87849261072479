import React, { useEffect, useMemo } from 'react'
import { AuthArtist } from '@fnd/components/Auth'
import Alert from '@fnd/components/Alert'
import Genres from '@fnd/modules/Analysis/Search/Genres'
import Button from '@fnd/components/Button'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import MatchTrack from './MatchTrack'
import MatchRecent from './MatchRecent'
import classNames from 'classnames'
import { PLANS, GENRES_QUERY_LIMIT } from '@fnd/constants'
import { PushCounter } from '@fnd/modules/Analysis/Spotify'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import {
  useMatchStore,
  useRecentMatchStore,
  usePushToPlaylistStore,
} from '@fnd/store'
import { FormattedMessage, useIntl } from 'react-intl'

export const MatchForm = ({
  onSubmit,
  btnLabel = 'Match',
  btnVariant = 'gradient',
  matchTypeEnabled,
  className,
}) => {
  const intl = useIntl()

  const {
    track,
    setTrack,
    trackId,
    setTrackId,
    genres,
    setGenres,
    isMatching,
    setIsMatching,
    matchType,
    setMatchType,
    canMatch,
    setCanMatch,
  } = useMatchStore()

  const { addMatch } = useRecentMatchStore()
  const { pushedCount, resetPushedPlaylists } = usePushToPlaylistStore()

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  useEffect(() => {
    setMatchType(matchTypeEnabled)
    return () => {
      setIsMatching(false)
      setMatchType(null)
    }
  }, [])

  useEffect(() => {
    setIsMatching(false)
    return () => {
      setIsMatching(false)
    }
  }, [track, genres])

  const handleTrackChange = (track) => {
    setTrack(track)
    setTrackId(track.id)
    resetPushedPlaylists()
  }

  const handleClear = () => {
    setTrack(null)
    setGenres(null)
  }

  const populateMatch = (match) => {
    setTrack(match.track)
    setGenres(match.genres)
    setTrackId(match.track.id)
  }

  const handleSubmit = () => {
    addMatch({ track, genres, type: matchType })
    setIsMatching(true)

    if (onSubmit) {
      onSubmit({
        track,
        genres,
      })
    }
  }

  const classes = classNames({
    'match-form': true,
    [className]: className,
  })

  const handleArtistSelect = () => {
    setCanMatch(true)
  }

  const isDisabled = useMemo(() => {
    if (profile?.plan?.name === PLANS.AGENCY) {
      return false
    }

    return profile?.spotify_artist_id?.length == 0
  }, [profile])

  return (
    <AuthArtist onArtistSelect={() => handleArtistSelect()} className="mb-8">
      <div className={classes}>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="flex flex-col items-start gap-6 md:col-span-2">
            {isDisabled && (
              <Alert
                variant="primary"
                label={intl.formatMessage({ id: 'feedback.error.artist' })}
              />
            )}

            <SearchSelect
              entity="track"
              className="w-full"
              title={<FormattedMessage id="match.first_step" />}
              name="track"
              tooltipText={
                <img
                  src="/images/matchfy_select_tutorial.gif"
                  alt="tutorial"
                  loading="lazy"
                  className="max-w-full sm:max-w-4xl rounded-sm"
                />
              }
              label={<FormattedMessage id="messages.search_track" />}
              defaultValue={trackId}
              artistIds={profile?.spotify_artist_id}
              limitBy={profile?.plan?.name !== PLANS.AGENCY ? 'artist' : null}
              value={trackId}
              onDataChange={handleTrackChange}
              onClear={handleClear}
              isDisabled={isDisabled}
            />

            <PushCounter
              pushCount={pushedCount}
              popularity={track?.popularity}
            />

            <MatchRecent
              artists={profile?.spotify_artist_id}
              plan={profile?.plan?.name}
              onClick={populateMatch}
            />

            <Genres
              title="Step 2"
              className="w-full"
              genres={genres}
              maxLimit={GENRES_QUERY_LIMIT}
              onGenreSelect={(genres) => setGenres(genres)}
              isDisabled={isDisabled}
            />
          </div>

          <MatchTrack />
        </div>

        <Button
          icon="list-music"
          variant={btnVariant}
          onClick={handleSubmit}
          className="w-full mt-6"
          label={btnLabel}
          disabled={!trackId || !genres?.length || isMatching || !canMatch}
        />
      </div>
    </AuthArtist>
  )
}

export default MatchForm
