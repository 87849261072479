import React from 'react'
import classNames from 'classnames'

const AppleMusicLogoIcon = ({ className }) => (
  <svg
    className={classNames({ [className]: className })}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_277_661)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.584 46.7909C149.584 45.0042 149.584 43.2175 149.576 41.4308C149.568 39.9266 149.551 38.4224 149.51 36.9183C149.422 33.6399 149.227 30.3366 148.645 27.0956C148.055 23.8047 147.087 20.7424 145.566 17.7507C144.071 14.813 142.118 12.1288 139.787 9.79778C137.456 7.46676 134.767 5.51385 131.83 4.01801C128.842 2.49723 125.78 1.53324 122.493 0.939058C119.252 0.357341 115.945 0.16205 112.666 0.0747923C111.162 0.033241 109.658 0.0166205 108.154 0.00831025C106.367 0 104.58 0 102.794 0H46.7909C45.0042 0 43.2175 0 41.4307 0.00831025C39.9266 0.0166205 38.4224 0.033241 36.9183 0.0747923C33.6399 0.166205 30.3324 0.357341 27.0914 0.943213C23.8006 1.53324 20.7424 2.50139 17.7548 4.02216C14.8172 5.51801 12.1288 7.47091 9.79778 9.80194C7.46676 12.133 5.51385 14.8172 4.01801 17.7548C2.49723 20.7465 1.52909 23.8089 0.939058 27.0997C0.357341 30.3407 0.16205 33.6482 0.0747922 36.9224C0.033241 38.4266 0.0166205 39.9308 0.00831025 41.4349C0 43.2175 0 45.0042 0 46.7909V102.789C0 104.576 0 106.363 0.00831025 108.15C0.0166205 109.654 0.033241 111.158 0.0747922 112.662C0.16205 115.94 0.357341 119.244 0.939058 122.485C1.52909 125.776 2.49723 128.838 4.01801 131.83C5.51385 134.767 7.46676 137.452 9.79778 139.783C12.1288 142.114 14.8172 144.066 17.7548 145.562C20.7424 147.083 23.8047 148.047 27.0914 148.641C30.3324 149.223 33.6399 149.418 36.9183 149.506C38.4224 149.547 39.9266 149.564 41.4307 149.572C43.2175 149.585 45.0042 149.58 46.7909 149.58H102.789C104.576 149.58 106.363 149.58 108.15 149.572C109.654 149.564 111.158 149.547 112.662 149.506C115.94 149.418 119.248 149.223 122.489 148.641C125.78 148.051 128.838 147.083 131.825 145.562C134.763 144.066 137.452 142.114 139.783 139.783C142.114 137.452 144.066 134.767 145.562 131.83C147.083 128.838 148.051 125.776 148.641 122.485C149.223 119.244 149.418 115.936 149.506 112.662C149.547 111.158 149.564 109.654 149.572 108.15C149.584 106.363 149.58 104.576 149.58 102.789V46.7909H149.584Z"
        fill="url(#paint0_linear_277_661)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.748 22.8532C105.386 22.8865 102.175 23.4557 101.788 23.5347L57.3282 32.5056L57.3116 32.5097C56.1523 32.7549 55.2424 33.1663 54.5402 33.7563C53.6925 34.4668 53.223 35.4724 53.0443 36.6441C53.0069 36.8934 52.9446 37.4003 52.9446 38.1483C52.9446 38.1483 52.9446 83.5721 52.9446 93.7937C52.9446 95.0942 52.8407 96.3574 51.9598 97.4336C51.0789 98.5098 49.9903 98.8338 48.7147 99.0915C47.7465 99.2868 46.7784 99.482 45.8102 99.6773C42.1371 100.417 39.7479 100.92 37.5831 101.759C35.5138 102.561 33.964 103.583 32.7299 104.88C30.2825 107.443 29.2895 110.921 29.6302 114.179C29.921 116.959 31.1717 119.618 33.3199 121.583C34.7701 122.913 36.5817 123.922 38.7174 124.35C40.9321 124.795 43.2922 124.641 46.741 123.943C48.5776 123.573 50.2978 122.996 51.9349 122.028C53.5554 121.072 54.9432 119.796 56.0277 118.242C57.1163 116.684 57.8186 114.952 58.205 113.111C58.6039 111.212 58.6994 109.496 58.6994 107.601V59.3394C58.6994 56.7549 59.4307 56.0735 61.5166 55.5665C61.5166 55.5665 98.4723 48.1122 100.197 47.7757C102.602 47.3145 103.737 48.0001 103.737 50.5222V83.4682C103.737 84.7729 103.724 86.0942 102.835 87.1746C101.954 88.2507 100.866 88.5748 99.59 88.8325C98.6219 89.0278 97.6537 89.223 96.6856 89.4183C93.0125 90.1579 90.6233 90.6607 88.4584 91.5001C86.3892 92.302 84.8393 93.3242 83.6053 94.6206C81.1579 97.1843 80.0776 100.662 80.4183 103.92C80.7091 106.7 82.0471 109.359 84.1953 111.324C85.6454 112.654 87.4571 113.634 89.5928 114.067C91.8075 114.511 94.1676 114.353 97.6163 113.659C99.4529 113.29 101.173 112.737 102.81 111.769C104.431 110.813 105.819 109.537 106.903 107.983C107.992 106.425 108.694 104.693 109.08 102.852C109.479 100.953 109.496 99.2369 109.496 97.3422V26.784C109.504 24.2244 108.154 22.6455 105.748 22.8532Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_277_661"
        x1="74.7922"
        y1="149.005"
        x2="74.7922"
        y2="3.22379"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA233B" />
        <stop offset="1" stopColor="#FB5C74" />
      </linearGradient>
      <clipPath id="clip0_277_661">
        <rect width="150" height="150" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default AppleMusicLogoIcon
