import React from 'react'
import Badge from '@fnd/components/Badge'
import MagazinePush from './MagazinePush'
import { PLANS } from '@fnd/constants'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

export function MagazineDetail({ className, ...props }) {
  const intl = useIntl()

  const { contacts, logo, location, preview, name } = props

  const classes = classNames({
    'magazine-detail': true,
    'magazine-detail-preview': preview,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="magazine-detail-header">
        <div className="magazine-detail-logo rounded-md">
          <img src={logo} alt={name} className="rounded-md" />
        </div>

        <div className="flex flex-col items-start justify-center">
          <h3 className="w-full text-xl mb-2">{name}</h3>

          <div className="flex flex-wrap items-center gap-1">
            {contacts?.length > 0 && (
              <Badge
                className="block text-center truncate"
                icon="envelope"
                variant="light"
                label={contacts?.length}
              />
            )}

            {location && (
              <Badge
                className="block text-center truncate"
                icon="globe"
                variant="light"
                label={location}
              />
            )}
          </div>
        </div>
      </div>

      {preview && (
        <AccountUpgrade
          small
          btnVariant="gradient"
          btnClasses="w-full"
          message={intl.formatMessage(
            { id: 'profile.upgrade.magazine' },
            {
              plan: 'agency',
            }
          )}
          plan={PLANS.AGENCY}
        />
      )}

      {!preview && <MagazinePush {...props} />}
    </div>
  )
}

export default MagazineDetail
