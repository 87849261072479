import ConsentProvider from '@fnd/context/ConsentProvider'
import ThemeProvider from '@fnd/context/ThemeProvider'
import Catcher from '@fnd/components/Catcher'
import EmailVerifyPrompt from '@fnd/components/EmailVerifyPrompt'
import {
  EmailVerifyContext,
  useEmailVerify,
} from '@fnd/components/EmailVerifyPrompt/useEmailVerify'
import {
  withUserAuthentication,
  withUserContextProvider,
} from '@fnd/modules/User'
import '@fnd/styles/style.scss'
import {
  TranslationContext,
  getExistingLocale,
  messages,
  setExistingLocale,
} from '@fnd/translations'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { useCallback, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { ShepherdTour } from 'react-shepherd'
import { MAINTENANCE } from './constants'
import Maintenance from './screens/Maintenance'
import Layout from './Layout'
import { onlyUpdateForKeys } from 'recompose'
import { useSearchParams } from 'react-router-dom'

const queryClient = new QueryClient()

function Bootstrap({ authorize, user }) {
  const [searchParams] = useSearchParams()
  useEffect(() => {
    authorize()
  }, [])

  useEffect(() => {
    if (searchParams.has('lang')) {
      const lang = searchParams.get('lang')
      setExistingLocale(lang)
      searchParams.delete('lang')
      const s = searchParams.toString()
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}${s ? `?${s}` : ''}`
      )
      window.location.href = `${window.location.pathname}${s ? `?${s}` : ''}`
      window.location.reload()
    }
  }, [])

  const [locale, setLocale] = useState(getExistingLocale())

  const handleSetLocale = useCallback((language) => {
    setExistingLocale(language)
    setLocale(language)
  }, [])

  const {
    isEmailVerifyPopupOpen,
    openEmailVerifyPopup,
    closeEmailVerifyPopup,
    onSubmitEmailVerify,
    emailVerifyResult,
  } = useEmailVerify()

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      highlightClass: 'highlight',
      scrollTo: false,
    },
    useModalOverlay: true,
    scrollTo: { behavior: 'smooth', block: 'center' },
  }

  return (
    <Catcher>
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <TranslationContext.Provider
            value={{ locale, setLocale: handleSetLocale }}
          >
            {MAINTENANCE ? (
              <Maintenance />
            ) : (
              <EmailVerifyContext.Provider value={{ openEmailVerifyPopup }}>
                <ThemeProvider>
                  <ConsentProvider>
                    <ShepherdTour steps={[]} tourOptions={tourOptions}>
                      <Layout user={user} />

                      <EmailVerifyPrompt
                        onSubmit={onSubmitEmailVerify}
                        userName={user.profile.spotify_username}
                        onClose={closeEmailVerifyPopup}
                        isOpen={isEmailVerifyPopupOpen}
                        result={emailVerifyResult}
                      />
                    </ShepherdTour>
                  </ConsentProvider>
                </ThemeProvider>
              </EmailVerifyContext.Provider>
            )}
          </TranslationContext.Provider>
        </IntlProvider>
      </QueryClientProvider>
    </Catcher>
  )
}

export default withUserContextProvider(
  withUserAuthentication(onlyUpdateForKeys(['user'])(Bootstrap))
)
