import { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { TOURS, VERIFY_EMAIL_TOUR_STEPS } from './tourSteps'
import { userProfileSelector } from '@fnd/modules/User'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { isMobile } from 'react-device-detect'
import { ShepherdTourContext } from 'react-shepherd'

export const Tour = () => {
  const tour = useContext(ShepherdTourContext)
  const intl = useIntl()

  const { pathname } = useLocation()

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const resetTourSteps = () => {
    tour.steps = []
  }

  const canShowTour = () => {
    let seenTours = window.localStorage.getItem('tour')
    if (seenTours && tour) {
      seenTours = JSON.parse(seenTours)
      if (seenTours.includes(tour.tourId)) {
        return false
      }
    }
    return true
  }

  const setTour = (set) => {
    tour.addSteps(tourSteps(set.steps))
    tour.tourId = set.tourId
    tour.startDelay = set.delay
  }

  const setTourSteps = () => {
    const tourSet = TOURS.find((set) => set.route === pathname)
    if (tourSet) {
      setTour(tourSet)
    }
  }

  const tourProgress = () => {
    const currentStep = tour.currentStep?.id
    const currentStepElement = tour.currentStep?.el
    const totalSteps = tour.steps.length
    const currentStepIndex =
      tour.steps.findIndex((step) => step.id === currentStep) + 1
    const percent = (currentStepIndex / totalSteps) * 100

    const header = currentStepElement.querySelector('.shepherd-header')
    const progressSteps = document.createElement('span')
    progressSteps.classList.add('tour-steps')

    const progressBar = document.createElement('div')
    const progress = document.createElement('div')
    progressBar.appendChild(progress)
    progressBar.classList.add('tour-progress')
    progress.style.width = `${percent}%`

    progressSteps.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${
      tour.steps.length
    }`

    header.insertBefore(
      progressSteps,
      currentStepElement.querySelector('.shepherd-title')
    )
    header.insertBefore(
      progressBar,
      currentStepElement.querySelector('.shepherd-cancel-icon')
    )
  }

  const tourSteps = (steps) => {
    let tourButtons = []

    const nextButton = {
      classes: 'button button-primary ml-auto',
      text: intl.formatMessage({ id: 'tour.next' }),
      action: () => {
        tour.next()
      },
    }

    const backButton = {
      classes: 'button button-outline-primary',
      text: intl.formatMessage({ id: 'tour.back' }),
      action: () => {
        tour.back()
      },
    }

    const endButton = {
      classes: 'button button-primary ml-auto',
      text: intl.formatMessage({ id: 'tour.end' }),
      action: () => {
        tour.complete()
      },
    }

    return steps.map((step, index) => {
      if (steps.length === 1) {
        tourButtons = [endButton]
      } else if (index === 0) {
        tourButtons = [nextButton]
      } else if (index === steps.length - 1) {
        tourButtons = [backButton, endButton]
      } else {
        tourButtons = [backButton, nextButton]
      }

      return {
        ...step,
        title: intl.formatMessage({ id: step.title }),
        text: intl.formatMessage({ id: step.text }),
        buttons: tourButtons,
        attachTo:
          isMobile && step?.attachToMobile
            ? step.attachToMobile
            : step.attachTo,
        when: {
          show() {
            document.body.classList.add('tour-active')
            tourProgress()
          },
          hide: function () {
            document.body.classList.remove('tour-active')
          },
        },
      }
    })
  }

  const startTour = () => {
    setTimeout(() => {
      if (canShowTour()) {
        tour.start()

        const localStorageTour = window.localStorage.getItem(`tour`)
        if (localStorageTour) {
          let seenTours = JSON.parse(localStorageTour)
          seenTours = [...seenTours, tour.tourId]
          localStorage.setItem(`tour`, JSON.stringify(seenTours))
        } else {
          localStorage.setItem(`tour`, JSON.stringify([tour.tourId]))
        }
      }
    }, tour.startDelay)
  }

  useEffect(() => {
    resetTourSteps()

    if (
      tour &&
      profile?.verified === false &&
      pathname === '/my-profile/overview'
    ) {
      setTour(VERIFY_EMAIL_TOUR_STEPS)
      startTour()
    } else if (tour && profile?.verified) {
      setTourSteps()
      startTour()
    }
  }, [profile, pathname])

  return null
}

export default Tour
