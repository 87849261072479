import React from 'react'
import { ProgressBar } from '@fnd/components/Progress'
import { getPopularityColor } from '@fnd/core/libs/popularity'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

function PushProgress({
  pushCount,
  pushRange,
  popularity,
  showFeedback = true,
  className,
}) {
  const intl = useIntl()

  const getPushPercentage = () => {
    return ((pushCount / pushRange.high) * 100).toFixed(2)
  }

  const getPushProgressFeedback = () => {
    const pushPercentage = getPushPercentage()
    let feedbackTier = 'lowest'

    if (pushPercentage >= 10 && pushPercentage < 25) {
      feedbackTier = 'low'
    } else if (pushPercentage >= 25 && pushPercentage <= 50) {
      feedbackTier = 'medium'
    } else if (pushPercentage > 50 && pushPercentage <= 75) {
      feedbackTier = 'high'
    } else if (pushPercentage > 75 && pushPercentage <= 99) {
      feedbackTier = 'almost_there'
    } else if (pushPercentage > 99) {
      feedbackTier = 'done'
    }

    const feedbackTitle = intl.formatMessage({
      id: `push_progress.${feedbackTier}.title`,
    })
    const feedbackText = intl.formatMessage({
      id: `push_progress.${feedbackTier}.text`,
    })

    return {
      title: feedbackTitle,
      text: feedbackText,
    }
  }

  const classes = classNames({
    [className]: className,
  })

  return (
    <div className={classes}>
      <ProgressBar
        variant={getPopularityColor(popularity)}
        label={intl.formatMessage({ id: 'messages.push_count' })}
        valueLabel={`${pushCount} (${getPushPercentage()}%)`}
        max={pushRange.high}
        value={pushCount}
        className="mb-4"
      />

      {showFeedback && (
        <div className="bg-secondary p-4 rounded-lg border-outline">
          <h3 className="text-sm font-semibold">
            {getPushProgressFeedback().title}
          </h3>

          <p className="text-sm">{getPushProgressFeedback().text}</p>
        </div>
      )}
    </div>
  )
}

export default PushProgress
