import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import Avatar from '@fnd/components/Avatar'
import Icon from '@fnd/components/Icon'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
  useUserContext,
  withUserAuthentication,
} from '@fnd/modules/User'
import Button from '@fnd/components/Button'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@fnd/components/Dropdown/DropdownMenu'
import { ThemeContext } from '@fnd/context/ThemeProvider'
import { MATCHFY_DISTRO_URL } from '@fnd/constants'
import { ROUTES, LOGOUT_REDIRECT } from '@fnd/screens/constants'

function NavigationProfile({ unauthorize }) {
  const navigate = useNavigate()
  const intl = useIntl()

  const { profile, isAuthorized } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  const { currentTheme, applyTheme } = useContext(ThemeContext)

  const toggleTheme = (e) => {
    e.preventDefault()
    if (currentTheme === 'dark') {
      applyTheme('light')
    } else {
      applyTheme('dark')
    }
  }

  const logout = () => {
    unauthorize()
    navigate(LOGOUT_REDIRECT)
  }

  const SignoutButton = () => {
    return (
      <Button
        variant="ghost"
        label={<FormattedMessage id="messages.logout" />}
        icon="sign-out"
        className="justify-start px-3"
        onClick={logout}
      />
    )
  }

  const [avatar = {}] = profile.images
  const curatorUrl = `${window.location.origin}${ROUTES.CURATORS}/${profile.spotify_id}`
  const linkClasses = 'flex items-center gap-2 py-[.6rem] px-3 cursor-pointer'

  const dropdownItems = [
    {
      id: 'dashboard',
      label: (
        <Link
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.DASHBOARD}`}
          className={linkClasses}
        >
          <Icon name="tachometer" />
          <FormattedMessage id="navigation.dashboard" />
        </Link>
      ),
    },
    {
      id: 'profile',
      label: (
        <Link
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`}
          className={linkClasses}
        >
          <Icon name="user" />
          <FormattedMessage id="navigation.profile" />
        </Link>
      ),
    },
    {
      id: 'profile_curator',
      label: (
        <Link to={curatorUrl} className={linkClasses}>
          <Icon name="user-music" />
          <FormattedMessage id="navigation.profile_curator" />
        </Link>
      ),
    },
    {
      id: 'artists',
      label: (
        <Link
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ARTISTS}`}
          className={linkClasses}
        >
          <Icon name="headphones" />
          <FormattedMessage id="navigation.my_artists" />
        </Link>
      ),
    },
    {
      id: 'submissions',
      label: (
        <Link
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ACTIVITY}`}
          className={linkClasses}
        >
          <Icon name="chart-line" />
          <FormattedMessage id="navigation.my_activity" />
        </Link>
      ),
    },
    {
      id: 'playlists',
      label: (
        <Link
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLISTS}`}
          className={linkClasses}
        >
          <Icon name="list-music" />
          <FormattedMessage id="navigation.my_playlists" />
        </Link>
      ),
    },
    {
      id: 'distribution',
      label: (
        <Link to={MATCHFY_DISTRO_URL} className={linkClasses}>
          <Icon name="album-collection" />
          <FormattedMessage id="navigation.distribution" />
        </Link>
      ),
    },
    {
      id: 'theme-toggle',
      label: (
        <Button
          icon={currentTheme === 'light' ? 'moon' : 'lightbulb'}
          variant="link-plain"
          onClick={toggleTheme}
          aria-label="Theme Toggle"
          className={`theme-toggle inline-flex justify-start ${linkClasses}`}
          label={
            currentTheme === 'light'
              ? `${intl.formatMessage({ id: 'messages.theme' })} Dark`
              : `${intl.formatMessage({ id: 'messages.theme' })} Light`
          }
        />
      ),
    },
    {
      id: 'settings',
      label: (
        <Link
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.SETTINGS}`}
          className={linkClasses}
        >
          <Icon name="cog" />
          <FormattedMessage id="navigation.settings" />
        </Link>
      ),
    },
  ]

  if (!isAuthorized) return null

  return (
    <DropdownMenu className="z-[1000]">
      <DropdownMenuTrigger asChild>
        <div className="nav-user flex items-center cursor-pointer">
          <Avatar
            size="small"
            src={avatar.url}
            label={profile.id}
            star={profile?.star}
          />
          <span className="ml-2 text-sm font-medium truncate hidden md:block">
            {profile.display_name}
          </span>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-[180px]">
        {dropdownItems.map((item) => (
          <DropdownMenuItem asChild key={item.id} className="p-0">
            {item.label}
          </DropdownMenuItem>
        ))}

        <DropdownMenuSeparator />

        <DropdownMenuItem className="p-0">
          <SignoutButton variant="link-plain" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default withUserAuthentication(NavigationProfile)
