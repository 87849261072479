import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { ROUTES } from '@fnd/screens/constants'

const VipCampaignAlert = ({ className, btnVariant = 'inverse', ...props }) => {
  const classes = classNames({
    'bg-secondary border-0 border-outline': true,
    'text-sm md:text-base': true,
    [className]: className,
  })

  return (
    <Alert
      {...props}
      className={classes}
      cta={
        <Button
          small
          className="w-full md:w-auto md:min-w-[200px]"
          icon="gem"
          to={ROUTES.VIP}
          variant={btnVariant}
        >
          VIP Campaign
        </Button>
      }
    >
      <FormattedMessage id="vip_campaign.alert.text" />
    </Alert>
  )
}

export default VipCampaignAlert
