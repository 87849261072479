import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import { Accordion, AccordionItem } from '@fnd/components/Accordion'
import Badge from '@fnd/components/Badge'

export const TUTORIALS = {
  WELCOME: 'https://www.youtube.com/watch?v=sVwc7wDY-Vc',
  PLANS: 'https://www.youtube.com/watch?v=PVT4b1jT-OU',
  PROFILE: 'https://www.youtube.com/watch?v=ri7YtlcXleQ',
  PUSH_TO_PLAYLIST: 'https://www.youtube.com/watch?v=K4rGN9kXTzA',
  PROMOTE_CURATOR: 'https://www.youtube.com/watch?v=ayIqxjvuT58',
  DISTRIBUTION: 'https://www.youtube.com/watch?v=_EB9ByBQUfI',
  CREATE_RELEASE: 'https://www.youtube.com/watch?v=6XmwkEw0jUw',
}

export const FAQ_DATA = [
  {
    question: 'faq.what_is',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.WELCOME} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.is_song_submitted',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.how_check_submissions',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/all-my-profile-tabs-explained/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.how_know_song_added',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/all-my-profile-tabs-explained/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.how_get_in_touch_curator',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.how_many_plays',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.how_match_song',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.how_export_curator',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/exporting-contacts-is-easy/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.how_be_curator',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/playlist-curators-as-influencers-why-you-should-be-one/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PROMOTE_CURATOR} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.what_featured_playlists',
    values: {
      blog: (
        <a href="https://blog.matchfy.io/playlist-curators-as-influencers-why-you-should-be-one/">
          <FormattedMessage id="faq.blog" />
        </a>
      ),
      video: (
        <a href={TUTORIALS.PROMOTE_CURATOR} target="_blank" rel="noreferrer">
          <FormattedMessage id="faq.video" />
        </a>
      ),
    },
  },
  {
    question: 'faq.how_know_compatible_genres',
  },
  {
    question: 'faq.what_do_curator_charge_money',
  },
  {
    question: 'faq.how_many_money_charge',
  },
  {
    question: 'faq.how_know_right_genres',
  },
  {
    question: 'faq.is_data_safe',
  },
  {
    question: 'faq.what_is_vip',
  },
  {
    question: 'faq.subscription',
  },
  {
    question: 'faq.refund_policy',
  },
  {
    question: 'faq.credits',
  },
  {
    question: 'faq.credits_policy',
  },
  {
    question: 'faq.accelerate',
  },
  {
    question: 'faq.promote_profile',
  },
  {
    question: 'faq.login_with_multiple_accounts',
  },
]

export const Faqs = () => {
  const intl = useIntl()

  return (
    <div className="max-w-screen-md mx-auto">
      <Accordion>
        {FAQ_DATA.map((faq, index) => {
          const answer = intl.formatMessage(
            { id: `${faq.question}.answer` },
            { ...faq?.values }
          )

          return (
            <AccordionItem
              key={index}
              label={<FormattedMessage id={faq.question} />}
            >
              <p>{answer}</p>
            </AccordionItem>
          )
        })}
      </Accordion>
    </div>
  )
}

export const Faq = ({ className, showTitle = false, titleTag = 'h3' }) => {
  const classes = classNames({
    faq: true,
    section: true,
    container: true,
    [className]: className,
  })

  const intl = useIntl()

  return (
    <div id="faq" className={classes}>
      {showTitle && (
        <SectionTitle
          tag={titleTag}
          title={intl.formatMessage({ id: 'faq.title' })}
          center
          badge={
            <Badge
              variant="primary-light"
              icon="question-circle"
              label={intl.formatMessage({ id: 'navigation.faq' })}
            />
          }
        />
      )}

      <Faqs />
    </div>
  )
}

export default Faq
