import React from 'react'
import Spinner from '@fnd/components/Spinner'
import { InvoiceCard } from '@fnd/components/Invoice'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Empty from '@fnd/components/Empty'
import SectionTitle from '@fnd/components/SectionTitle'
import Wrapper from '@fnd/components/Wrapper'
import { QUERIES } from '@fnd/constants'
import { useQuery } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'

const Promo = () => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERIES.PROMO.LIST],
    queryFn: () => spotimatchEndpoints.getFeaturedInvoices(),
  })

  const intl = useIntl()

  return (
    <Wrapper>
      <SectionTitle
        title={intl.formatMessage({ id: 'promo.page.title' })}
        subtitle={intl.formatMessage({ id: 'promo.page.subtitle' })}
        headingClasses="text-gradient-primary-fade"
        center
        badge={
          <Badge
            variant="primary-light"
            label="BF2024"
            icon="tag"
          />
        }
      />

      <div className="max-w-screen-md pb-12 mx-auto">
        {isLoading && <Spinner />}

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {data?.length > 0 && data.map((invoice) => (
            <InvoiceCard
              key={invoice._id}
              accessToken={invoice?.access_token}
              invoiceId={invoice?._id}
              userId={invoice?.user_id}
              description={invoice?.description}
              amount={invoice?.amount}
              cover={invoice?.cover}
              discountPercent={invoice?.discount_percentage}
              discountAmount={invoice?.discount_amount}
              expirationDate={invoice?.expiration_date}
              discountExpirationDate={invoice?.discount_expiration}
              status={invoice?.status}
            />
          ))}

          {data?.length === 0 && (
            <Empty
              icon="badge-percent"
              message={intl.formatMessage({ id: 'promo.empty_state' })}
            />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default Promo
