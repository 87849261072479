import React from 'react'
import Auth from '@fnd/components/Auth'
import { ConversationList } from '@fnd/components/Chat'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { useMediaQuery } from 'react-responsive'
import { isMobile } from 'react-device-detect'
import { BREAKPOINTS } from '@fnd/constants'

export default function ChatPage({ detail }) {
  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.MD}px)`,
  })

  return (
    <Auth>
      <ConversationList
        className="mb-4"
        detail={detail}
        user={profile}
        mobile={isMobile || isTabletOrMobile}
      />
    </Auth>
  )
}
