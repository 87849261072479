import React from 'react'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Button from '@fnd/components/Button'
import { StepperFooter } from '@fnd/components/Stepper'
import { useForm } from 'react-hook-form'
import { useIntl, FormattedMessage } from 'react-intl'
import { useVipCampaignStore } from '@fnd/store'
import Payment from '@fnd/components/Payment'
import { PaymentItem } from '@fnd/components/Plans/PaymentItem'
import { PLAN_PRICES } from '@fnd/constants'
import VipCampaignOverview from '@fnd/modules/Profile/VipCampaigns/VipCampaignOverview'
import VipCampaignSummary from '../VipCampaignSummary'
import VipCampaignPlans from '../VipCampaignPlans'

const VipCampaignStep5 = ({ previous }) => {
  const {
    form,
    setForm,
    getFormData,
    getPaymentTotal,
    getMaxCredits,
    impressions,
  } = useVipCampaignStore()

  const intl = useIntl()

  const { getValues, setValue, watch } = useForm({
    defaultValues: {
      ...form,
    },
  })

  const formData = watch()

  const handleBack = () => {
    const data = getValues()
    setForm({
      ...form,
      ...data,
    })
    previous()
  }

  const handleCampaignCreation = async () => {
    const formData = getFormData()
    await spotimatchEndpoints.createVipCampaign(formData)
    setForm(form)
  }

  return (
    <>
      <div className="flex flex-col gap-2 mt-2 mb-6">
        <h3 className="text-3xl m-0">
          {intl.formatMessage({ id: 'vip_campaign.step5.title' })}
        </h3>
        <p className="font-light text-lg opacity-80 m-0">
          {intl.formatMessage({ id: 'vip_campaign.step5.text' })}
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-8">
        <div className="flex flex-col md:col-span-2">
          <VipCampaignOverview campaign={formData} />

          <VipCampaignPlans
            campaign={formData}
            className="mt-8"
            setValue={setValue}
          />
        </div>

        <div className="relative">
          <VipCampaignSummary
            className="relative md:sticky md:top-24"
            campaign={form}
            impressions={impressions}
            showEstimate={true}
            payment={getPaymentTotal()}
          />
        </div>
      </div>

      <StepperFooter className="mt-6">
        <Button
          variant="inverse"
          type="button"
          onClick={handleBack}
          icon="arrow-left"
        >
          {intl.formatMessage({ id: 'messages.previous' })}
        </Button>

        <Payment
          contract={true}
          className="flex justify-center w-100"
          plan="vip"
          planName="vip"
          title="VIP"
          label={<FormattedMessage id="messages.proceed_to_payment" />}
          price={getPaymentTotal().totalWithVAT}
          maxCredits={getMaxCredits(form)}
          autoApply={false}
          creditsDiscount={0.5}
          onBeforePayment={handleCampaignCreation}
          payload={{
            ...formData,
            impressions: impressions,
          }}
          addons={formData.addOns}
        >
          <div className="flex flex-col gap-2">
            <PaymentItem
              title="VIP"
              color="gold"
              subtitle={<FormattedMessage id="navigation.account_plan" />}
              price={getPaymentTotal().vipTotal}
              icon="gem"
            />

            {formData?.addOns?.length > 0 &&
              formData?.addOns.map((addOn) => (
                <PaymentItem
                  key={addOn.plan}
                  title={addOn.name}
                  subtitle={<FormattedMessage id="messages.add_on" />}
                  price={PLAN_PRICES[addOn.plan].salePrice}
                  salePrice={addOn.price}
                  icon="plus"
                />
              ))}
          </div>
        </Payment>
      </StepperFooter>
    </>
  )
}

export default VipCampaignStep5
