import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import {
  PLAN_CONFIG,
  PLAN_PRICES,
  PLANS,
  STRIPE_PLAN_SUBSCRIPTIONS,
} from '@fnd/constants'
import Button from '@fnd/components/Button'
import { PlanCard } from '@fnd/components/Plans'
import { useUserContext, userPlanSelector } from '@fnd/modules/User'
import { useVipCampaignStore } from '@fnd/store'
import { useQuery } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Spinner from '@fnd/components/Spinner'

const VipCampaignPlans = ({ campaign, setValue, className }) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const { calculatePaymentTotal, setItem, resetItems } = useVipCampaignStore()

  const { plan } = useUserContext(({ user }) => ({
    plan: userPlanSelector(user),
  }))

  const intl = useIntl()

  const { data } = useQuery({
    queryKey: ['plans'],
    queryFn: () => spotimatchEndpoints.getPlans(intl.locale),
  })

  const artistPlan = data?.find((plan) => plan.slug === PLANS.ARTIST)
  const agencyPlan = data?.find((plan) => plan.slug === PLANS.AGENCY)

  const classes = classNames({
    [className]: className,
  })

  const togglePlan = (value) => {
    if (value === selectedPlan) {
      setSelectedPlan(null)
      setValue('addOns', [])
      resetItems()
      return
    }

    const addOn = {
      planId: STRIPE_PLAN_SUBSCRIPTIONS[value],
      plan: value,
      name: PLAN_CONFIG[value].title,
      price: PLAN_PRICES[value].vipPrice,
    }

    setItem(addOn)
    setValue('addOns', [addOn])
    setSelectedPlan(value)
  }

  useEffect(() => {
    if (campaign?.addOns?.length > 0) {
      setSelectedPlan(campaign?.addOns[0].plan)
    }

    if (plan?.name === PLANS.AGENCY) {
      resetItems()
    }
  }, [])

  useEffect(() => {
    if (selectedPlan) {
      calculatePaymentTotal(campaign, selectedPlan)
    } else {
      calculatePaymentTotal(campaign, plan)
    }
  }, [campaign.addOns])

  if (!plan || plan?.name === PLANS.AGENCY) return null

  return (
    <div className={classes}>
      <div className="flex flex-col items-start gap-2 mb-4">
        <h3 className="m-0">
          {intl.formatMessage({ id: 'vip_campaign.plans.title' })}
        </h3>

        <p className="font-light text-lg opacity-80 m-0">
          {intl.formatMessage({ id: 'vip_campaign.plans.text' })}
        </p>
      </div>

      {data ? (
        <div className="grid md:grid-cols-2 gap-8">
          {plan?.name === PLANS.FREE && artistPlan && (
            <PlanCard
              className={classNames({
                'plan-card cursor-pointer': true,
                [PLAN_CONFIG.artist.color]: PLAN_CONFIG.artist.color,
                active: selectedPlan === PLANS.ARTIST,
              })}
              title={artistPlan.name}
              slug={artistPlan.slug}
              icon={artistPlan.icon}
              color={artistPlan.colorClass}
              features={artistPlan.features}
              caption={artistPlan.caption}
              save={artistPlan.save}
              price={artistPlan.price}
              salePrice={artistPlan.salePrice}
              priceMonthly={artistPlan.priceMonthly}
              salePriceMonthly={artistPlan.salePriceMonthly}
              isActive={
                (typeof plan !== 'undefined' ? plan.name.toLowerCase() : '') ===
                artistPlan.slug
              }
              cta={
                <Button
                  label={
                    selectedPlan === PLANS.ARTIST ? (
                      <FormattedMessage id="messages.selected" />
                    ) : (
                      <FormattedMessage id="messages.add" />
                    )
                  }
                  icon={selectedPlan === PLANS.ARTIST ? 'check' : 'plus'}
                  onClick={() => togglePlan(PLANS.ARTIST)}
                />
              }
              socialProof={
                artistPlan.chooseFrom > 0 ? (
                  <FormattedMessage
                    id="plans.card.artist.social_proof"
                    values={{ plans: artistPlan.chooseFrom }}
                  />
                ) : null
              }
            />
          )}

          {(plan?.name === PLANS.FREE || plan?.name === PLANS.ARTIST) && (
            <PlanCard
              className={classNames({
                'plan-card cursor-pointer': true,
                [PLAN_CONFIG.artist.color]: PLAN_CONFIG.artist.color,
                active: selectedPlan === PLANS.AGENCY,
              })}
              title={agencyPlan.name}
              slug={agencyPlan.slug}
              icon={agencyPlan.icon}
              color={agencyPlan.colorClass}
              features={agencyPlan.features}
              caption={agencyPlan.caption}
              save={agencyPlan.save}
              price={agencyPlan.price}
              salePrice={agencyPlan.salePrice}
              priceMonthly={agencyPlan.priceMonthly}
              salePriceMonthly={agencyPlan.salePriceMonthly}
              isActive={
                (typeof plan !== 'undefined' ? plan.name.toLowerCase() : '') ===
                agencyPlan.slug
              }
              cta={
                <Button
                  label={
                    selectedPlan === PLANS.AGENCY ? (
                      <FormattedMessage id="messages.selected" />
                    ) : (
                      <FormattedMessage id="messages.add" />
                    )
                  }
                  icon={selectedPlan === PLANS.AGENCY ? 'check' : 'plus'}
                  onClick={() => togglePlan(PLANS.AGENCY)}
                />
              }
              socialProof={
                agencyPlan.chooseFrom > 0 ? (
                  <FormattedMessage
                    id="plans.card.artist.social_proof"
                    values={{ plans: agencyPlan.chooseFrom }}
                  />
                ) : null
              }
            />
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default VipCampaignPlans
