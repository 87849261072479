import React, { useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import TikTokList from './TikTokList'
import { MatchForm, MatchSwitch } from '@fnd/components/Match'
import { applyTheme, resetTheme } from '@fnd/core/libs/helpers'

export function TikTokMatch() {
  useEffect(() => {
    applyTheme('tiktok-primary', 'tiktok-secondary')

    return () => {
      resetTheme()
    }
  }, [])

  return (
    <Wrapper>
      <Auth login emptyState>
        <MatchSwitch initial="tiktok" className="mb-8" />

        <MatchForm
          btnLabel="Match 3000+ TikTok stars"
          btnVariant="gradient"
          className="mb-8"
          matchTypeEnabled="tiktok"
        />

        <TikTokList />
      </Auth>
    </Wrapper>
  )
}

export default TikTokMatch
