import React, { useContext, useEffect, useRef } from 'react'
import { TRUSTPILOT_LINK } from '@fnd/constants'
import { AVAILABLE_LANGUAGES } from '@fnd/screens/constants'
import { ThemeContext } from '@fnd/context/ThemeProvider'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

const TrustpilotMicroComboWidget = ({ className }) => {
  const intl = useIntl()
  const { currentTheme } = useContext(ThemeContext)
  const { locale } = intl
  const widgetRef = useRef(null)

  const language = AVAILABLE_LANGUAGES.find((lang) => lang.value === locale)

  const classes = classNames('trustpilot-widget-wrapper', className)

  useEffect(() => {
    if (widgetRef.current) {
      widgetRef.current.setAttribute('data-theme', currentTheme ?? 'light')

      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(widgetRef.current, true)
      }
    }
  }, [currentTheme])

  return (
    <div className={classes}>
      <div
        ref={widgetRef}
        className="trustpilot-widget"
        data-locale={language?.bpc47 ?? 'en-US'}
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="62e005c88f96c51a8665f08f"
        data-style-height="20px"
        data-style-width="100%"
        data-theme={currentTheme ?? 'light'}
      >
        <a
          href={TRUSTPILOT_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className="hidden"
        >
          Trustpilot
        </a>
      </div>
    </div>
  )
}

export default TrustpilotMicroComboWidget
