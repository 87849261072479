import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import sanityClient from '@fnd/core/sanity/client'
import Hero from '@fnd/components/Hero'
import TeamMember from '@fnd/components/TeamMember'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { teamQuery } from '@fnd/core/sanity/queries'
import { nanoid } from 'nanoid'

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([])

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const teamData = await sanityClient.fetch(teamQuery)
        setTeamMembers(teamData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchTeam()
  }, [])

  return (
    <Wrapper>
      <Hero
        title={<FormattedMessage id="team.title" />}
        subtitle={<FormattedMessage id="team.our_mission" />}
        image="/images/home/matchfy_team.jpg"
        overlay="overlay"
        className="h-[50vh] mb-8"
        overlayOpacity={70}
      />

      {teamMembers && teamMembers.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-24">
          {teamMembers.map((member) => (
            <TeamMember
              key={nanoid()}
              name={member.title}
              description={member.role}
              image={member.image.url}
            />
          ))}
        </div>
      )}
    </Wrapper>
  )
}

export default Team
