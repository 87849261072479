import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { ROUTES } from '@fnd/screens/constants'
import dayjs from 'dayjs'

const AccelerateDaysRemaining = ({ className, hasAccelerated }) => {
  const intl = useIntl()
  const daysToEndOfMonth = dayjs().endOf('month').diff(dayjs(), 'days')

  return (
    <Alert variant="primary" className={className}>
      <h5 className="text-primary mb-2">
        {daysToEndOfMonth > 0 ? (
          <FormattedMessage
            id="accelerate.requirements.timer"
            values={{ daysLeft: daysToEndOfMonth }}
          />
        ) : (
          <FormattedMessage id="accelerate.requirements.last_day" />
        )}
      </h5>

      {!hasAccelerated && (
        <p className="text-lg">
          {intl.formatMessage({ id: 'accelerate.requirements.timer.label' })}
        </p>
      )}

      {hasAccelerated && (
        <>
          <p className="text-lg">
            {intl.formatMessage({ id: 'accelerate.used_this_month' })}
          </p>

          <Button
            to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ACCELERATES}`}
            label={intl.formatMessage({ id: 'navigation.my_accelerates' })}
          />
        </>
      )}
    </Alert>
  )
}

export default AccelerateDaysRemaining
