import React from 'react'
import classNames from 'classnames'
import Emoji from '@fnd/components/Emoji'
import { Placeholder } from '@fnd/components/Placeholder'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'

export default function RankTable({ ranking, className }) {
  const navigate = useNavigate()

  const handleClick = (id) => {
    navigate(`${ROUTES.CURATORS}/${id}`)
  }

  const RankRow = ({ rank, index }) => {
    const position = index + 1
    const rowClasses = classNames({
      'rank-table-row': true,
      [`rank-table-row-${position}`]: position,
      'cursor-pointer': true,
    })

    const getRankIcon = (position) => {
      if (position === 1)
        return <Emoji symbol="🥇" className="text-3xl" label="first-place" />
      if (position === 2)
        return <Emoji symbol="🥈" className="text-3xl" label="second-place" />
      if (position === 3)
        return <Emoji symbol="🥉" className="text-3xl" label="third-place" />
      return position
    }

    return (
      <div className={rowClasses} onClick={() => handleClick(rank.owner)}>
        {rank.images[0]?.url ? (
          <div className="rank-avatar">
            <img
              className="absolute w-full h-full object-cover"
              src={rank.images[0]?.url}
              alt={rank.display_name ?? rank.owner}
            />
          </div>
        ) : (
          <Placeholder className="rank-avatar" icon="user" />
        )}

        <span className="rank-index">{getRankIcon(position)}</span>

        <span className="rank-name">{rank.display_name ?? rank.owner}</span>
      </div>
    )
  }

  const classes = classNames({
    'rank-table': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      {ranking.map((rank, index) => (
        <RankRow key={rank.id} index={index} rank={rank} />
      ))}
    </div>
  )
}
