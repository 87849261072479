import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import axios from 'axios'
import { ROUTES } from '@fnd/screens/constants'
import { MATCHFY_BLOG_API_URL, GHOST_KEY } from '@fnd/constants'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Card from '@fnd/components/Card/Card'
import { readMore } from '@fnd/core/libs/helpers'

const Blog = ({ className }) => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postData = await axios.get(
          `${MATCHFY_BLOG_API_URL}/posts/?key=${GHOST_KEY}&limit=4`
        )
        setPosts(postData.data.posts)
      } catch (error) {
        console.log(error)
      }
    }
    fetchPosts()
  }, [])

  const intl = useIntl()

  const classes = classNames({
    blog: true,
    section: true,
    'container mx-auto': true,
    [className]: className,
  })

  if (!posts || posts.length === 0) {
    return null
  }

  return (
    <div id="blog" className={classes}>
      <SectionTitle
        tag="h3"
        center
        title={intl.formatMessage({ id: 'blog.title' })}
        subtitle={intl.formatMessage({ id: 'blog.subtitle' })}
        badge={<Badge variant="primary-light" icon="newspaper" label="News" />}
      />

      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
        {posts &&
          posts.length > 0 &&
          posts.map((post) => (
            <Card
              key={post.id}
              className="card-blog"
              to={post.url}
              external={true}
              image={post.feature_image}
              title={post.title}
              titleTag="h4"
              text={readMore(post.excerpt)}
            />
          ))}
      </div>

      <div className="w-full text-center mt-8">
        <a
          href={ROUTES.BLOG}
          target="_blank"
          rel="noopener noreferrer"
          className="button button-cta button-outline"
        >
          <FormattedMessage id="home.blog.read_more" />
        </a>
      </div>
    </div>
  )
}

export default Blog
