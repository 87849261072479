import React from 'react'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import Badge from '@fnd/components/Badge'
import Copyright from './Copyright'
import FooterNavigation from './FooterNavigation'
import Social from './Social'
import { TrustpilotMiniWidget } from '@fnd/components/Trustpilot'
import { useAppStore } from '@fnd/store'

const Footer = () => {
  const { version } = useAppStore()

  return (
    <footer>
      <div className="footer-content">
        <div className="footer-grid">
          <div className="footer-logo">
            <div className="flex flex-col items-start justify-between gap-4 h-full">
              <LogoIcon />

              <Badge
                small
                variant="light"
                value={version}
                className="font-mono"
              />
            </div>
          </div>

          <FooterNavigation />

          <Social />
        </div>
      </div>
      <Copyright version={version} />
    </footer>
  )
}

export default Footer
