import React, { useContext } from 'react'
import { TranslationContext, messages } from '@fnd/translations'
import Select from 'react-select'

const languageOptions = Object.keys(messages).map((language) => ({
  value: language,
  label: language.toUpperCase(),
}))

export default function LanguageDropdown() {
  const { locale, setLocale } = useContext(TranslationContext)
  const changeHandler = (option, { action }) => {
    if (action !== 'select-option') {
      return
    }

    setLocale(option.value)
  }

  return (
    <Select
      value={languageOptions.find(({ value }) => value === locale)}
      options={languageOptions}
      onChange={changeHandler}
      className="select language-dropdown"
      classNamePrefix="select"
      isSearchable={false}
    />
  )
}
