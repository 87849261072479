import React from 'react'
import classNames from 'classnames'
import { StepperItem } from '.'

const Stepper = ({ steps, className, ...props }) => {
  const { set, currentStep, previous, next } = props
  const classes = classNames('stepper', className)

  return (
    <ol className={classes}>
      {steps.map((step, index) => (
        <StepperItem
          key={step.key}
          currentStep={currentStep}
          index={index}
          next={next}
          previous={previous}
          step={step}
          set={set}
          {...props}
        />
      ))}
    </ol>
  )
}

export default Stepper
