import React from 'react'
import { FormattedMessage } from 'react-intl'

const AccelerateRequirements = () => {
  return (
    <>
      <h5>
        <FormattedMessage id="accelerate.requirements.title" />
      </h5>

      <p>
        <FormattedMessage id="accelerate.requirements.main" />
      </p>

      <ul className="list">
        <li>
          <FormattedMessage id="accelerate.requirements.list.first" />
        </li>

        <li>
          <FormattedMessage id="accelerate.requirements.list.second" />
        </li>

        <li>
          <FormattedMessage
            id="accelerate.requirements.list.third"
            values={{ b: (chunk) => <b>{chunk}</b> }}
          />
        </li>

        <li>
          <FormattedMessage
            id="accelerate.requirements.list.fourth"
            values={{ b: (chunk) => <b>{chunk}</b> }}
          />
        </li>
      </ul>

      <p className="mt-5 mb-0 opacity-70">
        <FormattedMessage
          id="accelerate.requirements.in_case"
          values={{ b: (chunk) => <b>{chunk}</b> }}
        />
      </p>
    </>
  )
}

export default AccelerateRequirements
