import React, { useEffect, useState } from 'react'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { ROUTES } from '@fnd/screens/constants'
import { useIntl } from 'react-intl'
import { MATCHFY_DISTRO_URL, PLANS } from '@fnd/constants'
import { useLocation, useNavigate } from 'react-router'
import { useUserContext } from '@fnd/modules/User'
import { SignButton } from '@fnd/components/Sign'

const SingleSignOn = () => {
  const [status, setStatus] = useState('loading')

  const navigate = useNavigate()
  const location = useLocation()
  const { search } = location

  const {
    user: { profile },
  } = useUserContext()

  const intl = useIntl()

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const returnTo = params.get('return_to')

      if (returnTo) {
        sessionStorage.setItem('redirect', returnTo)
        return
      }
    }

    sessionStorage.setItem('redirect', MATCHFY_DISTRO_URL)
  }, [])

  useEffect(() => {
    if (!profile) {
      setStatus('notLoggedIn')
      return
    }

    if (
      profile?.plan?.name === PLANS.ARTIST ||
      profile?.plan?.name === PLANS.AGENCY
    ) {
      setStatus('authorized')
      navigate(`${ROUTES.SPOTIFY_SSO_CALLBACK}`)
    } else {
      setStatus('unauthorized')
    }
  }, [profile])

  return (
    <Wrapper id="sso">
      {(status === 'loading' || status === 'authorized') && <Spinner />}

      {status === 'notLoggedIn' && (
        <SignButton
          variant="gradient"
          label={intl.formatMessage({ id: 'sso' })}
          className="mt-8"
          type="sso"
        />
      )}

      {status === 'unauthorized' && (
        <Empty
          login
          icon="user-lock"
          message={intl.formatMessage({ id: 'navigation.login_to_continue' })}
        />
      )}
    </Wrapper>
  )
}

export default SingleSignOn
