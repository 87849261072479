import React from 'react'
import Avatar from '@fnd/components/Avatar'
import Badge from '@fnd/components/Badge'
import TikTokPush from './TikTokPush'
import { nFormatter } from '@fnd/core/libs/helpers'
import { PLANS } from '@fnd/constants'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

export function TikTokDetail({
  className,
  heartCount,
  followers,
  region,
  preview,
  videoCount,
  ...props
}) {
  const intl = useIntl()

  const classes = classNames({
    'tiktok-detail': true,
    'tiktok-detail-preview': preview,
    [className]: className,
  })

  const { avatar, nickname } = props

  return (
    <div className={classes}>
      <div className="modal-detail-header">
        <Avatar
          src={avatar}
          alt={nickname}
          className="w-20 h-20 flex-shrink-0 rounded-full"
        />

        <div className="flex flex-col items-start w-full">
          <h3 className="text-xl sm:mt-2 sm:mb-1">{nickname}</h3>

          <div className="flex items-center justify-center md:justify-start flex-wrap gap-2">
            {region && (
              <Badge
                className="flex-1 justify-center"
                icon="globe"
                variant="light"
                label={region}
              />
            )}

            {heartCount && heartCount > 0 && (
              <Badge
                className="flex-1 justify-center"
                icon="heart"
                variant="red-light"
                label={nFormatter(heartCount)}
              />
            )}

            {followers && followers > 0 && (
              <Badge
                className="flex-1 justify-center"
                icon="users"
                variant="primary-light"
                label={nFormatter(followers)}
              />
            )}

            {videoCount && videoCount > 0 && (
              <Badge
                className="flex-1 justify-center"
                icon="video"
                variant="blue-light"
                label={nFormatter(videoCount)}
              />
            )}
          </div>
        </div>
      </div>

      {preview && (
        <AccountUpgrade
          small
          btnVariant="gradient"
          btnClasses="w-full"
          message={intl.formatMessage(
            { id: 'profile.upgrade.tiktok' },
            {
              plan: 'agency',
            }
          )}
          plan={PLANS.AGENCY}
        />
      )}

      {!preview && <TikTokPush {...props} />}
    </div>
  )
}

export default TikTokDetail
