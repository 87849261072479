import React from 'react'
import Card from '@fnd/components/Card'
import Icon from '@fnd/components/Icon'
import { getCampaignOption } from '../utils'
import { useIntl } from 'react-intl'

const VipInfoSpotify = () => {
  const intl = useIntl()

  const campaignOption = getCampaignOption('spotify', intl)

  return (
    <Card className="bg-primary p-4 border-outline">
      <h3 className="inline-flex gap-2 text-xl mb-2">
        <Icon type="brands" name="spotify" />
        {campaignOption.label} -{' '}
        {intl.formatMessage({ id: 'messages.how_it_works' })}
      </h3>

      <ul className="list flex flex-col items-start gap-3">
        <li className="text-sm text-balance opacity-90 py-0">
          {intl.formatMessage({ id: 'vip_campaign.info.spotify.text1' })}
        </li>
        <li className="text-sm text-balance opacity-90 py-0">
          {intl.formatMessage({ id: 'vip_campaign.info.spotify.text2' })}
        </li>
        <li className="text-sm text-balance opacity-90 py-0">
          {intl.formatMessage({ id: 'vip_campaign.info.spotify.text3' })}
        </li>
        <li className="text-sm text-balance opacity-90 py-0">
          {intl.formatMessage({ id: 'vip_campaign.info.spotify.text4' })}
        </li>
        <li className="text-sm text-balance opacity-90 py-0">
          {intl.formatMessage({ id: 'vip_campaign.info.spotify.text5' })}
        </li>
      </ul>
    </Card>
  )
}

export default VipInfoSpotify
