import React from 'react'
import { Checkbox } from '@fnd/components/Field/Checkbox'
import Badge from '@fnd/components/Badge'
import { formatNumber } from '@fnd/core/libs/currency'
import { TableColumnHeader } from '@fnd/components/Table/TableColumnHeader'
import { DataTableRowActions } from './TikTokTableActions'
import { getMediaUrl } from '@fnd/core/libs/media'
import { getPlatformUrl } from '@fnd/core/libs/platforms'

export const getColumns = (intl, actionProps = {}) => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_all' })}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_row' })}
      />
    ),
    show: false,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'tiktok_handle',
    header: ({ column }) => (
      <TableColumnHeader column={column} title="TikTok Handle" />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <div className="flex flex-col items-start">
          <a
            href={getPlatformUrl('tiktok', item.tiktok_handle)}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center gap-3 font-medium"
          >
            {item?.avatar && (
              <img
                src={getMediaUrl(item.avatar)}
                alt={item.tiktok_handle}
                className="w-[42px] h-[42px] rounded-full"
              />
            )}
            {item.tiktok_handle}
          </a>
        </div>
      )
    },
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: 'nickname',
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Nickname" />
    ),
    cell: ({ row }) => {
      const item = row.original

      return <span>{item.nickname}</span>
    },
  },
  {
    accessorKey: 'heart_count',
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Heart Count" />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <span className="inline-flex items-center gap-2">
          {formatNumber(item.heart_count)}
        </span>
      )
    },
  },
  {
    accessorKey: 'followers',
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Followers" />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <span className="inline-flex items-center gap-2">
          {formatNumber(item.followers)}
        </span>
      )
    },
  },
  {
    accessorKey: 'video_count',
    header: ({ column }) => (
      <TableColumnHeader column={column} title="Video Count" />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <span className="inline-flex items-center gap-2">
          {formatNumber(item.video_count)}
        </span>
      )
    },
  },
  {
    accessorKey: 'tags',
    header: ({ column }) => <TableColumnHeader column={column} title="Tags" />,
    cell: ({ row }) => {
      const item = row.original

      return (
        <div className="flex flex-wrap items-center gap-1">
          {item.tags?.length > 0 &&
            item.tags.map((tag) => (
              <Badge key={tag} variant="primary-light">
                {tag}
              </Badge>
            ))}
        </div>
      )
    },
    show: false,
  },
  {
    id: 'actions',
    cell: ({ row }) => <DataTableRowActions row={row} {...actionProps} />,
  },
]

export default getColumns
