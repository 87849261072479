import React from 'react'
import Badge from '@fnd/components/Badge'
import { ArtistPopularityBar } from '@fnd/components/Artist'
import { formatNumber, formatCurrency } from '@fnd/core/libs/currency'
import { useIntl } from 'react-intl'

const VipImpressions = ({ id, impressions, formData }) => {
  const intl = useIntl()
  const { locale } = intl

  const data = impressions[id]
  const spotifyData = impressions?.spotify

  if (id === 'tiktokTalents') {
    return (
      <>
        <Badge
          vertical
          variant="blue-light"
          className="flex w-full items-center text-base font-medium"
          icon="eye"
          label={intl.formatMessage({
            id: 'vip_campaign.estimate.follower_reach',
          })}
          value={
            <>
              {formatNumber(data?.reach?.min, locale)}
              {' - '}
              {formatNumber(data?.reach?.max, locale)}
            </>
          }
        />

        <Badge
          variant="blue-light"
          className="flex w-full items-center text-base font-medium"
          icon="sack-dollar"
          label={intl.formatMessage({
            id: 'vip_campaign.estimate.costPerTalent',
          })}
          value={formatCurrency(data?.costPerTalent)}
        />
      </>
    )
  }

  if (id === 'tiktok') {
    return (
      <Badge
        vertical
        variant="primary-light"
        className="flex w-full text-base font-medium capitalize"
        icon="eye"
        label={intl.formatMessage({ id: 'vip_campaign.estimate.views' })}
        value={
          <>
            {formatNumber(data?.views?.min, locale)}
            {' - '}
            {formatNumber(data?.views?.max, locale)}
          </>
        }
      />
    )
  }

  if (id === 'spotify') {
    return (
      <>
        <ArtistPopularityBar
          popularity={formData?.info?.spotify?.artist?.popularity || 0}
          className="mb-2"
        />

        <Badge
          vertical
          variant="green-light"
          className="flex w-full items-center text-base font-medium capitalize"
          icon="list-music"
          label={intl.formatMessage({ id: 'vip_campaign.estimate.playlists' })}
          value={
            <>
              {formatNumber(spotifyData?.playlists?.min, locale)}
              {' - '}
              {formatNumber(spotifyData?.playlists?.max, locale)}
            </>
          }
        />

        <Badge
          vertical
          variant="green-light"
          className="flex w-full items-center text-base font-medium capitalize"
          icon="play"
          label="Streams"
          value={
            <>
              {formatNumber(spotifyData?.streams?.min, locale)}
              {' - '}
              {formatNumber(spotifyData?.streams?.max, locale)}
            </>
          }
        />
      </>
    )
  }

  if (id === 'spotifyAlgo') {
    return (
      <Badge
        vertical
        variant="green-light"
        className="flex w-full items-center text-base font-medium capitalize"
        icon="play"
        label="Streams"
        value={
          <>
            {formatNumber(spotifyData?.streams?.min, locale)}
            {' - '}
            {formatNumber(spotifyData?.streams?.max, locale)}
          </>
        }
      />
    )
  }

  if (id === 'youtube') {
    return (
      <Badge
        vertical
        variant="red-light"
        className="flex w-full items-center text-base font-medium capitalize"
        icon="eye"
        label={intl.formatMessage({ id: 'vip_campaign.estimate.views' })}
        value={
          <>
            {formatNumber(data?.views?.min, locale)}
            {' - '}
            {formatNumber(data?.views?.max, locale)}
          </>
        }
      />
    )
  }
}

export default VipImpressions
