import React from 'react'
import Icon from '@fnd/components/Icon'
import { useIntl } from 'react-intl'
import { SpotidoctorIconLogo } from '@fnd/components/Logo/Logo'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

const AnalysisProcess = ({ className }) => {
  const intl = useIntl()

  const processData = [
    {
      title: intl.formatMessage({ id: 'analysis.card.1.title' }),
      description: intl.formatMessage({ id: 'analysis.card.1.description' }),
    },
    {
      title: intl.formatMessage({ id: 'analysis.card.2.title' }),
      description: intl.formatMessage({ id: 'analysis.card.2.description' }),
    },
    {
      title: intl.formatMessage({ id: 'analysis.card.3.title' }),
      description: intl.formatMessage({ id: 'analysis.card.3.description' }),
    },
  ]

  const classes = classNames({
    'analysis-process': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="analysis-cover-grid">
        {[...Array(6)].map(() => (
          <div key={nanoid()} className="analysis-track">
            <Icon name="music" />
          </div>
        ))}
      </div>
      <div className="analysis-doc">
        <SpotidoctorIconLogo />
        <div className="analysis-line" />
      </div>
      <div className="analysis-grid">
        {processData.map((data, index) => (
          <div key={index} className="analysis-card">
            <h3>{data.title}</h3>
            <p>{data.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AnalysisProcess
