import React from 'react'
import Icon from '@fnd/components/Icon'
import Field from '@fnd/components/Field'
import { TIKTOK_SOUND_TUTORIAL_URL } from '@fnd/constants'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'

const VipFormTikTokTalents = ({ control, form, errors }) => {
  const intl = useIntl()

  return (
    <div className="flex flex-col gap-3">
      <h4 className="text-xl m-0">
        <Icon name="user-music" className="mr-2" />
        TikTok Talents
      </h4>

      <div className="grid md:grid-cols-2 gap-4">
        <Controller
          name="info.tiktokTalents.soundUrl"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              className="md:col-span-2"
              iconBefore={<Icon name="link" />}
              label={intl.formatMessage({
                id: 'vip_campaign.field.tiktok_sound_url.label',
              })}
              description={
                <span className="inline-flex items-center gap-2">
                  {intl.formatMessage({
                    id: 'vip_campaign.field.tiktok_sound_code.description',
                  })}
                  <a
                    href={TIKTOK_SOUND_TUTORIAL_URL}
                    className="underline text-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage({
                      id: 'vip_campaign.field.tiktok_sound_code.cta',
                    })}
                  </a>
                </span>
              }
              errors={errors?.info?.tiktokTalents?.soundUrl}
              {...field}
            />
          )}
        />

        <Controller
          name="info.tiktokTalents.minuteStart"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="time"
              label={intl.formatMessage({
                id: 'vip_campaign.field.minute_start.label',
              })}
              description={intl.formatMessage({
                id: 'vip_campaign.field.minute_start.description',
              })}
              errors={errors?.info?.tiktokTalents?.minuteStart}
              defaultValue={form?.info?.tiktokTalents?.minuteStart}
              {...field}
            />
          )}
        />

        <Controller
          name="info.tiktokTalents.minuteEnd"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="time"
              label={intl.formatMessage({
                id: 'vip_campaign.field.minute_end.label',
              })}
              description={intl.formatMessage({
                id: 'vip_campaign.field.minute_end.description',
              })}
              errors={errors?.info?.tiktokTalents?.minuteEnd}
              defaultValue={form?.info?.tiktokTalents?.minuteEnd}
              {...field}
            />
          )}
        />

        <Controller
          name="info.tiktokTalents.lyrics"
          control={control}
          render={({ field }) => (
            <Field
              type="textarea"
              className="md:col-span-2"
              label={intl.formatMessage({
                id: 'vip_campaign.field.lyrics.label',
              })}
              description={intl.formatMessage({
                id: 'vip_campaign.field.lyrics.description',
              })}
              errors={errors?.info?.tiktokTalents?.lyrics}
              {...field}
            />
          )}
        />
      </div>
    </div>
  )
}

export default VipFormTikTokTalents
