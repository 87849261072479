import Button from '@fnd/components/Button'
import { ROUTES } from '@fnd/screens/constants'
import { usePlayStore } from '@fnd/store'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import TrackCard from './TrackCard'

export function TrackPlaysNavigation({ className }) {
  const { tracks, trackId, setTrack, setTrackId } = usePlayStore()
  const [prevTrack, setPrevTrack] = useState(null)
  const [nextTrack, setNextTrack] = useState(null)
  const intl = useIntl()

  useEffect(() => {
    const filteredTracks = tracks.filter((track) => track.id !== trackId)
    const currentTrackPosition = tracks.findIndex(
      (track) => track.id === trackId
    )

    setNextTrack(filteredTracks[currentTrackPosition])
    setPrevTrack(filteredTracks[currentTrackPosition - 1])
  }, [trackId])

  const handleTrackNavigation = (track) => {
    setTrackId(track.id)
    setTrack(track)
  }

  const classes = classNames({
    'grid grid-cols-2': true,
    'gap-6': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      {prevTrack && (
        <TrackCard
          vertical
          link={ROUTES.TRACK_DETAIL.replace(':trackId', prevTrack.id)}
          onClick={() => handleTrackNavigation(prevTrack)}
          track={prevTrack}
        />
      )}

      {nextTrack && (
        <TrackCard
          vertical
          link={ROUTES.TRACK_DETAIL.replace(':trackId', nextTrack.id)}
          onClick={() => handleTrackNavigation(nextTrack)}
          track={nextTrack}
        />
      )}

      <Button
        className="col-span-2"
        to={ROUTES.TRACKS}
        icon="arrow-left"
        label="Back"
      />
    </div>
  )
}

export default TrackPlaysNavigation
